import React from 'react';
import {
  FaChalkboardTeacher,
  FaGraduationCap,
  FaUserShield,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const EducationContent = () => (
  <>
    <h3 className="modal-main-title">
      Transforming Education with Secure Identity Solutions
    </h3>
    <p>
      Education is rapidly evolving in the digital age, and secure access to
      learning resources is essential to enhancing the learning experience for
      students, faculty, and administrators. At{' '}
      <strong>RAAH Technologies</strong>, we empower educational institutions
      with cutting-edge identity solutions that ensure secure, streamlined
      access for all stakeholders.
    </p>

    <h4 className="modal-subheading">
      <FaChalkboardTeacher size={30} style={{ marginRight: '10px' }} />
      Identity Challenges in Education
    </h4>
    <p>
      Educational institutions face a wide range of identity challenges,
      including managing remote access, protecting student and faculty data, and
      providing secure access for third-party partners. Our comprehensive
      identity solutions help solve these challenges with a focus on:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Managing secure access for faculty,
        staff, and administrative employees, ensuring compliance with
        educational standards and secure handling of sensitive information.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Enabling effective Identity Governance and
        Administration, including the onboarding, offboarding, and entitlement
        management of employees, students, and guest lecturers.
      </li>
      <li className="modal-list-item">
        <strong>CIAM (B2C):</strong> Providing seamless access to online
        resources for students, ensuring a secure and smooth experience with
        features like Single Sign-On (SSO) and Multi-Factor Authentication
        (MFA).
      </li>
      <li className="modal-list-item">
        <strong>B2B/B2B2C IAM:</strong> Managing identities for third-party
        partners, research collaborators, and other affiliated institutions to
        foster seamless collaboration.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Securing privileged access to administrative
        systems and sensitive research data to prevent unauthorized use.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Education
    </h4>
    <p>Our tailored identity solutions for education include:</p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling students, faculty, and
        staff to use a single set of credentials to access multiple platforms,
        from the Learning Management System (LMS) to library systems.
      </li>
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect sensitive student data and administrative
        information.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring the right
        individuals have access to the right resources, whether they are
        students accessing course materials or faculty managing grades.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user accounts
        throughout their lifecycle, from student onboarding to alumni access,
        ensuring compliance with regulations like FERPA.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Restricting access
        to critical administrative systems, protecting sensitive data, and
        ensuring proper usage by authorized personnel only.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: University of Future
    </h4>
    <p>
      <strong>Challenge:</strong> The University of Future wanted to provide
      secure remote access to its Learning Management System (LMS), library
      resources, and other online platforms while ensuring compliance with
      regulatory requirements.
    </p>
    <p>
      <strong>Solution:</strong> Our identity solution provided an integrated
      SSO experience across multiple platforms, allowing students, staff, and
      alumni easy access to essential resources. Additionally, Multi-Factor
      Authentication (MFA) was implemented to add an extra layer of security for
      high-value transactions and administrative access.
    </p>
    <p>
      <strong>Result:</strong> The University of Future experienced a{' '}
      <strong>25% increase</strong> in student satisfaction and a significant
      improvement in operational efficiency due to the reduction in IT helpdesk
      requests for password resets.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Student Experience:</strong> Seamless access to all
        learning resources through a unified identity system.
      </li>
      <li className="modal-list-item">
        <strong>Streamlined Faculty Access:</strong> Quick and secure access to
        teaching platforms, student records, and administrative tools.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Full compliance with educational
        regulations like FERPA and GDPR, ensuring data privacy and security.
      </li>
      <li className="modal-list-item">
        <strong>Reduced Administrative Overhead:</strong> Automated identity
        lifecycle management reduces manual processes for IT staff, enabling
        them to focus on other priorities.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      The future of education requires secure, scalable, and adaptable identity
      solutions that enhance the digital learning experience. At{' '}
      <strong>RAAH Technologies</strong>, we are committed to supporting
      educational institutions with innovative IAM, IGA, CIAM, and PAM solutions
      that meet the evolving needs of the digital campus.
    </p>
  </>
);

export default EducationContent;
