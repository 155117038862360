import React from "react";
import '../App.css';
import './TestimonalsComponent.css'; // Fixed typo in the file name
import Person1 from './icons/person1.jpg';
import Person2 from './icons/person2.jpg';
import Person3 from './icons/person3.jpg';
import Arrow from './icons/arrow.svg';

const TestimonialsComponent = ({ scrollPercentage }) => {
    const getSelectedText = (textVal) => {
        let baseClass = "testimonialShape testimonialTransition";
        switch (textVal) {
            case "first":
                return scrollPercentage >= 1 && scrollPercentage < (100 / 3)
                    ? `${baseClass} selectedTestimonial`
                    : `${baseClass} testimonialHidden`;
            case "second":
                return scrollPercentage >= (100 / 3) && scrollPercentage < (200 / 3)
                    ? `${baseClass} selectedTestimonial`
                    : `${baseClass} testimonialHidden`;
            case "third":
                return scrollPercentage >= (200 / 3) && scrollPercentage < (300 / 3)
                    ? `${baseClass} selectedTestimonial`
                    : `${baseClass} testimonialHidden`;
            default:
                return `${baseClass} testimonialHidden`;
        }
    };

    const getTitleStyle = (textVal) => {
        switch (textVal) {
            case "first":
                return scrollPercentage >= 1 && scrollPercentage < (100 / 3)
                    ? "selectedTestimonial"
                    : "hiddenTestimonialTitle";
            case "second":
                return scrollPercentage >= (100 / 3) && scrollPercentage < (200 / 3)
                    ? "selectedTestimonial"
                    : "hiddenTestimonialTitle";
            case "third":
                return scrollPercentage >= (200 / 3) && scrollPercentage < (300 / 3)
                    ? "selectedTestimonial"
                    : "hiddenTestimonialTitle";
            default:
                return "hiddenTestimonialTitle";
        }
    };

    const getTextContent = () => {
        if (scrollPercentage >= 1 && scrollPercentage < (100 / 3)) {
            return (
                <>
                    <span className="testimonialHeaderText">RAAH Stays Engaged.<br /><span className="boldHeaderText">Denise Hughes saw it</span></span><br /><br />
                    “RAAH brought their network and security expertise to one of our complex airport clients, showing <span className="highlightTestimonial">professionalism</span> and <span className="highlightTestimonial">responsiveness</span> throughout. We appreciate their work and look forward to future projects together.”<br /><br />
                    <span className="testimonialSmallText">- Northeast Territory Director, SITA Inc. USA</span>
                </>
            );
        } else if (scrollPercentage >= (100 / 3) && scrollPercentage < (200 / 3)) {
            return (
                <>
                    <span className="testimonialHeaderText">RAAH Knows the Industry.<br /><span className="boldHeaderText">Gary Burpo can attest</span></span><br /><br />
                    “RAAH's Advisory Services helped us <span className="highlightTestimonial">manage M&A complexities</span>, data, target systems, and identity processes, strengthening our OIM/OAM and Okta solutions to improve our IAM program.”<br /><br />
                    <span className="testimonialSmallText">- Director of Technology (IAM), Cox Auto</span>
                </>
            );
        } else if (scrollPercentage >= (200 / 3) && scrollPercentage < (300 / 3)) {
            return (
                <>
                    <span className="testimonialHeaderText">RAAH’s Talent Delivers.<br /><span className="boldHeaderText">Todd Oxford says</span></span><br /><br />
                    “We have engaged RAAH on multiple occasions. Their team of <span className="highlightTestimonial">Developers, Testers, BAs, Architects,</span> and <span className="highlightTestimonial">SMEs</span> allows RAAH to deliver Identity solutions and professional expertise that meet every aspect of your IAM portfolio.”<br /><br />
                    <span className="testimonialSmallText">- Senior Security Director, Bed Bath & Beyond</span>
                </>
            );
        } else {
            return (
                <>
                    <span className="testimonialHeaderText">RAAH Stays Engaged.<br /><span className="boldHeaderText">Denise Hughes saw it</span></span><br /><br />
                    “RAAH brought their network and security expertise to one of our complex airport clients, showing <span className="highlightTestimonial">professionalism</span> and <span className="highlightTestimonial">responsiveness</span> throughout. We appreciate their work and look forward to future projects together.”<br /><br />
                    <span className="testimonialSmallText">- Northeast Territory Director, SITA Inc. USA</span>
                </>
            );
        }
    };

    return (
        <div className="testimonialsContainer" id="testimonialsContainerDiv">
            <div className="leftTestimonialsContainer">
                <div className={getSelectedText("first")}>
                    <div className={`hiddenTestimonialTitle ${getTitleStyle("first")}`}>
                        <div className="testimonialImageWrapper">
                            <img src={Person1} alt="person1" className="testimonialImage testimonialImage1"/>
                            <img src={Arrow} className={getTitleStyle("first") === "selectedTestimonial" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
                <div className={getSelectedText("second")}>
                    <div className={`hiddenTestimonialTitle ${getTitleStyle("second")}`}>
                        <div className="testimonialImageWrapper">
                            <img src={Person2} alt="person2" className="testimonialImage testimonialImage2"/>
                            <img src={Arrow} className={getTitleStyle("second") === "selectedTestimonial" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
                <div className={getSelectedText("third")}>
                    <div className={`hiddenTestimonialTitle ${getTitleStyle("third")}`}>
                        <div className="testimonialImageWrapper">
                            <img src={Person3} alt="person3" className="testimonialImage testimonialImage3"/>
                            <img src={Arrow} className={getTitleStyle("third") === "selectedTestimonial" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rightTestimonialContainer">
                <h1 className="rightTestimonialText">
                    {getTextContent()}
                </h1>
            </div>
        </div>
    );
};

export default TestimonialsComponent;
