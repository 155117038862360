import React from 'react';
import {
  FaShoppingCart,
  FaShieldAlt,
  FaMobileAlt,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const RetailContent = () => (
  <>
    <h3 className="modal-main-title">
      Enhancing Retail and E-Commerce with Secure Identity Solutions
    </h3>
    <p>
      Retail and e-commerce are evolving rapidly, with secure access and
      personalized customer experiences being top priorities. At{' '}
      <strong>RAAH Technologies</strong>, we provide tailored identity and
      access management solutions to enhance security, streamline operations,
      and deliver exceptional customer experiences.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in Retail and E-Commerce
    </h4>
    <p>
      Retail and e-commerce businesses face significant challenges, such as
      securing customer data, managing access to sensitive systems, and
      delivering personalized customer experiences. Our identity solutions
      address these challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Customer IAM (CIAM):</strong> Providing seamless access for
        customers, ensuring a secure and smooth user experience with features
        like Single Sign-On (SSO) and Multi-Factor Authentication (MFA).
      </li>
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing access for employees, store
        associates, and administrative personnel, ensuring compliance with
        industry standards.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities for employees, ensuring
        appropriate access rights, and providing audit trails for compliance.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that access
        critical retail systems, mitigating risks of insider threats and
        unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for suppliers, vendors,
        and other partners, ensuring secure integration across the retail
        ecosystem.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Retail and E-Commerce
    </h4>
    <p>
      Our customized identity solutions for the retail and e-commerce sector
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect customer accounts and retail systems,
        safeguarding against unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling employees and customers
        to use a single set of credentials to access multiple systems, improving
        efficiency and user experience.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that retail
        employees have access to the systems and data they need, based on their
        roles and responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user identities
        throughout their lifecycle, ensuring compliance with industry
        regulations.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing access to
        critical retail systems, including administrative tools and inventory
        management, to protect against unauthorized use.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Global Retailer
    </h4>
    <p>
      <strong>Challenge:</strong> A global retailer needed to secure customer
      data and provide seamless access across multiple platforms, while ensuring
      compliance with data protection regulations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided a comprehensive
      identity solution, including Customer IAM (CIAM) and Multi-Factor
      Authentication (MFA) for secure access. Role-Based Access Control (RBAC)
      was implemented to ensure appropriate access for employees, and Privileged
      Access Management (PAM) protected critical retail systems.
    </p>
    <p>
      <strong>Result:</strong> The retailer experienced a{' '}
      <strong>25% increase</strong> in customer satisfaction and a significant
      reduction in data breaches, leading to improved trust and increased sales.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Customer Experience:</strong> Providing seamless access
        to online and in-store services through a unified identity system.
      </li>
      <li className="modal-list-item">
        <strong>Improved Operational Efficiency:</strong> Streamlined access for
        retail employees with SSO and RBAC, reducing administrative burden and
        enhancing productivity.
      </li>
      <li className="modal-list-item">
        <strong>Secure Collaboration:</strong> Enabling secure interactions with
        suppliers and partners through effective identity management.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to retail
        industry standards, reducing the risk of penalties and breaches.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As retail and e-commerce continue to evolve, secure identity solutions are
      essential for protecting customer data and enabling personalized
      experiences. At <strong>RAAH Technologies</strong>, we are dedicated to
      providing advanced IAM, IGA, CIAM, and PAM solutions that meet the dynamic
      needs of the retail sector.
    </p>
  </>
);

export default RetailContent;
