// CustomerRoleManagementContent.js

import React from 'react';
import { FaUserTag, FaCogs, FaKey } from 'react-icons/fa';

const CustomerRoleManagementContent = () => (
  <>
    <h3 className="modal-main-title">
      Customer Role and Entitlement Management (B2C, B2B, and B2B2C CIAM)
    </h3>
    <p>
      Customer Role and Entitlement Management is a crucial part of Customer
      Identity and Access Management (CIAM) for B2C, B2B, and B2B2C use cases.
      It involves assigning appropriate roles and entitlements to users,
      ensuring they have the right level of access based on their relationship
      with the organization. This approach helps maintain security, streamline
      user management, and enhance user experiences.
    </p>

    <h4 className="modal-subheading">
      <FaUserTag size={30} style={{ marginRight: '10px' }} />
      Benefits of Customer Role and Entitlement Management
    </h4>
    <p>
      Implementing effective role and entitlement management helps organizations
      improve security, simplify access control, and ensure compliance with data
      protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Assigning roles and entitlements
        based on user context ensures that users have only the access they need,
        minimizing the risk of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Simplified User Management:</strong> Role-based access control
        (RBAC) makes it easier to manage user permissions and streamline the
        onboarding and offboarding processes.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that access permissions are granted
        in accordance with regulations like GDPR and CCPA, maintaining
        transparency and control over customer data.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCogs size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Customer Role and Entitlement Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      comprehensive role and entitlement management for B2C, B2B, and B2B2C use
      cases. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Implement RBAC to
        manage user access based on predefined roles, ensuring that users have
        the appropriate level of access.
      </li>
      <li className="modal-list-item">
        <strong>Dynamic Entitlement Management:</strong> Assign entitlements
        dynamically based on user behavior and interactions, adapting to
        changing user needs.
      </li>
      <li className="modal-list-item">
        <strong>Secure Data Handling:</strong> Ensure that user roles and
        entitlements are managed securely and in compliance with data protection
        regulations, such as GDPR and CCPA.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      Use Case: Managing Access for a B2B2C Service Platform
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B2C service platform needed to manage
      access for both partners and end customers, ensuring that each party had
      the appropriate level of access to relevant resources without compromising
      security.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a role and
      entitlement management solution that assigned roles based on partner type
      and customer interactions, dynamically adjusting entitlements as users
      engaged with the platform.
    </p>
    <p>
      <strong>Result:</strong> The platform was able to securely manage access
      for both partners and end customers, improve operational efficiency, and
      ensure compliance with data protection regulations, resulting in better
      partner relationships, enhanced trust, and improved customer satisfaction.
    </p>
  </>
);

export default CustomerRoleManagementContent;
