import React, { useState, useEffect } from 'react';
import "./ServicesComponent1.css";

function ServicesComponent1({ scrollPercentage }) {
  const [showInitialTitle, setShowInitialTitle] = useState(false);
  const [isTitleShrunk, setIsTitleShrunk] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Observer to handle scroll into view behavior for title and content
    const componentObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowInitialTitle(true);
            setTimeout(() => {
              setIsTitleShrunk(true);
              setTimeout(() => {
                setShowContent(true);
              }, 150); // Delay before showing content
            }, 500); // Delay before starting the title shrink
            componentObserver.unobserve(entry.target); // Run the animation only once
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is in view
    );

    const componentElement = document.querySelector('.ServicesComponent1Container');
    if (componentElement) {
      componentObserver.observe(componentElement);
    }

    return () => {
      if (componentElement) {
        componentObserver.unobserve(componentElement);
      }
    };
  }, []);

  return (
    <div className='ServicesComponent1Container'>
      <div
        className={`servicesComponent_title ${showInitialTitle ? 'show' : ''} ${isTitleShrunk ? 'shrink' : ''}`}
      >
        <h1>
          Our <span className="highlightServices1">Services</span>
        </h1>
      </div>

      <div className={`ServicesComponent1Text ${showContent ? 'show' : ''}`}>
        <p>
          <span className='highlightServices1'>RAAH</span> Technologies’ services maximize existing resources,
          identify crucial gaps in IAM capabilities, and implement
          foundational IAM services that are future-proofed.
          <br /><br />
          <span className='highlightServices1'>RAAH</span> is your one stop shop for anything Identity, period.
        </p>
      </div>
    </div>
  );
}

export default ServicesComponent1;
