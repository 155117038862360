import React, {useRef, useState, useEffect} from "react";
import './ContactPage.css'
import HamburgerMenu from "../overlays/HamburgerMenu";
import ScrollArrow from "../overlays/ScrollArrow";
import Icon from "../overlays/Icon";
import RightSphere from "../overlays/RightSphere";
import LeftSphere from "../overlays/LeftSphere";
import ContactForm from "./ContactForm";

function ContactPage() {


  return (
    <div className="Contact-Page">
      <Icon />
      <HamburgerMenu />
      <LeftSphere />
      <RightSphere />
      <ScrollArrow />
      <ContactForm />
    </div>
  );
}

export default ContactPage;
