import React from 'react';
import {
  FaUsers,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const GigyaContent = () => (
  <>
    <h3 className="modal-main-title">
      Engaging Customers with SAP Customer Data Cloud (formerly Gigya)
    </h3>
    <p>
      SAP Customer Data Cloud, formerly known as Gigya, is a leader in Customer
      Identity and Access Management (CIAM), providing solutions that enable
      organizations to manage customer identities, enhance user experiences, and
      build customer trust. At <strong>RAAH Technologies</strong>, we leverage
      SAP Customer Data Cloud's capabilities to help organizations create
      seamless and secure digital experiences for their customers.
    </p>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      SAP Customer Data Cloud: A Comprehensive CIAM Solution
    </h4>
    <p>
      SAP Customer Data Cloud's platform is designed to manage customer
      identities, provide personalized experiences, and ensure compliance with
      privacy regulations. Key features include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Customer Registration:</strong> Simplifies customer onboarding
        with customizable registration forms and social login options.
      </li>
      <li className="modal-list-item">
        <strong>Consent Management:</strong> Ensures compliance with data
        privacy regulations like GDPR and CCPA by managing customer consent
        preferences.
      </li>
      <li className="modal-list-item">
        <strong>Profile Management:</strong> Provides a central platform for
        managing customer profiles, enabling personalized experiences.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enables customers to use a single
        set of credentials to access multiple digital properties, providing a
        seamless experience.
      </li>
      <li className="modal-list-item">
        <strong>Data Analytics:</strong> Offers insights into customer behavior
        and preferences, enabling organizations to create more targeted and
        effective marketing campaigns.
      </li>
    </ul>
    <p>
      SAP Customer Data Cloud allows organizations to build trust with their
      customers by providing secure and personalized digital experiences. At
      RAAH, we bring deep expertise in implementing SAP Customer Data Cloud to
      help organizations enhance customer engagement and meet regulatory
      requirements.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Enhancing Customer Experience for a Retail Brand
    </h4>
    <p>
      <strong>Challenge:</strong> A retail brand wanted to enhance customer
      engagement by providing a seamless login experience across their website
      and mobile app while ensuring compliance with data privacy regulations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented SAP Customer Data
      Cloud's CIAM solution to provide a unified customer registration and login
      experience. Social login options were added to simplify the onboarding
      process, and consent management features ensured compliance with GDPR and
      CCPA.
    </p>
    <p>
      <strong>Result:</strong> The retail brand saw a{' '}
      <strong>20% increase</strong> in customer registrations and a significant
      improvement in customer satisfaction due to the simplified login process
      and personalized experiences.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Improved Customer Engagement:</strong> Simplifies customer
        onboarding and provides personalized experiences.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Manages customer consent
        preferences to ensure compliance with data privacy regulations.
      </li>
      <li className="modal-list-item">
        <strong>Seamless User Experience:</strong> Provides Single Sign-On (SSO)
        capabilities for a consistent and convenient customer experience.
      </li>
      <li className="modal-list-item">
        <strong>Actionable Insights:</strong> Leverages customer data to create
        targeted marketing campaigns and improve customer loyalty.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we understand the importance of
      customer identity in creating exceptional digital experiences. Our
      expertise in deploying SAP Customer Data Cloud enables organizations to:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Design and implement customer identity solutions that enhance user
        experience and build customer trust.
      </li>
      <li className="modal-list-item">
        Integrate SAP Customer Data Cloud with existing systems, including CRM
        and marketing automation platforms, to create a unified customer
        experience.
      </li>
      <li className="modal-list-item">
        Ensure compliance with data privacy regulations through effective
        consent management and data governance.
      </li>
      <li className="modal-list-item">
        Provide ongoing support and optimization to adapt the solution to
        changing business needs.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD"
          target="_blank"
          rel="noopener noreferrer"
        >
          SAP Customer Data Cloud Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://community.sap.com/t5/c-khhcw49343/SAP+Customer+Data+Cloud/pd-p/73555000100800001231"
          target="_blank"
          rel="noopener noreferrer"
        >
          SAP Customer Data Cloud Community
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/SAP/sap-customer-data-cloud-accelerator"
          target="_blank"
          rel="noopener noreferrer"
        >
          SAP Customer Data Cloud Accelerator
        </a>
      </li>
    </ul>
  </>
);

export default GigyaContent;
