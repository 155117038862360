import React, { useState, useEffect } from "react";
import "./ServicesComponent3.css";
import Services1 from "../icons/Foundation.gif";
import Services2 from "../icons/Expansion.gif";
import Services3 from "../icons/Optimization.gif";
import Services4 from "../icons/Optimization.gif";

function ServicesComponent3({ scrollPercentage }) {
  const [active, setActive] = useState(0);

  const phases = [
    {
      src: Services1, header: "Foundation Builder", text: <>
        <span className="highlightServices3">Start strong with a solid identity foundation.</span>

        <br /><br />Lay the groundwork for a successful identity solution by implementing core functionalities tailored to your needs.
      </>
    },
    {
      src: Services2, header: "Expansion Mode", text: <>
        <span className="highlightServices3">Grow seamlessly with scalable identity solutions.</span>
        <br /><br />Scale your identity solutions to accommodate growth, ensuring infrastructure meets new requirements.
      </>
    }, {
      src: Services3, header: "Optimization and Mastery", text: <>
        <span className="highlightServices3">Achieve peak identity performance.</span>
        <br /><br />Fine-tune your identity systems for maximum efficiency and security, enhancing overall performance.
      </>
    },
    {
      src: Services4, header: "End to End", text: <>
        <span className="highlightServices3">Full identity management—start to finish.</span>
        <br /><br />Get comprehensive identity management from start to finish, covering all aspects of security for an integrated solution.
      </>
    },
  ];

  useEffect(() => {
    const numberOfPhases = phases.length;
    const phaseThreshold = 100 / numberOfPhases;

    phases.forEach((_, index) => {
      if (scrollPercentage >= index * phaseThreshold && scrollPercentage < (index + 1) * phaseThreshold) {
        setActive(index);
      }
    });
  }, [scrollPercentage, phases]);

  return (
    <div className='ServicesComponent3Container'>
      <div className='ServicesComponent3Text'>
        <h1>Professional Services</h1>
      </div>
      <div className="services3ContentWrapper">
        <div className="services3ImageWrapper">
          <img
            src={phases[active].src}
            alt={phases[active].header}
            className="services3Image"
          />
          <h2 className="services3Header">{phases[active].header}</h2>
        </div>
        <div className="services3TextWrapper">
          <p>{phases[active].text}</p>
        </div>
      </div>
    </div>
  )
}

export default ServicesComponent3;
