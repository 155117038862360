import React from 'react';
import Text1 from '../icons/TextBubblesDarkTheme.gif';
import Text2 from '../icons/ReactionTextBubbleDarkTheme.gif';
import Text3 from '../icons/QuestionMarkBubbleDarkTheme.gif';
import './App.css';

const H4 = () => {
    return (
        
        <div className="header-tile text-bubble-dark1-holder">
            <div className="text-bubble-container">
            <img
                src={Text1}
                alt="A representation of the Identity world"
                className="text-bubble-dark1-gif"
                /> 
            <img
                src={Text3}
                alt="A representation of the Identity world"
                className="text-bubble-dark3-gif"
                />
            <img
                src={Text2}
                alt="A representation of the Identity world"
                className="text-bubble-dark2-gif"
                /> 
            </div>
            <span className="font-link">
                <h1>What do they say </h1>
                <h1>about <span className="highlight">RAAH</span>?</h1>
            </span>
        
        </div>
    )

}

export default H4;