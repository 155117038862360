// RoleMiningContent.js

import React from 'react';
import { FaCogs, FaSearch, FaChartBar } from 'react-icons/fa';

const RoleMiningContent = () => (
  <>
    <h3 className="modal-main-title">Role Mining and Optimization</h3>
    <p>
      Role Mining and Optimization is the process of analyzing user access
      patterns to identify common roles and optimize access management. By
      understanding how users interact with systems, organizations can create
      well-defined roles that provide the appropriate level of access, reducing
      the risk of over-privileged accounts.
    </p>

    <h4 className="modal-subheading">
      <FaCogs size={30} style={{ marginRight: '10px' }} />
      Benefits of Role Mining and Optimization
    </h4>
    <p>
      Implementing Role Mining and Optimization helps organizations improve
      security, simplify access management, and reduce the complexity of role
      assignments.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Identifies and removes excessive
        permissions, ensuring users have only the access they need to perform
        their jobs.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Streamlines role assignments by
        grouping similar permissions together, making it easier to manage user
        access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Helps organizations maintain compliance by
        ensuring that access is granted based on well-defined roles that align
        with business policies.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaSearch size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Role Mining
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive Role Mining and
      Optimization solutions to help organizations effectively manage user
      access. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Data-Driven Analysis:</strong> Use data-driven analysis to
        identify common access patterns and define roles that align with
        organizational needs.
      </li>
      <li className="modal-list-item">
        <strong>Role Optimization:</strong> Refine existing roles to remove
        unnecessary permissions, ensuring that users have only what they need to
        perform their duties.
      </li>
      <li className="modal-list-item">
        <strong>Visual Reports:</strong> Provide visual reports and dashboards
        to help administrators understand role assignments and optimize access
        management.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaChartBar size={30} style={{ marginRight: '10px' }} />
      Use Case: Optimizing Access Roles in a Retail Company
    </h4>
    <p>
      <strong>Challenge:</strong> A retail company needed to optimize access
      roles to ensure that employees had the appropriate level of access while
      reducing the risk of over-privileged accounts.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies performed a role mining
      analysis to identify common access patterns among employees and created
      optimized roles that aligned with job functions.
    </p>
    <p>
      <strong>Result:</strong> The company improved its security posture by
      reducing excessive permissions, simplified access management processes,
      and ensured compliance with internal policies.
    </p>
  </>
);

export default RoleMiningContent;
