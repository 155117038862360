import React from 'react';
import {
  FaBuilding,
  FaShieldAlt,
  FaPeopleArrows,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const GovernmentContent = () => (
  <>
    <h3 className="modal-main-title">
      Enhancing Security for Government and Public Sector with Identity
      Solutions
    </h3>
    <p>
      The government and public sector face unique challenges when it comes to
      secure access and identity management. At{' '}
      <strong>RAAH Technologies</strong>, we offer identity solutions tailored
      to meet the specific needs of government entities, ensuring security,
      compliance, and efficient service delivery to the public.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in the Government and Public Sector
    </h4>
    <p>
      Government agencies must secure sensitive citizen data, manage complex
      workflows, and comply with stringent regulations. Our identity solutions
      address these key challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing employee access to government
        systems and sensitive data, ensuring compliance with regulatory
        requirements.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Providing Identity Governance and Administration
        to manage user roles, entitlements, and ensure accountability through
        audit trails.
      </li>
      <li className="modal-list-item">
        <strong>Citizen Identity Management (CIAM):</strong> Offering seamless,
        secure access to public services, enabling citizens to engage safely
        with government platforms.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for external contractors,
        partners, and inter-agency collaboration, ensuring secure integration.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that have access to
        critical government systems, mitigating risks of insider threats and
        unauthorized access.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaPeopleArrows size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Government and Public Sector
    </h4>
    <p>Our customized identity solutions for government include:</p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect access to sensitive government systems,
        safeguarding against unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring government
        employees and contractors have appropriate access based on their roles,
        reducing the risk of data breaches.
      </li>
      <li className="modal-list-item">
        <strong>Citizen Identity and Access Management (CIAM):</strong> Enabling
        secure access to government services for citizens, providing a seamless
        experience for accessing public resources.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Protecting
        administrative tools and critical systems by controlling access to
        privileged accounts.
      </li>
      <li className="modal-list-item">
        <strong>Federated Identity Management:</strong> Facilitating secure
        collaboration between government agencies and with external partners by
        managing their identities efficiently.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: City Government of Metropolis
    </h4>
    <p>
      <strong>Challenge:</strong> The City Government of Metropolis needed to
      secure employee and contractor access to critical municipal systems while
      providing citizens with a seamless way to access online government
      services.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided an integrated
      identity solution that included Workforce IAM, CIAM, and PAM. We
      implemented Multi-Factor Authentication (MFA) across workforce channels,
      and Role-Based Access Control (RBAC) was used to manage access to
      municipal systems based on user roles.
    </p>
    <p>
      <strong>Result:</strong> The City Government of Metropolis achieved a{' '}
      <strong>35% reduction</strong> in unauthorized access attempts, improved
      security posture, and enhanced citizen satisfaction with a more secure,
      user-friendly online service portal.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Comprehensive IAM and PAM solutions
        protect critical infrastructure and sensitive data from unauthorized
        access and insider threats.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to government
        regulations, reducing the risk of non-compliance and associated
        penalties.
      </li>
      <li className="modal-list-item">
        <strong>Improved Citizen Experience:</strong> Secure, seamless access to
        government services through CIAM, enhancing citizen engagement and
        trust.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Streamlined identity management
        for government employees and contractors, reducing the burden on IT
        teams and improving service delivery.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As government services continue to digitize, secure identity solutions are
      essential to meet regulatory requirements and enhance service delivery. At{' '}
      <strong>RAAH Technologies</strong>, we are committed to delivering
      advanced IAM, IGA, CIAM, and PAM solutions that help government entities
      ensure security, compliance, and citizen trust.
    </p>
  </>
);

export default GovernmentContent;
