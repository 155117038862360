import React from 'react';
import Curve from '../icons/ServicesWorldDark.svg';
import './App.css';

const H2 = () => {
  return (
    <div className="header-tile curved-earth-dark-holder">
      <div className="chabababa">
        <img
          src={Curve}
          alt="A representation of the Identity world"
          className="curved-earth-dark-svg"
          key={Date.now()}
        />{' '}
      </div>

      <span className="font-link unique-margin">
        <h1>What Services does </h1>
        <h1>
          <span className="highlight">RAAH</span> offer?
        </h1>
      </span>
    </div>
  );
};

export default H2;
