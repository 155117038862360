// Import React and CSS
import React, { useState, useEffect, useRef } from 'react';
import './solutionsComponent4.css';
import FullScreenModal from '../fullscreenModalComponents/fullScreenModal';

// Import icons from FontAwesome
import {
  FaUserShield,
  FaLock,
  FaKey,
  FaUsers,
  FaSync,
  FaIdBadge,
  FaShieldAlt,
  FaTasks,
  FaCogs,
  FaClipboardCheck,
  FaClipboardList,
  FaUserCog,
  FaProjectDiagram,
  FaUserSecret,
  FaHandshake,
  FaUserLock,
  FaGavel,
  FaBuilding,
  FaMobileAlt,
  FaChartLine,
  FaUserPlus,
  FaDoorOpen,
  FaCodeBranch,
  FaClock,
  FaNetworkWired,
} from 'react-icons/fa';

// Import detailed body content for each use case
import AccessCertificationContent from '../fullscreenModalComponents/AccessCertificationContent';
import AccessRequestContent from '../fullscreenModalComponents/AccessRequestContent';
import AccountDiscoveryContent from '../fullscreenModalComponents/AccountDiscoveryContent';
import AccountRecoveryContent from '../fullscreenModalComponents/AccountRecoveryContent';
import AdaptiveAuthContent from '../fullscreenModalComponents/AdaptiveAuthContent';
import AdminMonitoringContent from '../fullscreenModalComponents/AdminMonitoringContent';
import APISecurityContent from '../fullscreenModalComponents/APISecurityContent';
import AuditComplianceWorkforceContent from '../fullscreenModalComponents/AuditComplianceWorkforceContent';
import AuditCompliancePartnerandConsumerContent from '../fullscreenModalComponents/CrossOrgCollaborationContent';
import CrossOrgCollaborationContent from '../fullscreenModalComponents/CrossOrgCollaborationContent';
import CustomerProfileContent from '../fullscreenModalComponents/CustomerProfileContent';
import CustomerRoleManagementContent from '../fullscreenModalComponents/CustomerRoleManagementContent';
import DelegatedAdminContent from '../fullscreenModalComponents/DelegatedAdminContent';
import DeviceEndpointContent from '../fullscreenModalComponents/DeviceEndpointContent';
import EmployeeOnboardingContent from '../fullscreenModalComponents/EmployeeOnboardingContent';
import GranularAccessControlsContent from '../fullscreenModalComponents/GranularAccessControlsContent';
import IdentityLifecycleContent from '../fullscreenModalComponents/IdentityLifecycleContent';
import IdentityThreatDetectionContent from '../fullscreenModalComponents/IdentityThreatDetectionContent';
import JITPrivilegedAccessContent from '../fullscreenModalComponents/JITPrivilegedAccessContent';
import MFAEnhancedSecurityContent from '../fullscreenModalComponents/MFAEnhancedSecurityContent';
import PartnerFederationContent from '../fullscreenModalComponents/PartnerFederationContent';
import PasswordlessAuthenticationContent from '../fullscreenModalComponents/PasswordlessAuthenticationContent';
import PrivilegedAccessGovernanceContent from '../fullscreenModalComponents/PrivilegedAccessGovernanceContent';
import PrivilegedAccountMonitoringContent from '../fullscreenModalComponents/PrivilegedAccountMonitoringContent';
import PrivilegedTaskAutomationContent from '../fullscreenModalComponents/PrivilegedTaskAutomationContent';
import ProgressiveProfilingContent from '../fullscreenModalComponents/ProgressiveProfilingContent';
import RBACContent from '../fullscreenModalComponents/RBACContent';
import RoleMiningContent from '../fullscreenModalComponents/RoleMiningContent';
import SeamlessCustomerExperienceContent from '../fullscreenModalComponents/SeamlessCustomerExperienceContent';
import SocialLoginContent from '../fullscreenModalComponents/SocialLoginContent';
import SoDContent from '../fullscreenModalComponents/SoDContent';
import SSOEmployeeContent from '../fullscreenModalComponents/SSOEmployeeContent';
import SupplierVendorIdentityContent from '../fullscreenModalComponents/SupplierVendorIdentityContent';
import UserEntitlementContent from '../fullscreenModalComponents/UserEntitlementContent';
import UserRegistrationContent from '../fullscreenModalComponents/UserRegistrationContent';
import ZeroTrustSecurityContent from '../fullscreenModalComponents/ZeroTrustSecurityContent';

const categories = [
  'Workforce (B2E) IAM and IGA',
  'Consumer (B2C, B2B/B2B2C) IAM',
  'Privileged Access Management',
];

const useCases = [
  {
    category: 'Workforce (B2E) IAM and IGA',
    icon: <FaUsers className="sc4-icon" />,
    items: [
      {
        title: 'Single Sign-On (SSO) for Employees',
        description: 'Secure and simplify employee access across systems.',
        icon: <FaUserShield className="sc4-icon" />,
        body: <SSOEmployeeContent />,
      },
      {
        title: 'Multi-factor Authentication (MFA) for Enhanced Security',
        description: 'Enhance security by adding MFA options.',
        icon: <FaShieldAlt className="sc4-icon" />,
        body: <MFAEnhancedSecurityContent />,
      },
      {
        title: 'Role-Based Access Control (RBAC)',
        description: 'Manage access permissions using defined roles.',
        icon: <FaKey className="sc4-icon" />,
        body: <RBACContent />,
      },
      {
        title: 'Device and Endpoint Identity Management',
        description:
          'Secure and manage identities across devices and endpoints.',
        icon: <FaMobileAlt className="sc4-icon" />,
        body: <DeviceEndpointContent />,
      },
      {
        title: 'Passwordless Authentication for Workforce',
        description:
          'Improve security and user experience with passwordless options.',
        icon: <FaLock className="sc4-icon" />,
        body: <PasswordlessAuthenticationContent />,
      },
      {
        title: 'Zero Trust Security and Adaptive Access Controls',
        description: 'Implement zero trust principles for adaptive access.',
        icon: <FaShieldAlt className="sc4-icon" />,
        body: <ZeroTrustSecurityContent />,
      },
      {
        title: 'Employee Onboarding and Offboarding',
        description:
          'Automate onboarding and offboarding to streamline identity management.',
        icon: <FaUserPlus className="sc4-icon" />,
        body: <EmployeeOnboardingContent />,
      },
      {
        title: 'Access Certification and Attestation',
        description: 'Regularly verify and certify user access for compliance.',
        icon: <FaClipboardCheck className="sc4-icon" />,
        body: <AccessCertificationContent />,
      },
      {
        title: 'Access Request and Workflow Automation',
        description: 'Automate access requests and approval workflows.',
        icon: <FaTasks className="sc4-icon" />,
        body: <AccessRequestContent />,
      },
      {
        title: 'Identity Lifecycle Management',
        description:
          'Manage the complete lifecycle of user identities, from onboarding to deactivation.',
        icon: <FaSync className="sc4-icon" />,
        body: <IdentityLifecycleContent />,
      },
      {
        title: 'Role Mining and Optimization',
        description:
          'Discover and optimize role definitions for better access management.',
        icon: <FaCogs className="sc4-icon" />,
        body: <RoleMiningContent />,
      },
      {
        title: 'Separation of Duties (SoD) Management',
        description: 'Ensure compliance by managing separation of duties.',
        icon: <FaClipboardList className="sc4-icon" />,
        body: <SoDContent />,
      },
      {
        title: 'Granular Access Controls',
        description:
          'Enforce fine-grained access policies for better security.',
        icon: <FaLock className="sc4-icon" />,
        body: <GranularAccessControlsContent />,
      },
      {
        title: 'User Entitlement Management',
        description: 'Manage user entitlements efficiently.',
        icon: <FaKey className="sc4-icon" />,
        body: <UserEntitlementContent />,
      },
      {
        title: 'Audit Compliance',
        description: 'Ensure adherence to regulatory compliance standards.',
        icon: <FaGavel className="sc4-icon" />,
        body: <AuditComplianceWorkforceContent />,
      },
    ],
  },
  {
    category: 'Consumer (B2C, B2B/B2B2C) IAM',
    icon: <FaUserCog className="sc4-icon" />,
    items: [
      {
        title: 'User Registration and Self-Service Portals',
        description: 'Enable users to register and manage their own accounts.',
        icon: <FaUserPlus className="sc4-icon" />,
        body: <UserRegistrationContent />,
      },
      {
        title: 'Social and Federated Login for Customers',
        description:
          'Allow users to log in with social media or other federated identities.',
        icon: <FaHandshake className="sc4-icon" />,
        body: <SocialLoginContent />,
      },
      {
        title: 'Customer Profile and Consent Management',
        description: 'Manage customer data and consent preferences.',
        icon: <FaIdBadge className="sc4-icon" />,
        body: <CustomerProfileContent />,
      },
      {
        title: 'API Security and Access Control',
        description: 'Secure APIs and manage access policies effectively.',
        icon: <FaNetworkWired className="sc4-icon" />,
        body: <APISecurityContent />,
      },
      {
        title: 'Seamless Customer Experience across Channels',
        description:
          'Provide consistent user experiences across devices and platforms.',
        icon: <FaChartLine className="sc4-icon" />,
        body: <SeamlessCustomerExperienceContent />,
      },
      {
        title: 'Real-Time Identity Threat Detection for Customers',
        description: 'Detect and respond to identity threats in real time.',
        icon: <FaUserSecret className="sc4-icon" />,
        body: <IdentityThreatDetectionContent />,
      },
      {
        title: 'Progressive Profiling',
        description:
          'Gradually collect user information to improve user experience and personalization.',
        icon: <FaUsers className="sc4-icon" />,
        body: <ProgressiveProfilingContent />,
      },
      {
        title: 'Customer Role and Entitlement Management',
        description:
          'Assign and manage customer roles and entitlements effectively.',
        icon: <FaKey className="sc4-icon" />,
        body: <CustomerRoleManagementContent />,
      },
      {
        title: 'Cross-Organization Collaboration',
        description:
          'Enable secure collaboration between different organizations.',
        icon: <FaProjectDiagram className="sc4-icon" />,
        body: <CrossOrgCollaborationContent />,
      },
      {
        title: 'Account Recovery and Identity Assurance',
        description:
          'Assist users in recovering their accounts securely while maintaining identity assurance.',
        icon: <FaLock className="sc4-icon" />,
        body: <AccountRecoveryContent />,
      },
      {
        title: 'Partner Federation',
        description: 'Federate identities across partner organizations.',
        icon: <FaHandshake className="sc4-icon" />,
        body: <PartnerFederationContent />,
      },
      {
        title: 'Delegated Administration',
        description:
          'Delegate administrative tasks to specific users or roles for improved efficiency.',
        icon: <FaIdBadge className="sc4-icon" />,
        body: <DelegatedAdminContent />,
      },
      {
        title: 'Contextual and Risk-Based Access',
        description:
          'Use context and risk factors to manage access dynamically.',
        icon: <FaShieldAlt className="sc4-icon" />,
        body: <IdentityThreatDetectionContent />,
      },
      {
        title: 'Partner and Consumer Identity Audit Compliance',
        description: 'Ensure partner organizations meet compliance standards.',
        icon: <FaClipboardCheck className="sc4-icon" />,
        body: <AuditCompliancePartnerandConsumerContent />,
      },
      {
        title: 'Supplier and Vendor Identity Management',
        description: 'Manage identities of suppliers and vendors.',
        icon: <FaBuilding className="sc4-icon" />,
        body: <SupplierVendorIdentityContent />,
      },
      {
        title: 'Adaptive Authentication',
        description:
          'Adapt authentication based on real-time context and risk.',
        icon: <FaCodeBranch className="sc4-icon" />,
        body: <AdaptiveAuthContent />,
      },
    ],
  },
  {
    category: 'Privileged Access Management',
    icon: <FaKey className="sc4-icon" />,
    items: [
      {
        title: 'Administrator and High-Risk User Monitoring',
        description: 'Monitor privileged accounts for suspicious activity.',
        icon: <FaUserSecret className="sc4-icon" />,
        body: <AdminMonitoringContent />,
      },
      {
        title: 'Just-In-Time (JIT) Privileged Access',
        description:
          'Grant temporary access to privileged resources as needed.',
        icon: <FaClock className="sc4-icon" />,
        body: <JITPrivilegedAccessContent />,
      },
      {
        title: 'Privileged Account Discovery and Lifecycle Management',
        description: 'Manage the lifecycle of privileged accounts.',
        icon: <FaProjectDiagram className="sc4-icon" />,
        body: <AccountDiscoveryContent />,
      },
      {
        title: 'Session Monitoring and Audit',
        description: 'Monitor admin activities and ensure audit compliance.',
        icon: <FaUserLock className="sc4-icon" />,
        body: <PrivilegedAccountMonitoringContent />,
      },
      {
        title: 'Privileged Task Automation',
        description:
          'Automate tasks performed by privileged users to enhance efficiency and security.',
        icon: <FaTasks className="sc4-icon" />,
        body: <PrivilegedTaskAutomationContent />,
      },
      {
        title: 'Privileged Access Governance',
        description:
          'Ensure proper governance of privileged accounts and access levels.',
        icon: <FaClipboardCheck className="sc4-icon" />,
        body: <PrivilegedAccessGovernanceContent />,
      },
    ],
  },
];

function SolutionsComponent4() {
  const [selectedCategory, setSelectedCategory] = useState(null); // Updated default to `null`
  const [expandedCategories, setExpandedCategories] = useState({});
  const [showInitialTitle, setShowInitialTitle] = useState(false);
  const [isTitleShrunk, setIsTitleShrunk] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    icon: null,
    body: null,
  });
  const componentRef = useRef(null);
  const animationRunOnce = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationRunOnce.current) {
            animationRunOnce.current = true;
            setShowInitialTitle(true);
            setTimeout(() => {
              setIsTitleShrunk(true);
              setTimeout(() => {
                setShowContent(true);
              }, 150);
            }, 500);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleCategoryChange = (category) => {
    if (selectedCategory === category) {
      // Deselect category if clicked again
      setSelectedCategory(null);
    } else {
      // Set the selected category
      setSelectedCategory(category);
      setExpandedCategories((prev) => ({
        ...prev,
        [category]: true,
      }));
    }
  };

  const toggleCategoryExpand = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleLearnMoreClick = (item) => {
    setModalContent({
      title: item.title,
      icon: item.icon,
      body: item.body,
    });
    setIsModalOpen(true);
  };

  const filteredUseCases = useCases.filter(
    (useCase) => useCase.category === selectedCategory
  );

  return (
    <div
      className="sc4-solutionsComponent4Unique sc4-centerView"
      ref={componentRef}
    >
      <h1
        className={`sc4-titleUnique ${showInitialTitle ? 'showUnique' : ''} ${
          isTitleShrunk ? 'shrinkUnique' : ''
        }`}
      >
        By <span className="useCaseHighlight">Use Case</span>
      </h1>

      <p className="sc4-descriptionUnique">
        {selectedCategory === null
          ? 'Select a category below to see more'
          : 'Select a category to explore relevant use cases:'}
        {selectedCategory === null && (
          <span className="sc4-down-arrow">⬇️</span>
        )}
      </p>

      <div
        className={`sc4-filterOptionsUnique ${showContent ? 'showUnique' : ''}`}
      >
        {categories.map((category, index) => (
          <button
            key={index}
            className={`sc4-filterButtonUnique ${
              selectedCategory === category ? 'active' : 'sc4-animate-border'
            }`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {selectedCategory && (
        <div
          className={`sc4-useCaseContainerUnique ${
            showContent ? 'showUnique' : ''
          }`}
        >
          <div className="sc4-useCaseGridUnique">
            {filteredUseCases.map((useCase, index) => (
              <div key={index} className="sc4-useCaseCategoryUnique">
                <div
                  className="sc4-categoryHeaderUnique"
                  onClick={() => toggleCategoryExpand(useCase.category)}
                >
                  <div className="sc4-categoryIconUnique">{useCase.icon}</div>
                  <h3>{useCase.category}</h3>
                  <span
                    className={`sc4-arrowUnique ${
                      expandedCategories[useCase.category] ? 'expanded' : ''
                    }`}
                  >
                    &#9662;
                  </span>
                </div>
                {expandedCategories[useCase.category] && (
                  <div className="sc4-categoryItemsUnique">
                    {useCase.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className="sc4-useCaseCardUnique"
                        onClick={() => handleLearnMoreClick(item)}
                      >
                        <div className="sc4-useCaseCardContent">
                          <div className="sc4-useCaseCardIconUnique">
                            {item.icon}
                          </div>
                          <div className="sc4-useCaseCardTitleUnique">
                            {item.title}
                          </div>
                          <div className="sc4-useCaseCardDescriptionUnique">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <FullScreenModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
        body={modalContent.body}
      />
    </div>
  );
}

export default SolutionsComponent4;
