import React, { useState, useEffect, useRef } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import './HamburgerMenu.css'; // Use the updated CSS for mobile responsiveness

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenuOnEsc = (event) => {
    if (event.key === 'Escape' && isOpen) {
      setIsOpen(false);
    }
  };

  const closeMenuOnOverlayClick = (event) => {
    const overlay = document.querySelector('.menu-overlay.open');
    if (overlay && event.target === overlay) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listeners for keydown and mousedown
    window.addEventListener('keydown', closeMenuOnEsc);
    window.addEventListener('mousedown', closeMenuOnOverlayClick);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', closeMenuOnEsc);
      window.removeEventListener('mousedown', closeMenuOnOverlayClick);
    };
  }, [isOpen]);

  return (
    <div className="hamburger-menu">
      <div
        className={`hamburger-icon ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <RxHamburgerMenu size={40} />
      </div>

      <div className={`menu-overlay ${isOpen ? 'open' : ''}`}>
        <ul className="menu-links" ref={menuRef}>
          <li><a href="/home">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/solutions">Solutions</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/news">News</a></li>
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenu;
