import React from 'react';
import {
  FaLock,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaKey,
  FaCode,
} from 'react-icons/fa';

const DelineaContent = () => (
  <>
    <h3 className="modal-main-title">
      Simplifying Privileged Access with Delinea (Formerly Thycotic)
    </h3>
    <p>
      Delinea (formerly Thycotic) offers a seamless and scalable approach to
      Privileged Access Management (PAM) that enhances security without
      complexity. At <strong>RAAH Technologies</strong>, we help organizations
      leverage Delinea's capabilities to secure privileged credentials, automate
      secrets management, and enforce least privilege—all while simplifying the
      user experience.
    </p>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      Delinea: Seamless and Secure Privileged Access
    </h4>
    <p>
      Delinea provides a wide range of privileged access security features,
      which are essential for organizations looking to secure their IT
      environments without overwhelming users or administrators. Key features
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Secret Server:</strong> A secure, enterprise-class vault to
        store and manage privileged credentials, secrets, and access keys with
        encryption and auditing.
      </li>
      <li className="modal-list-item">
        <strong>Privilege Manager:</strong> Enforces least privilege policies on
        endpoints, ensuring that users operate with just enough privileges to
        perform their tasks, thereby minimizing risks.
      </li>
      <li className="modal-list-item">
        <strong>Account Lifecycle Management:</strong> Automates the discovery,
        onboarding, management, and de-provisioning of privileged accounts,
        reducing human error and improving efficiency.
      </li>
      <li className="modal-list-item">
        <strong>Session Recording and Monitoring:</strong> Records privileged
        sessions for auditing and compliance, providing visibility into what
        privileged users are doing within critical systems.
      </li>
      <li className="modal-list-item">
        <strong>Just-in-Time Access:</strong> Minimizes the standing privileges
        by granting privileged access only when needed and revoking it
        afterward, enhancing security without hindering productivity.
      </li>
    </ul>
    <p>
      Delinea's solutions are designed to help businesses mitigate risks
      associated with privileged accounts while enhancing productivity and
      maintaining compliance. At RAAH, we bring expertise in deploying Delinea
      to fit your organization's needs, ensuring a seamless user experience and
      effective security controls.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Simplifying Privileged Access for a Healthcare Provider
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare provider needed to secure
      administrative access to sensitive patient data while maintaining a high
      level of operational efficiency. They faced challenges in implementing
      least privilege without impacting productivity and needed a solution that
      would be simple to use for their IT and administrative teams.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Delinea's
      Privilege Manager to enforce least privilege policies across endpoints,
      ensuring users operated with only the permissions required for their role.
      Secret Server was also deployed to securely vault administrative
      credentials and automate password rotation. Additionally, Just-in-Time
      access was used to grant elevated privileges only when necessary,
      minimizing security risks.
    </p>
    <p>
      <strong>Result:</strong> The healthcare provider experienced a{' '}
      <strong>60% improvement</strong> in security, reduced the number of
      standing privileges by <strong>75%</strong>, and streamlined their IT
      operations without compromising productivity.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Reduced Privileged Account Risk:</strong> Automatically vaults,
        rotates, and monitors privileged credentials to reduce risk.
      </li>
      <li className="modal-list-item">
        <strong>Seamless User Experience:</strong> Provides security with
        minimal user friction, allowing employees to continue working without
        disruptions.
      </li>
      <li className="modal-list-item">
        <strong>Compliance Assurance:</strong> Delivers auditing, session
        recording, and policy enforcement to help organizations meet regulatory
        requirements.
      </li>
      <li className="modal-list-item">
        <strong>Automated Privilege Management:</strong> Streamlines the
        onboarding, management, and lifecycle of privileged accounts to reduce
        manual intervention.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we understand that securing
      privileged access is a critical aspect of any security strategy. Our
      expertise in deploying Delinea enables organizations to:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Design and implement least privilege policies that ensure security
        without hindering productivity.
      </li>
      <li className="modal-list-item">
        Seamlessly integrate Delinea's PAM solutions into existing IT
        environments, ensuring they align with organizational workflows.
      </li>
      <li className="modal-list-item">
        Train IT teams on how to effectively manage and audit privileged
        accounts using Delinea, ensuring best practices are followed.
      </li>
      <li className="modal-list-item">
        Provide ongoing support and optimization to adapt the PAM solution to
        evolving business and regulatory needs.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://docs.delinea.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Delinea Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://www.delinea.com/resources"
          target="_blank"
          rel="noopener noreferrer"
        >
          Delinea Resources
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/DelineaXPM"
          target="_blank"
          rel="noopener noreferrer"
        >
          Delinea GitHub Repository
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://delinea.com/support"
          target="_blank"
          rel="noopener noreferrer"
        >
          Delinea Support
        </a>
      </li>
    </ul>
  </>
);

export default DelineaContent;
