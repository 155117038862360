import React from 'react';
import { FaUserShield, FaCogs, FaUsers } from 'react-icons/fa';

const RBACContent = () => (
  <>
    <h3 className="modal-main-title">Role-Based Access Control (RBAC)</h3>
    <p>
      Role-Based Access Control (RBAC) is a policy-neutral access control
      mechanism defining the roles and permissions assigned to users within an
      organization. With RBAC, user access is determined based on their role,
      which makes it easier to manage permissions and enforce security policies.
    </p>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Benefits of RBAC
    </h4>
    <p>
      Implementing RBAC helps organizations improve security, reduce
      administrative overhead, and enforce compliance.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Reduced Complexity:</strong> Managing permissions by roles
        rather than individual users significantly reduces administrative
        burden.
      </li>
      <li className="modal-list-item">
        <strong>Improved Security:</strong> RBAC ensures users only have access
        to the data and systems required for their role, thereby minimizing the
        risk of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> RBAC helps organizations meet regulatory
        standards by defining clear and manageable access rights.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCogs size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports RBAC Implementation
    </h4>
    <p>
      <strong>RAAH Technologies</strong> assists businesses in defining and
      implementing RBAC policies that align with operational needs. Our RBAC
      solutions provide:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Role Customization:</strong> Customize roles according to unique
        organizational needs to ensure that access is accurately tailored.
      </li>
      <li className="modal-list-item">
        <strong>Seamless Integration:</strong> Integrate RBAC policies with your
        existing IAM infrastructure and enforce access controls without
        disruptions.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Reporting:</strong> Track and audit role assignments
        and permission changes for compliance and security insights.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Use Case: Access Management for Financial Services
    </h4>
    <p>
      <strong>Challenge:</strong> A financial services company needed to manage
      access to sensitive data effectively while meeting regulatory
      requirements.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed a Role-Based Access
      Control solution that segmented employees by roles, granting appropriate
      access based on role requirements.
    </p>
    <p>
      <strong>Result:</strong> The company improved its security posture,
      minimized the risk of unauthorized access, and achieved compliance with
      industry regulations.
    </p>
  </>
);

export default RBACContent;
