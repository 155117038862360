import React, { useState, useEffect } from "react";
import "./ServicesComponent2.css";
import Services1 from "../icons/GovernanceAdvisory.gif";
import Services2 from "../icons/ExperienceAdvisory.gif";
import Services3 from "../icons/CollaborationAdvisory.gif";
import Services4 from "../icons/PrivilegedAdvisory.gif";
import Services5 from "../icons/MasterplanAdvisory.gif";

function ServicesComponent2({ scrollPercentage }) {
  const [active, setActive] = useState(0);

  const phases = [
    {
      src: Services1, header: "Workforce Access Blueprint and Governance Mastery", text: <>
        <span className="highlightServices2">Deliver secure, seamless employee experiences.</span>
        <br /><br />We craft a strategic workforce access plan to ensure governance and compliance, aligning access management with your growth goals.
      </>
    },
    {
      src: Services2, header: "Customer Identity Evolution and Secure Experience", text: <>
        <span className="highlightServices2">Evolve customer identity with security and trust.</span>
        <br /><br />We design an advanced customer identity roadmap to enhance user experience while safeguarding sensitive data.
      </>
    },
    {
      src: Services3, header: "Partner Access Synergy and Collaboration Framework", text: <>
        <span className="highlightServices2">Seamless integration with secure identity frameworks.</span>
        <br /><br />Create a secure partner access strategy that promotes collaboration while maintaining secure boundaries.
      </>
    },
    {
      src: Services4, header: "Privileged Access Fortification and Risk Strategy", text: <>
        <span className="highlightServices2">Reduce risk, protect critical systems.</span>
        <br /><br />Strengthen your security by fortifying privileged access, mitigating risks associated with critical systems.
      </>
    },
    {
      src: Services5, header: "Masterplan Identity Strategy and Advisory", text: <>
        <span className="highlightServices2">Informed decisions for lasting identity solutions.</span>
        <br /><br />Develop a holistic identity strategy that aligns with your long-term objectives across the identity lifecycle.
      </>
    },
  ];

  useEffect(() => {
    const numberOfPhases = phases.length;
    const phaseThreshold = 100 / numberOfPhases;

    phases.forEach((_, index) => {
      if (scrollPercentage >= index * phaseThreshold && scrollPercentage < (index + 1) * phaseThreshold) {
        setActive(index);
      }
    });
  }, [scrollPercentage, phases]);

  return (
    <div className='ServicesComponent2Container'>
      <div className='ServicesComponent2Text'>
        <h1>Advisory Services</h1>
      </div>
      <div className="services2ContentWrapper">
        <div className="services2ImageWrapper">
          <img
            src={phases[active].src}
            alt={phases[active].header}
            className="services2Image"
          />
          <h2 className="services2Header">{phases[active].header}</h2>
        </div>
        <div className="services2TextWrapper">
          <p>{phases[active].text}</p>
        </div>
      </div>
    </div>
  )
}

export default ServicesComponent2;