// AdminHighRiskUserMonitoringContent.js

import React from 'react';
import { FaUserShield, FaExclamationTriangle, FaLock } from 'react-icons/fa';

const AdminMonitoringContent = () => (
  <>
    <h3 className="modal-main-title">
      Administrator and High-Risk User Monitoring
    </h3>
    <p>
      Administrator and High-Risk User Monitoring is a crucial component of
      Privileged Access Management (PAM). It involves continuously monitoring
      the activities of privileged users, such as system administrators and
      other high-risk individuals, to detect and respond to suspicious actions
      that could pose a security threat. This approach helps prevent
      unauthorized access, data breaches, and misuse of privileged accounts.
    </p>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Benefits of Administrator and High-Risk User Monitoring
    </h4>
    <p>
      Implementing Administrator and High-Risk User Monitoring helps
      organizations enhance security, maintain accountability, and ensure
      compliance with data protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Continuously monitor privileged user
        activities to detect and respond to suspicious behavior, minimizing the
        risk of data breaches and insider threats.
      </li>
      <li className="modal-list-item">
        <strong>Accountability:</strong> Maintain detailed logs of all
        privileged user actions, ensuring that administrators and high-risk
        users are held accountable for their activities.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that privileged user activities
        comply with regulations like GDPR and CCPA, protecting sensitive
        information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaExclamationTriangle size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Administrator and High-Risk User Monitoring
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers Privileged Access Management
      (PAM) solutions that include Administrator and High-Risk User Monitoring.
      Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Real-Time Activity Monitoring:</strong> Continuously monitor
        privileged user activities in real-time to detect and respond to
        suspicious actions.
      </li>
      <li className="modal-list-item">
        <strong>Behavioral Analytics:</strong> Use behavioral analytics to
        establish a baseline of normal activities and identify deviations that
        may indicate potential threats.
      </li>
      <li className="modal-list-item">
        <strong>Audit Logging:</strong> Maintain detailed audit logs of all
        privileged user actions, providing a comprehensive record for compliance
        and incident response.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Use Case: Monitoring High-Risk Users in a Financial Services Company
    </h4>
    <p>
      <strong>Challenge:</strong> A financial services company needed to monitor
      the activities of privileged users to ensure that sensitive financial data
      was not being accessed or misused.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an Administrator
      and High-Risk User Monitoring solution that used real-time activity
      monitoring and behavioral analytics to detect suspicious behavior. Audit
      logs were maintained for all privileged user actions to ensure
      accountability.
    </p>
    <p>
      <strong>Result:</strong> The financial services company improved security,
      ensured compliance with data protection regulations, and reduced the risk
      of insider threats, resulting in enhanced trust and confidence in their
      security posture.
    </p>
  </>
);

export default AdminMonitoringContent;
