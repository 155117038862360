import React from 'react';
import './fullScreenModal.css';

function FullScreenModal({ isOpen, onClose, title, body, icon }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content fullscreen">
        {/* Modal Left Section */}
        <div className="modal-left">
          <div className="modal-icon">
            {icon && <img src={icon} alt="Icon" />}
          </div>
          <h2>{title}</h2>
          <button className="modal-close-left" onClick={onClose}>
            &times;
          </button>
        </div>

        {/* Modal Right Section with scroll area */}
        <div className="modal-right">
          <div className="modal-scroll-container">
            <div className="modal-body">{body}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullScreenModal;
