import React, { useState } from 'react';
import LinkedIn from '../icons/LinkedInIcon.svg';
import Facebook from '../icons/FacebookIcon.svg';
import Instagram from '../icons/Instagram.png';
import Email from '../icons/EmailIcon.svg';
import Mobile from '../icons/MobileIcon.svg';
import './SocialIcons.css';

function SocialIcons() {
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const navigateToSocial = (social) => {
        switch (social) {
            case "LinkedIn":
                window.open('https://www.linkedin.com/company/raahtech/', '_blank');
                break;
            case "Instagram":
                window.open('https://www.instagram.com/raah_tech/', '_blank'); 
                break;
            case "Facebook":
                window.open('https://www.facebook.com/raahtech/', '_blank'); 
                break;
            case "Email":
                window.open('mailto:solutions@raahtech.com', '_blank');
                break;
            case "Phone":
                window.open('tel:+1234567890', '_blank');
                break;
            default:
                break;
        }
    };

    const iconDetails = {
        LinkedIn: "RAAH Tech LinkedIn",
        Instagram: "RAAH Tech Instagram",
        Facebook: "RAAH Tech Facebook",
        Email: "Email us at solutions@raahtech.com",
        Phone: ""
    };

    return (
        <div className='social-icons-container'>
            <div className='left-social-icons'>
                {Object.entries(iconDetails).map(([iconName, detail]) => (
                    <div
                        key={iconName}
                        className='social-icon-wrapper'
                        onMouseEnter={() => setHoveredIcon(iconName)}
                        onMouseLeave={() => setHoveredIcon(null)}
                        onClick={() => navigateToSocial(iconName)}
                    >
                        <img
                            className={iconName}
                            alt={iconName}
                            src={
                                iconName === "LinkedIn" ? LinkedIn :
                                iconName === "Instagram" ? Instagram :
                                iconName === "Facebook" ? Facebook :
                                iconName === "Email" ? Email :
                                Mobile
                            }
                            width={20}
                            height={20}
                        />
                        {hoveredIcon === iconName && (
                            <div className="tooltip">{detail}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SocialIcons;
