import React, { useState, useEffect } from "react";
import "./ServicesComponent5.css"; // Updated CSS import
import Services1 from "../icons/Admin.gif";
import Services2 from "../icons/Support.gif";
import Services3 from "../icons/Project.gif";
import Services4 from "../icons/Special.gif";

function ServicesComponent5({ scrollPercentage }) {
  const [active, setActive] = useState(0);

  const phases = [
    {
      src: Services1, header: "Administration and Operations Staff", text: <>
        <span className="highlightServices5">Boost your identity team with expert support.</span>
        <br /><br />Augment your identity team with skilled admin and operations personnel to keep programs running efficiently.
      </>
    },
    {
      src: Services2, header: "Support and HelpDesk Staffing", text: <>
        <span className="highlightServices5">Efficient support for identity-related issues.</span>
        <br /><br />Enhance your support capabilities with specialized HelpDesk staff experienced in identity management.
      </>
    },
    {
      src: Services3, header: "Short/Long Term Leadership and Project/Program Management Staffing", text: <>
        <span className="highlightServices5">Drive identity initiatives with expert leadership.</span>
        <br /><br />Bring in experienced leaders for short-term projects or long-term strategic management.
      </>
    },
    {
      src: Services4, header: "Privileged Access Managed Services (PAM)", text: <>
        <span className="highlightServices5">Expert talent for specialized identity challenges.</span>
        <br /><br />Protect critical systems with controlled, monitored, and audited privileged access.
      </>
    },
  ];

  useEffect(() => {
    const numberOfPhases = phases.length;
    const phaseThreshold = 100 / numberOfPhases;

    phases.forEach((_, index) => {
      if (scrollPercentage >= index * phaseThreshold && scrollPercentage < (index + 1) * phaseThreshold) {
        setActive(index);
      }
    });
  }, [scrollPercentage, phases]);

  return (
    <div className='ServicesComponent5Container'>
      <div className='ServicesComponent5Text'>
        <h1>Staffing Services</h1>
      </div>
      <div className="services5ContentWrapper">
        <div className="services5ImageWrapper">
          <img
            src={phases[active].src}
            alt={phases[active].header}
            className="services5Image"
          />
          <h2 className="services5Header">{phases[active].header}</h2>
        </div>
        <div className="services5TextWrapper">
          <p>{phases[active].text}</p>
        </div>
      </div>
    </div>
  )
}

export default ServicesComponent5;