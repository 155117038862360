// PrivilegedTaskAutomationContent.js

import React from 'react';
import { FaTasks, FaCogs, FaRobot } from 'react-icons/fa';

const PrivilegedTaskAutomationContent = () => (
  <>
    <h3 className="modal-main-title">Privileged Task Automation</h3>
    <p>
      Privileged Task Automation is an essential component of Privileged Access
      Management (PAM). It involves automating tasks that require privileged
      access, reducing the need for manual intervention and minimizing the risk
      of human error. By automating repetitive or sensitive tasks, organizations
      can enhance efficiency, improve security, and ensure compliance with
      policies and regulations.
    </p>

    <h4 className="modal-subheading">
      <FaTasks size={30} style={{ marginRight: '10px' }} />
      Benefits of Privileged Task Automation
    </h4>
    <p>
      Implementing Privileged Task Automation helps organizations enhance
      operational efficiency, reduce security risks, and maintain compliance
      with data protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Increased Efficiency:</strong> Automate repetitive tasks to
        reduce the workload of IT administrators, allowing them to focus on more
        strategic activities.
      </li>
      <li className="modal-list-item">
        <strong>Reduced Human Error:</strong> Minimize the risk of errors that
        can occur during manual execution of privileged tasks, improving overall
        security.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that all automated tasks are
        executed in accordance with organizational policies and regulatory
        requirements, maintaining transparency and accountability.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCogs size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Privileged Task Automation
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers Privileged Access Management
      (PAM) solutions that include Privileged Task Automation. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Automated Workflows:</strong> Create automated workflows for
        common privileged tasks, such as system configuration changes or access
        provisioning, ensuring consistency and reducing the risk of errors.
      </li>
      <li className="modal-list-item">
        <strong>Scripted Automation:</strong> Use pre-approved scripts to
        automate complex tasks that require privileged access, reducing the need
        for manual intervention and improving security.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Reporting:</strong> Maintain detailed records of all
        automated tasks to ensure compliance with regulatory requirements and
        provide insights for improving automation processes.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaRobot size={30} style={{ marginRight: '10px' }} />
      Use Case: Automating Privileged Tasks for a Financial Institution
    </h4>
    <p>
      <strong>Challenge:</strong> A financial institution needed to automate
      privileged tasks to improve efficiency and reduce the risk of human error
      when managing sensitive financial systems.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Privileged Task
      Automation solution that included automated workflows and scripted
      automation for common tasks, ensuring consistency and compliance with
      regulatory requirements.
    </p>
    <p>
      <strong>Result:</strong> The financial institution improved operational
      efficiency, reduced the risk of human error, and ensured compliance with
      data protection regulations, resulting in enhanced security and
      streamlined operations.
    </p>
  </>
);

export default PrivilegedTaskAutomationContent;
