import React, { useState } from 'react';
import './ScrollArrow.css';

const ScrollArrow = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current section index

  const handleScroll = () => {
    // Get all scroll sections
    const sections = Array.from(document.querySelectorAll('.scroll-section'));

    // Ensure there's a next section to scroll to
    if (currentIndex + 1 < sections.length) {
      const nextSection = sections[currentIndex + 1];
      nextSection.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Update the current index
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="scroll-arrow-container" onClick={handleScroll}>
      <div className="scroll-arrow">
        <div className="scroller"></div>
      </div>
    </div>
  );
};

export default ScrollArrow;
