// AdaptiveAuthContent.js

import React from 'react';
import { FaLock, FaMobileAlt, FaSyncAlt } from 'react-icons/fa';

const AdaptiveAuthContent = () => (
  <>
    <h3 className="modal-main-title">Adaptive Authentication (CIAM Context)</h3>
    <p>
      Adaptive Authentication is a dynamic and context-aware approach to
      securing user access in Customer Identity and Access Management (CIAM). It
      analyzes various risk signals, such as user behavior, location, and
      device, to determine the appropriate level of authentication required.
      This method ensures that users have a frictionless experience while
      maintaining strong security for sensitive resources.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Benefits of Adaptive Authentication
    </h4>
    <p>
      Implementing Adaptive Authentication helps organizations enhance security,
      reduce friction for legitimate users, and ensure compliance with data
      protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved Security:</strong> Analyze risk signals in real-time to
        apply additional authentication measures when suspicious behavior is
        detected, minimizing the risk of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced User Experience:</strong> Provide a seamless experience
        for users with low-risk access attempts by reducing unnecessary
        authentication steps.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that authentication policies comply
        with data protection regulations such as GDPR and CCPA, protecting
        sensitive user data and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Adaptive Authentication
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      Adaptive Authentication for both B2B and B2B2C use cases. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Risk-Based Analysis:</strong> Utilize real-time risk analysis to
        assess the context of each access attempt and dynamically adjust
        authentication requirements based on the risk level.
      </li>
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Integrate MFA as an
        additional layer of security when high-risk behavior is detected,
        ensuring that sensitive resources are protected.
      </li>
      <li className="modal-list-item">
        <strong>Behavioral Biometrics:</strong> Implement behavioral biometrics
        to analyze user behavior, such as typing patterns or navigation habits,
        adding an extra layer of security without impacting the user experience.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaSyncAlt size={30} style={{ marginRight: '10px' }} />
      Use Case: Implementing Adaptive Authentication for an Online Retail
      Platform
    </h4>
    <p>
      <strong>Challenge:</strong> An online retail platform needed to secure
      customer accounts while maintaining a seamless shopping experience,
      especially during peak sales periods.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an Adaptive
      Authentication solution that used risk-based analysis to assess each login
      attempt. Low-risk users were granted access without additional
      verification, while high-risk attempts prompted for MFA.
    </p>
    <p>
      <strong>Result:</strong> The retail platform achieved improved security,
      reduced fraud incidents, and provided a frictionless experience for
      legitimate users, resulting in increased customer satisfaction and trust.
    </p>
  </>
);

export default AdaptiveAuthContent;
