// UserRegistrationContent.js

import React from 'react';
import { FaUserPlus, FaMobileAlt, FaLock } from 'react-icons/fa';

const UserRegistrationContent = () => (
  <>
    <h3 className="modal-main-title">
      User Registration and Self-Service Portals (B2C and B2B CIAM)
    </h3>
    <p>
      User Registration and Self-Service Portals are essential components of
      Customer Identity and Access Management (CIAM) for B2C and B2B use cases.
      They allow customers and business partners to easily register, manage
      their own profiles, and access services, creating a seamless user
      experience while maintaining robust security and compliance.
    </p>

    <h4 className="modal-subheading">
      <FaUserPlus size={30} style={{ marginRight: '10px' }} />
      Benefits of User Registration and Self-Service Portals
    </h4>
    <p>
      Implementing self-service capabilities for user registration helps
      organizations improve customer experience, reduce operational costs, and
      ensure compliance with data protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced User Experience:</strong> Allowing users to register
        and manage their own accounts provides a smooth, user-friendly
        experience that meets modern customer expectations.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Self-service portals reduce the
        burden on customer support by allowing users to reset passwords, update
        profile information, and manage consent on their own.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that user data is collected and
        managed in compliance with regulations like GDPR and CCPA, including
        providing users with control over their data and consent preferences.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports User Registration and Self-Service
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive CIAM solutions
      for user registration and self-service, helping organizations meet their
      B2C and B2B requirements. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Customizable Registration Forms:</strong> Design and implement
        customizable registration forms that capture the necessary user
        information while providing a seamless onboarding experience.
      </li>
      <li className="modal-list-item">
        <strong>Self-Service Account Management:</strong> Enable users to update
        their profiles, manage their credentials, and adjust consent preferences
        through a secure, easy-to-use self-service portal.
      </li>
      <li className="modal-list-item">
        <strong>Secure Data Handling:</strong> Ensure that user data is
        encrypted and managed in compliance with regulations such as GDPR and
        CCPA, providing transparency and control to users.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Use Case: Streamlining Customer Registration for an E-Commerce Platform
    </h4>
    <p>
      <strong>Challenge:</strong> An e-commerce company needed to streamline the
      registration process for new customers while ensuring compliance with GDPR
      and providing a secure, user-friendly experience.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a CIAM solution
      that included customizable registration forms, a self-service portal for
      account management, and secure data handling practices to ensure
      compliance with data protection regulations.
    </p>
    <p>
      <strong>Result:</strong> The e-commerce platform improved its customer
      onboarding process, increased user satisfaction, and ensured compliance
      with GDPR, leading to enhanced trust and a better overall user experience.
    </p>
  </>
);

export default UserRegistrationContent;
