import React, { useEffect } from 'react';
import './servicesComponentTitle.css'; 

const ServicesComponentTitle = ({ onAnimationEnd }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onAnimationEnd();
        }, 2000); // Match the animation duration

        return () => clearTimeout(timer);
    }, [onAnimationEnd]);

    return (
        <div className="servicesComponentTitle">
            <p><span className="highlightServices">RAAH</span> Services</p>
        </div>
    );
};

export default ServicesComponentTitle;
