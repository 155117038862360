// SupplierVendorIdentityManagementContent.js

import React from 'react';
import { FaIndustry, FaHandshake, FaUserShield } from 'react-icons/fa';

const SupplierVendorContent = () => (
  <>
    <h3 className="modal-main-title">
      Supplier and Vendor Identity Management (B2B and B2B2C CIAM)
    </h3>
    <p>
      Supplier and Vendor Identity Management is a critical component of
      Customer Identity and Access Management (CIAM) for B2B and B2B2C use
      cases. It enables organizations to manage the identities and access of
      suppliers, vendors, and third-party partners, ensuring secure
      collaboration and compliance with data protection regulations.
    </p>

    <h4 className="modal-subheading">
      <FaIndustry size={30} style={{ marginRight: '10px' }} />
      Benefits of Supplier and Vendor Identity Management
    </h4>
    <p>
      Implementing Supplier and Vendor Identity Management helps organizations
      enhance security, streamline onboarding, and maintain compliance with data
      protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Ensure that suppliers and vendors
        have only the access they need, reducing the risk of unauthorized
        actions and potential breaches.
      </li>
      <li className="modal-list-item">
        <strong>Streamlined Onboarding:</strong> Simplify the onboarding and
        offboarding of suppliers and vendors by managing their identities
        centrally and applying consistent access policies.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that all supplier and vendor
        activities comply with data protection regulations such as GDPR and
        CCPA, minimizing legal risks and ensuring transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaHandshake size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Supplier and Vendor Identity Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      comprehensive Supplier and Vendor Identity Management for both B2B and
      B2B2C use cases. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Centralized Identity Management:</strong> Manage supplier and
        vendor identities centrally to ensure consistent access control and
        streamline administrative processes.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Apply RBAC to define
        and manage access levels for suppliers and vendors, ensuring that they
        only have access to relevant resources.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Monitoring:</strong> Track and monitor all supplier
        and vendor activities to ensure compliance with data protection
        regulations and maintain accountability.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Use Case: Managing Supplier Access for a B2B Manufacturing Network
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B manufacturing network needed to manage
      the identities and access of multiple suppliers while ensuring compliance
      with data protection regulations and maintaining security.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Supplier and
      Vendor Identity Management solution that included centralized identity
      management, role-based access control, and audit logging to ensure secure
      collaboration and compliance.
    </p>
    <p>
      <strong>Result:</strong> The manufacturing network improved security,
      streamlined supplier onboarding and offboarding, and ensured compliance
      with GDPR and other data protection regulations, resulting in enhanced
      trust and stronger supplier relationships.
    </p>
  </>
);

export default SupplierVendorContent;
