import React from 'react';
import {
  FaUserShield,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const SaviyntContent = () => (
  <>
    <h3 className="modal-main-title">
      Saviynt: Enabling Intelligent Identity Governance and Administration
    </h3>
    <p>
      Saviynt is a leading provider of Identity Governance and Administration
      (IGA) solutions, delivering comprehensive capabilities to manage user
      identities, ensure compliance, and enforce security across enterprise
      environments. At <strong>RAAH Technologies</strong>, we leverage our deep
      expertise with Saviynt to help organizations achieve their identity and
      compliance goals effectively.
    </p>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Key Capabilities of Saviynt
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Automate the creation,
        modification, and removal of user identities, ensuring users have
        appropriate access throughout their lifecycle.
      </li>
      <li className="modal-list-item">
        <strong>Access Request and Certification:</strong> Empower users to
        request access to resources and streamline certification campaigns to
        meet compliance requirements.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Control and monitor
        privileged access to critical systems, reducing the risk of insider
        threats and misuse.
      </li>
      <li className="modal-list-item">
        <strong>Risk-Based Access Controls:</strong> Define and enforce access
        policies based on risk levels, ensuring critical data and systems are
        adequately protected.
      </li>
      <li className="modal-list-item">
        <strong>Compliance and Audit Reporting:</strong> Generate detailed audit
        reports to demonstrate compliance with regulatory standards such as
        GDPR, SOX, and HIPAA.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Automating Compliance in a Regulated Industry
    </h4>
    <p>
      <strong>Challenge:</strong> A large healthcare provider needed to
      streamline identity management processes while ensuring compliance with
      HIPAA and other regulatory standards.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Saviynt's IGA
      platform to automate user provisioning, access certification, and
      privileged access management, ensuring compliance and reducing manual
      efforts.
    </p>
    <p>
      <strong>Result:</strong> The provider saw a <strong>60% reduction</strong>{' '}
      in the time spent on manual compliance-related tasks and significantly
      improved audit readiness.
    </p>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we combine our expertise in
      identity and governance with Saviynt's robust capabilities to deliver
      tailored solutions for organizations across industries. Our services
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Consulting on identity governance strategies using Saviynt's platform.
      </li>
      <li className="modal-list-item">
        Implementation and configuration of Saviynt to automate identity and
        compliance processes.
      </li>
      <li className="modal-list-item">
        Integration of Saviynt with other enterprise applications to create a
        cohesive security framework.
      </li>
      <li className="modal-list-item">
        Ongoing support and optimization to adapt to evolving compliance needs
        and security threats.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://saviynt.com/glossary-listing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saviynt Glossary
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://forums.saviynt.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saviynt Community
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://docs.saviyntcloud.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saviynt Documentation
        </a>
      </li>
    </ul>
  </>
);

export default SaviyntContent;
