import "./BlogComponentNew.css";
import React, { useState, useEffect } from "react";
import Tech from './icons/Dos&Donts.png';
import Tech1 from './icons/Guide.png';
import Tech2 from './icons/Hiring.png';
import Tech3 from './icons/Ransomware.png';
import Tech4 from './icons/SAML.png';

function BlogComponentNew({ scrollPercentage }) {
    const [active, setActive] = useState(0); // Active image index
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Check if mobile

    const images = [
        { src: Tech, header: "A Beginner's Guide to Building an Identity Access Management Program", text: "Efficient workforce access, managed securely. Complete identity management solution." },
        { src: Tech1, header: "5 Keys to Hiring the Right IAM Expert for Your Organization", text: "Building a strong IAM program requires a thoughtful hiring strategy. Hire a hungry learner." },
        { src: Tech2, header: "Do’s and Don’t’s for B2B IAM Pros in Building Partner IAM Solutions", text: "Building strong partner IAM is crucial for successful business operations." },
        { src: Tech3, header: "Ransomware and Cyber-Terrorism: How Countries, Cities, and Companies Fall", text: "Human error empowers hackers, leading to significant security vulnerabilities everywhere." },
        { src: Tech4, header: "Can You Use SAML and OIDC Together?", text: "Single Sign-On integrates SAML and OIDC for seamless user experiences." },
    ];

    // Set active image based on scroll percentage
    useEffect(() => {
        const imageCount = images.length;
        const percentagePerImage = 100 / imageCount;
        const newIndex = Math.floor(scrollPercentage / percentagePerImage);
        setActive(newIndex);
    }, [scrollPercentage, images.length]);

    // Check if the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Style logic for positioning and opacity
    const getImageStyle = (index) => {
        let translateValue, zIndex, opacity;

        const offsetFromActive = index - active;

        if (isMobile) {
            if (index === active) {
                translateValue = 0; // Active item centered
                zIndex = 5;
                opacity = 1;
            } else if (Math.abs(offsetFromActive) === 1) {
                translateValue = offsetFromActive * 100; // Faded item
                zIndex = images.length - Math.abs(offsetFromActive);
                opacity = 0.5; // Faded out
            } else {
                translateValue = offsetFromActive * 100;
                zIndex = images.length - Math.abs(offsetFromActive);
                opacity = 0; // Hide distant images
            }
        } else {
            // Desktop view logic
            if (index === active) {
                translateValue = 0; // Keep the active image centered
                zIndex = 5;
                opacity = 1;
            } else if (Math.abs(offsetFromActive) <= 2) {
                translateValue = offsetFromActive * 100; // Adjusted distance to keep it closer to center
                zIndex = images.length - Math.abs(offsetFromActive);
                opacity = 0.5 / (Math.abs(offsetFromActive) + 1); // Slight adjustment to avoid zero opacity
            } else {
                translateValue = offsetFromActive * 100;
                zIndex = images.length - Math.abs(offsetFromActive);
                opacity = 0; // Hide distant images
            }
        }

        return { translateValue, zIndex, opacity };
    };

    return (
        <div id="BlogComponentDiv" className="blogComponentContainer">
            {images.map((image, index) => {
                const { translateValue, zIndex, opacity } = getImageStyle(index);
                const isActive = index === active; // Check if the image is the active one
                return (
                    <div
                        className={`blogItem ${isActive ? 'activeCard' : ''}`}
                        key={index}
                        style={{
                            transform: `translateX(${translateValue}px)`,
                            zIndex: zIndex,
                            opacity: opacity,
                            transition: "transform 0.8s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.8s ease-in-out, z-index 0.8s ease-in-out"
                        }}
                    >
                        <div className="blogCard">
                            <div className="blogCardFace blogCardFront">
                                <img
                                    src={image.src}
                                    alt={`tech-image-${index}`}
                                    className="sliderImage" // Keep the class name
                                />
                            </div>
                            <div className="blogCardFace blogCardBack">
                                <h1>{image.header}</h1>
                                <br />
                                <p>{image.text}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default BlogComponentNew;
