import React from 'react';
import './ScrollingTitle.css';
import InfiniteScrollDiv from './InfiniteScrollDiv';

const ScrollingTitle = ({scrollPercentage}) => {
  const getTitleContent = () => {
    if (scrollPercentage >= 0 && scrollPercentage < (50)) {
      return (
      <div id='before-title' className='title-container container'>
          <InfiniteScrollDiv />
          <span className='on-your-mind'>on your mind?</span>
      </div>
      );
    } else if (scrollPercentage >= (50) && scrollPercentage <= (100)) {
      return (
        <span id='after-title' className='title-container'>Therefore, <span className="highlightThinking">RAAH</span></span>
      );
    }
  }

  return (
    <div>
      {getTitleContent()}
    </div>
  );
};

export default ScrollingTitle;
