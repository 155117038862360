import './ServicesComponent.css';
import { ReactComponent as BriefCase } from './icons/case.svg';
import { ReactComponent as Bulb } from './icons/bulb.svg';
import { ReactComponent as People } from './icons/people.svg';
import { ReactComponent as Gear } from './icons/gear.svg';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ServicesComponent({ scrollPercentage }) {
    const [servicesTitle, setServicesTitle] = useState();
    const [servicesContent, setServicesContent] = useState();
    const [activeService, setActiveService] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        updateContentBasedOnScroll(scrollPercentage);
    }, [scrollPercentage]);

    const updateContentBasedOnScroll = (scroll) => {
        if (scroll <= 20) {
            setActiveService(0);
            setServicesTitle('Advisory Service');
            setServicesContent(
                <>
                    We <span className='highlightServices'>guide leadership</span> and technical teams in developing strategic identity and security roadmaps, ensuring alignment with business goals and regulatory compliance.
                </>
            );
        } else if (scroll <= 45) {
            setActiveService(1);
            setServicesTitle('Professional Service');
            setServicesContent(
                <>
                    Our experts <span className='highlightServices'>implement tailored identity solutions</span>, from design to deployment, ensuring seamless integration with your existing infrastructure.
                </>
            );
        } else if (scroll <= 65) {
            setActiveService(2);
            setServicesTitle('Managed Service');
            setServicesContent(
                <>
                    We offer <span className='highlightServices'>comprehensive support</span> across all aspects of Identity, from designing and implementing solutions to running your identity operations as an extension of your team.
                </>
            );
        } else {
            setActiveService(3);
            setServicesTitle('Staffing Service');
            setServicesContent(
                <>
                    We offer <span className='highlightServices'>specialized talent</span> to support your identity and security initiatives, providing the right expertise exactly when you need it.
                </>
            );
        }
    };

    const handleIconClick = (index, scrollValue) => {
        setActiveService(index);
        updateContentBasedOnScroll(scrollValue);
    };

    return (
        <div id="ServicesComponentDiv" className="services-content">
            <div className='services-description'>
                <div className='services-description-title'>
                    <h1>{servicesTitle}</h1>
                </div>
                <div className='services-description-content'>
                    <h2>{servicesContent}</h2>
                </div>
                {/* Add Learn More button for the 4th component */}
                {activeService === 3 && (
                    <button className="learnButton" onClick={() => navigate('/services')}>
                        Learn More
                    </button>
                )}
            </div>
            <div className='servicesTextIcons'>
                {[{ Component: Bulb, alt: "bulb", index: 0, scrollValue: 20 },
                { Component: BriefCase, alt: "briefCase", index: 1, scrollValue: 45 },
                { Component: Gear, alt: "gear", index: 2, scrollValue: 60 },
                { Component: People, alt: "people", index: 3, scrollValue: 100 }
                ].map(({ Component, alt, index, scrollValue }) => (
                    <div key={index} className={`imageServiceBorder ${activeService === index ? 'active' : ''}`} onClick={() => handleIconClick(index, scrollValue)}>
                        <div className={`${activeService === index ? 'active-img' : ''}`}>
                            <Component className={`${activeService === index ? 'active-svg' : ''}`} alt={alt} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServicesComponent;
