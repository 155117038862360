// IdentityLifecycleContent.js

import React from 'react';
import { FaSync, FaUserPlus, FaUserTimes } from 'react-icons/fa';

const IdentityLifecycleContent = () => (
  <>
    <h3 className="modal-main-title">Identity Lifecycle Management</h3>
    <p>
      Identity Lifecycle Management (ILM) involves managing the entire lifecycle
      of user identities, from creation and modification to deactivation and
      deletion. ILM ensures that users have the correct access at every stage of
      their journey within an organization.
    </p>

    <h4 className="modal-subheading">
      <FaSync size={30} style={{ marginRight: '10px' }} />
      Benefits of Identity Lifecycle Management
    </h4>
    <p>
      Implementing Identity Lifecycle Management helps organizations streamline
      user access, reduce security risks, and maintain compliance throughout the
      user identity lifecycle.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Ensures that users have appropriate
        access at every stage, reducing the risk of excessive permissions or
        unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Automates user provisioning and
        deprovisioning, reducing the workload on IT teams and minimizing manual
        errors.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Provides a complete audit trail of identity
        changes, helping organizations meet regulatory requirements.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserPlus size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Identity Lifecycle Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers comprehensive ILM solutions to
      manage user identities efficiently throughout their lifecycle. Our
      solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Automated Provisioning and Deprovisioning:</strong> Automate
        user onboarding and offboarding to ensure that access is granted and
        revoked promptly based on user status.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access:</strong> Assign roles to users automatically
        based on their job functions, ensuring appropriate access from day one.
      </li>
      <li className="modal-list-item">
        <strong>Lifecycle Event Management:</strong> Manage changes to user
        identities during promotions, transfers, or other lifecycle events to
        ensure that access remains up-to-date.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserTimes size={30} style={{ marginRight: '10px' }} />
      Use Case: Managing Identity Lifecycle in Healthcare
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare provider needed to manage user
      identities efficiently to ensure that employees had appropriate access
      while maintaining compliance with healthcare regulations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an automated
      Identity Lifecycle Management solution that streamlined user provisioning,
      ensured timely deprovisioning upon employee departure, and provided a
      complete audit trail.
    </p>
    <p>
      <strong>Result:</strong> The healthcare provider improved operational
      efficiency, reduced the risk of unauthorized access, and ensured
      compliance with industry regulations.
    </p>
  </>
);

export default IdentityLifecycleContent;
