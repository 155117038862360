// ProgressiveProfilingContent.js

import React from 'react';
import { FaUserEdit, FaChartLine, FaClipboardList } from 'react-icons/fa';

const ProgressiveProfilingContent = () => (
  <>
    <h3 className="modal-main-title">Progressive Profiling (CIAM)</h3>
    <p>
      Progressive Profiling is a strategy used in Customer Identity and Access
      Management (CIAM) to gradually collect user information over time, rather
      than requiring users to provide all details during initial registration.
      This approach helps improve the user experience by reducing friction and
      allows for more personalized interactions as users engage more with the
      organization.
    </p>

    <h4 className="modal-subheading">
      <FaUserEdit size={30} style={{ marginRight: '10px' }} />
      Benefits of Progressive Profiling
    </h4>
    <p>
      Implementing Progressive Profiling helps organizations enhance user
      experience, improve data accuracy, and build richer customer profiles over
      time.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Reduced Friction:</strong> Minimize the barriers to entry by
        only asking for essential information during initial registration, with
        further details collected gradually as users interact more.
      </li>
      <li className="modal-list-item">
        <strong>Improved Data Accuracy:</strong> Collecting information over
        time ensures that data is up-to-date and relevant, as users are more
        likely to provide accurate details when engaged.
      </li>
      <li className="modal-list-item">
        <strong>Personalized Experience:</strong> Build a richer customer
        profile to deliver personalized experiences based on the information
        gathered progressively.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaChartLine size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Progressive Profiling
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      Progressive Profiling for both B2C and B2B use cases. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Customizable Data Collection:</strong> Implement customizable
        forms that adapt to user behavior, ensuring that relevant information is
        collected at the right time.
      </li>
      <li className="modal-list-item">
        <strong>Integration with Customer Journeys:</strong> Seamlessly
        integrate Progressive Profiling into customer journeys to gather
        information without disrupting the user experience.
      </li>
      <li className="modal-list-item">
        <strong>Data Security and Compliance:</strong> Ensure that user data
        collected through Progressive Profiling is managed securely and in
        compliance with regulations like GDPR and CCPA.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Enhancing Customer Engagement for an E-Learning Platform
    </h4>
    <p>
      <strong>Challenge:</strong> An e-learning platform wanted to gather more
      information about its users to provide personalized course
      recommendations, but didn't want to overwhelm users during registration.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Progressive
      Profiling solution that collected essential information during initial
      signup and gradually gathered additional details as users interacted with
      the platform.
    </p>
    <p>
      <strong>Result:</strong> The platform was able to provide personalized
      course recommendations, improved user engagement, and collected accurate
      data over time without creating a negative user experience.
    </p>
  </>
);

export default ProgressiveProfilingContent;
