import React, { useState, useEffect } from "react";
import "./wayComponent.css";
import Way1 from "../icons/Discovery.gif";
import Way2 from "../icons/Selection.gif";
import Way3 from "../icons/Implementation.gif";
import Way4 from "../icons/Success.gif";

function WayComponent({ scrollPercentage }) {
    const [active, setActive] = useState(0);

    const phases = [
        {
            src: Way1, header: "Discovery & Assessment", text: <>
                In the Discovery & Assessment phase, we thoroughly explore your organization’s identity and access management needs. We analyze your current systems, processes, and challenges, collaborating with your team to identify gaps and opportunities for improvement.
                <br /><br />This phase lays the foundation for the entire engagement, ensuring we understand your landscape and goals, allowing us to design solutions that fit your exact needs.
            </>
        },
        {
            src: Way2, header: "Strategic Planning & Selection", text: <>
                In the Strategic Planning & Selection phase, we develop a clear roadmap to achieve your identity and access management goals. Whether selecting new solutions or optimizing existing ones, we ensure they integrate smoothly into your environment. With our extensive experience and industry insights, we guide you in making informed decisions about tools and platforms.
                <br /><br />This blueprint sets the stage for a seamless implementation, minimizing risks and ensuring a successful outcome.
            </>
        },
        {
            src: Way3, header: "Implementation & Optimization", text: <>
                In the Implementation & Optimization phase, we put the plan into action. Our team builds, configures, and deploys solutions, ensuring seamless integration with your systems. We focus on minimizing disruption while optimizing performance, working closely with you to meet your unique needs and make adjustments as needed.
                <br /><br />From deployment to ongoing support, we’re with you every step of the way to ensure the solution delivers its full potential.
            </>
        },
        {
            src: Way4, header: "Success & Beyond", text: <>
                The final phase, Success & Beyond, focuses on delivering real, measurable results. We don’t stop at implementation—we stay involved, tracking performance and making adjustments to ensure ongoing value. Together, we measure success against key metrics and optimize the system for long-term impact.
                <br /><br />Our goal is to ensure your organization’s continued success, providing support and monitoring so the solution adapts as your business evolves.
            </>
        },
    ];

    useEffect(() => {
        const numberOfPhases = phases.length;
        const phaseThreshold = 100 / numberOfPhases;

        phases.forEach((_, index) => {
            if (scrollPercentage >= index * phaseThreshold && scrollPercentage < (index + 1) * phaseThreshold) {
                setActive(index);
            }
        });
    }, [scrollPercentage, phases]);

    return (
        <div id="WayComponentDiv" className="wayComponentScrollArea">
            <div className="wayComponentContainer">
                <div className="wayComponentTitle">
                    <h1>The <span className="highlightWay">RAAH</span> Process</h1>
                </div>
                <div className="wayContentWrapper">
                    <div className="wayImageWrapper">
                        <img
                            src={phases[active].src}
                            alt={phases[active].header}
                            className="wayImage"
                        />
                        <h2 className="wayHeader">{phases[active].header}</h2>
                    </div>
                    <div className="wayTextWrapper">
                        <p>{phases[active].text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WayComponent;
