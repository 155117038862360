// import React, { useEffect, useState } from 'react';
// import './IntroScreenComponent.css';
// import { useNavigate } from 'react-router-dom';
// import DonIntro from '../icons/DonIntro.png';
// import TarunIntro from '../icons/TarunIntro.png';
// import OviIntro from '../icons/OviIntro.png';
// import NarenIntro from '../icons/NarenIntro.png';
// import TalkFooter from '../icons/TalkFooter.png';
// import IAMFooter from '../icons/IAMFooter.png';
// import DeveloperFooter from '../icons/DeveloperFooter.png';

// const IntroScreenComponent = () => {
//     const [currentState, setCurrentState] = useState(0);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             if (currentState === textSlide.length - 1) {
//                 navigate("/intro");
//             } else {
//                 setCurrentState((prevState) => prevState + 1);
//             }
//         }, 30000);
//         return () => clearTimeout(timer);
//     }, [currentState, navigate]);

//     const goToNext = (index) => {
//         setCurrentState(index);
//     };

//     const textSlide = [
//         {
//             src: [TarunIntro, DonIntro],
//             header: <>OKTA CIC <span className='smallTextIntro'>(Formerly Auth0)</span><br /> VIRTUAL DEV LAB
//             </>,
//             body: <><span className='smallTextIntro'>December 11, 2024 <br />3:00PM to 5:00PM EDIT <br /></span>
//                 Learn to secure your website, custom apps, and API services. <br />
//                 Join our free virtual lab and walk away with a strong understanding of OKTA CIC. <br />
//                 Exciting prizes for participants up for grab.</>,
//             footer: DeveloperFooter,
//         },
//         {
//             src: NarenIntro,
//             header: <>MEET OUR <br /> EXPERT SPEAKER
//             </>,
//             body: <>NAREN JANGID <br /> <span className='smallTextIntro'>Founder and CEO, <br />RAAH Technologies INC.</span> <br /><br />
//                 GARTNER® <br /> Biometric Privacy: Balancing security and <br /> civil liberties with a sense of humor.
//             </>,
//             footer: TalkFooter,
//         },
//         {
//             src: OviIntro,
//             header: <>HERE WE <br />GO AGAIN
//             </>,
//             body: <>GARTNER® <br /> Join us at the premiere IAM conference to <br /> secure human and machine identities globally.
//             </>,
//             footer: IAMFooter,
//         },
//     ];

//     return (
//         <div className="container-style">
//             <h1 className="header">{textSlide[currentState].header}</h1>

//             {/* Slide Image */}
//             <div className="slide-images">
//                 {Array.isArray(textSlide[currentState].src) ? (
//                     textSlide[currentState].src.map((image, index) => (
//                         <img
//                             key={index}
//                             src={image}
//                             alt={`Slide ${index}`}
//                             className="slide-image slide-image1"
//                             width="200px"
//                         />
//                     ))
//                 ) : (
//                     <img
//                         src={textSlide[currentState].src}
//                         alt="Slide"
//                         className="slide-image"
//                     />
//                 )}
//             </div>
//             <div className="body-text">
//                 <p>{textSlide[currentState].body}</p>
//             </div>

//             {/* Register Now Button - Only for the first slide */}
//             {currentState === 0 && (
//                 <div className="register-button">
//                     <button className="registerButton">
//                         <a
//                             href="https://regionalevents.okta.com/okta-raah-devcamp"
//                         >
//                             Register Now
//                         </a>
//                     </button>
//                 </div>
//             )}

//             <div className="footer">
//                 {currentState === 0 ? 
//                 <img src={textSlide[currentState].footer} className="footer1" alt="Footer" />
//                 : <img src={textSlide[currentState].footer} className="footer2" alt="Footer" />
//                 }
                
//             </div>

//             {/* Carousel */}
//             <div className="carousel">
//                 {textSlide.map((slide, index) => (
//                     <button
//                         key={index}
//                         onClick={() => goToNext(index)}
//                         className={currentState === index ? 'active' : ''}
//                     ></button>
//                 ))}
//             </div>

//             {/* Intro Button */}
//             <div className="intro-button">
//                 <button
//                     className="introButton"
//                     onClick={() => navigate("/home")}
//                 >
//                     Skip Intro
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default IntroScreenComponent;

import React, { useEffect, useState } from 'react';
import './IntroScreenComponent.css';
import { useNavigate } from 'react-router-dom';
import Shieldbreaking from '../icons/shieldbreaking.png';
import Cash from '../icons/cash.png';
import Hacker from '../icons/Hacker.png';

const IntroScreenComponent = () => {
    const [currentState, setCurrentState] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentState === textSlide.length - 1) {
                navigate("/home");
            } else {
                setCurrentState(prevState => prevState + 1);
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, [currentState, navigate]);

    const goToNext = (index) => {
        setCurrentState(index);
    };

    const textSlide = [
        {
            src: Shieldbreaking,
            body: <>
                <span className='highlightIntroText'>70%</span> of CISOs anticipate a major cyber attack in the next 12 months, but <span className='highlightIntroText'>43% admit</span> they're still unprepared to handle targeted threats, revealing a critical gap in defenses.<br /><span className='smallIntroText'>2024 Voice of the CISO Report</span>
            </>
        },
        {
            src: Cash,
            body: <>
                Breaches in healthcare cost the most, averaging <span className='highlightIntroText'>$10.93 million</span>, while the global average hit <span className='highlightIntroText'>$4.45 million</span>. The financial sector followed with <span className='highlightIntroText'>$5.9 million</span> per breach.<br /><span className='smallIntroText'>2024 IBM Cost of Data Breach Study</span>
            </>
        },
        {
            src: Hacker,
            body: <>
                Stolen credentials were responsible for <span className='highlightIntroText'>31%</span> of breaches and <span className='highlightIntroText'>77%</span> of web app breaches. Phishing contributed to <span className='highlightIntroText'>14%</span>, underscoring the <span className='highlightIntroText'>need</span> for <span className='highlightIntroText'>stronger identity management</span>.<br /><span className='smallIntroText'>2024 Verizon Data Breach Report</span>
            </>
        }
    ];

    return (
        <div
            className="container-style"
            style={{
                backgroundImage: `url(${textSlide[currentState].src})`
            }}
        >
            <div className='description'>
                <h1>{textSlide[currentState].body}</h1>
            </div>
            <div className='carousel'>
                {
                    textSlide.map((slide, index) => (
                        <button
                            key={index}
                            onClick={() => goToNext(index)}
                            className={currentState === index ? 'active' : ''} // Highlight the active button
                        >
                        </button>
                    ))
                }
            </div>

            <div className='intro-button'>
                <button className="introButton" onClick={() => navigate("/home")}>Skip Intro</button>
            </div>
        </div>
    );
}

export default IntroScreenComponent;
