import React, { useEffect } from 'react';
import './newsComponentTitle.css';

const NewsComponentTitle = ({ onAnimationEnd }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onAnimationEnd();
        }, 2000); // Match the animation duration

        return () => clearTimeout(timer);
    }, [onAnimationEnd]);

    return (
        <div className="newsComponentTitle">
            <h1><span className="highlightNews">RAAH</span> News & PR</h1>
        </div>
    );
};

export default NewsComponentTitle;
