// AccountRecoveryContent.js

import React from 'react';
import { FaLock, FaRedo, FaUserShield } from 'react-icons/fa';

const AccountRecoveryContent = () => (
  <>
    <h3 className="modal-main-title">
      Account Recovery and Identity Assurance (B2C, B2B, and B2B2C CIAM)
    </h3>
    <p>
      Account Recovery is a critical feature of Customer Identity and Access
      Management (CIAM) for B2C, B2B, and B2B2C use cases. It ensures that
      customers, partners, and end users can securely regain access to their
      accounts when credentials are lost or compromised. Proper account recovery
      processes help maintain user trust and ensure the security of sensitive
      information.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Benefits of Account Recovery and Identity Assurance
    </h4>
    <p>
      Implementing secure account recovery processes helps organizations
      maintain user trust, prevent unauthorized access, and comply with data
      protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved User Experience:</strong> Provide a straightforward and
        secure way for users to regain access to their accounts, minimizing
        frustration and reducing support calls.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Ensure that account recovery
        processes include multiple verification steps to prevent unauthorized
        access and maintain the integrity of user accounts.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that account recovery processes
        comply with regulations such as GDPR and CCPA, protecting user data and
        maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaRedo size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Account Recovery
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      secure and user-friendly account recovery for B2C, B2B, and B2B2C use
      cases. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Verification:</strong> Implement multi-factor
        verification during account recovery to ensure that only authorized
        users can regain access to their accounts.
      </li>
      <li className="modal-list-item">
        <strong>Self-Service Recovery Portals:</strong> Provide self-service
        recovery portals that allow users to reset passwords and regain access
        without needing to contact support.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Recovery Processes:</strong> Utilize adaptive
        authentication to adjust the recovery process based on the risk level,
        providing additional verification steps when necessary.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Use Case: Secure Account Recovery for a B2B2C Financial Services Platform
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B2C financial services platform needed a
      secure and user-friendly account recovery process to help customers and
      partners regain access to their accounts while preventing unauthorized
      access.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an account
      recovery solution that included multi-factor verification, self-service
      recovery options, and adaptive recovery processes to ensure security and a
      smooth user experience.
    </p>
    <p>
      <strong>Result:</strong> The financial services platform improved user
      satisfaction, reduced support calls related to account recovery, and
      ensured compliance with data protection regulations, resulting in enhanced
      user trust and security.
    </p>
  </>
);

export default AccountRecoveryContent;
