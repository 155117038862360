// SeparationOfDutiesContent.js

import React from 'react';
import { FaLock, FaShieldAlt, FaClipboardList } from 'react-icons/fa';

const SeparationOfDutiesContent = () => (
  <>
    <h3 className="modal-main-title">Separation of Duties (SoD) Management</h3>
    <p>
      Separation of Duties (SoD) is a key internal control that helps prevent
      fraud and errors by ensuring that no single individual has control over
      all aspects of any critical business process. SoD is crucial for
      maintaining checks and balances within an organization.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Benefits of Separation of Duties
    </h4>
    <p>
      Implementing Separation of Duties helps organizations mitigate risks,
      enhance internal controls, and maintain compliance with industry
      regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Risk Mitigation:</strong> Ensures that critical tasks are
        divided among multiple individuals, reducing the likelihood of fraud or
        unauthorized activities.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Prevents any single user from having
        excessive control over business processes, thereby reducing the risk of
        internal threats.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Helps organizations comply with regulatory
        standards that require effective internal controls and segregation of
        duties.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Separation of Duties
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive Separation of
      Duties solutions to help organizations manage and enforce SoD policies
      effectively. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Policy Definition:</strong> Assist in defining SoD policies that
        align with business requirements and regulatory standards.
      </li>
      <li className="modal-list-item">
        <strong>Automated SoD Checks:</strong> Implement automated checks to
        detect and prevent conflicts of interest in user roles and permissions.
      </li>
      <li className="modal-list-item">
        <strong>Real-Time Monitoring:</strong> Monitor user activities in
        real-time to ensure compliance with SoD policies and quickly address any
        violations.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Ensuring Compliance in Financial Operations
    </h4>
    <p>
      <strong>Challenge:</strong> A financial institution needed to ensure that
      critical financial processes were not controlled by a single individual to
      mitigate the risk of fraud and comply with regulatory requirements.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Separation of
      Duties solution that defined clear SoD policies, automated SoD checks, and
      provided real-time monitoring of user activities to enforce compliance.
    </p>
    <p>
      <strong>Result:</strong> The institution reduced the risk of fraud,
      ensured compliance with industry regulations, and maintained a secure
      environment for financial operations.
    </p>
  </>
);

export default SeparationOfDutiesContent;
