// EmployeeOnboardingContent.js

import React from 'react';
import { FaUserPlus, FaUserTimes, FaSync } from 'react-icons/fa';

const EmployeeOnboardingContent = () => (
  <>
    <h3 className="modal-main-title">Employee Onboarding and Offboarding</h3>
    <p>
      Employee Onboarding and Offboarding are critical processes for managing
      employee access to corporate systems. Effective onboarding ensures that
      new employees are quickly granted the appropriate access they need to be
      productive, while efficient offboarding revokes access promptly when
      employees leave, reducing security risks.
    </p>

    <h4 className="modal-subheading">
      <FaUserPlus size={30} style={{ marginRight: '10px' }} />
      Benefits of Automated Onboarding and Offboarding
    </h4>
    <p>
      Automating the onboarding and offboarding processes helps organizations
      streamline operations, reduce manual errors, and enhance security.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved Efficiency:</strong> Automating the onboarding process
        ensures that new hires receive timely access to the tools and systems
        they need, reducing delays and improving productivity.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Automated offboarding promptly
        revokes access to corporate resources, minimizing the risk of
        unauthorized access after an employee departs.
      </li>
      <li className="modal-list-item">
        <strong>Consistency:</strong> Automation ensures that access
        provisioning and deprovisioning are consistently applied according to
        company policies, reducing the risk of human error.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaSync size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Onboarding and Offboarding
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive solutions to
      streamline employee onboarding and offboarding processes. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Automated Workflows:</strong> We implement automated workflows
        to provision and deprovision access across multiple systems, reducing
        manual intervention.
      </li>
      <li className="modal-list-item">
        <strong>Integration with HR Systems:</strong> Our solutions integrate
        seamlessly with HR systems to trigger onboarding and offboarding events
        based on employee status, ensuring accurate and timely access
        management.
      </li>
      <li className="modal-list-item">
        <strong>Access Reviews:</strong> Conduct periodic access reviews to
        ensure that employees have the appropriate level of access based on
        their current roles.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserTimes size={30} style={{ marginRight: '10px' }} />
      Use Case: Secure Offboarding for Financial Services
    </h4>
    <p>
      <strong>Challenge:</strong> A financial services company needed to ensure
      that former employees no longer had access to sensitive systems and data
      immediately after leaving the organization.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed an automated
      offboarding solution that integrated with the company's HR system,
      ensuring that access was revoked as soon as an employee's departure was
      recorded.
    </p>
    <p>
      <strong>Result:</strong> The company significantly reduced the risk of
      unauthorized access, improved compliance with regulatory requirements, and
      ensured a secure offboarding process.
    </p>
  </>
);

export default EmployeeOnboardingContent;
