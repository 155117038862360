import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../icons/logo-white.png";
import './Icon.css';

function Icon() {
    const navigate = useNavigate();
    return (
        <div>
            <img src={logo} alt="logo" className='nav-logo' onClick={() => navigate("/home")}></img>
        </div>
    );
}

export default Icon;
