import React from "react";
import '../App.css';
import './RecognitionComponent.css';
import Okta from './icons/okta.svg';
import Forbes from './icons/forbes.svg';
import Glassdoor from './icons/glassdoor.svg';
import Techbridge from './icons/techbridge.svg';
import Arrow from './icons/arrow.svg';

const RecognitionComponent = ({ scrollPercentage }) => {
    const isMobile = window.innerWidth < 768; // Check if the device is mobile

    const getSelectedText = (textVal) => {
        let baseClass = "recognitionShape recognitionTransition";

        // Determine visibility based on scroll percentage and device type
        if (isMobile) {
            switch (textVal) {
                case "first":
                    return scrollPercentage >= 1 && scrollPercentage < (100 / 4)
                        ? `${baseClass} selectedRecognition`
                        : `${baseClass} recognitionHidden`;
                case "second":
                    return scrollPercentage >= (100 / 4) && scrollPercentage < (200 / 4)
                        ? `${baseClass} selectedRecognition`
                        : `${baseClass} recognitionHidden`;
                case "third":
                    return scrollPercentage >= (200 / 4) && scrollPercentage < (300 / 4)
                        ? `${baseClass} selectedRecognition`
                        : `${baseClass} recognitionHidden`;
                case "fourth":
                    return scrollPercentage >= (300 / 4) && scrollPercentage < 100
                        ? `${baseClass} selectedRecognition`
                        : `${baseClass} recognitionHidden`;
                default:
                    return baseClass;
            }
        } else {
            // Desktop logic, all images are shown
            switch (textVal) {
                case "first":
                    return scrollPercentage >= 1 && scrollPercentage < (100 / 4)
                        ? `${baseClass} selectedRecognition`
                        : baseClass;
                case "second":
                    return scrollPercentage >= (100 / 4) && scrollPercentage < (200 / 4)
                        ? `${baseClass} selectedRecognition`
                        : baseClass;
                case "third":
                    return scrollPercentage >= (200 / 4) && scrollPercentage < (300 / 4)
                        ? `${baseClass} selectedRecognition`
                        : baseClass;
                case "fourth":
                    return scrollPercentage >= (300 / 4) && scrollPercentage < 100
                        ? `${baseClass} selectedRecognition`
                        : baseClass;
                default:
                    return baseClass;
            }
        }
    };

    const getTitleStyle = (textVal) => {
        switch (textVal) {
            case "first":
                return scrollPercentage >= 1 && scrollPercentage < (100 / 4)
                    ? "selectedRecognition"
                    : "hiddenRecognitionTitle";
            case "second":
                return scrollPercentage >= (100 / 4) && scrollPercentage < (200 / 4)
                    ? "selectedRecognition"
                    : "hiddenRecognitionTitle";
            case "third":
                return scrollPercentage >= (200 / 4) && scrollPercentage < (300 / 4)
                    ? "selectedRecognition"
                    : "hiddenRecognitionTitle";
            case "fourth":
                return scrollPercentage >= (300 / 4) && scrollPercentage < 100
                    ? "selectedRecognition"
                    : "hiddenRecognitionTitle";
            default:
                return "hiddenRecognitionTitle";
        }
    };

    const getTextContent = () => {
        if (scrollPercentage >= 10 && scrollPercentage < (100 / 4)) {
            return (
                <>
                    <span className="recognitionHeader">Okta Values our Technical Skillset</span><br /><br />
                    2023 Okta AMER Technical Champion of the Year Award <br /><br />
                    2023 Okta Technical Champion<br /><br />
                    2024 Okta AMER SI Partner of the Year
                </>
            );
        } else if (scrollPercentage >= (100 / 4) && scrollPercentage < (200 / 4)) {
            return (
                <>
                    <span className="recognitionHeader">Forbes Leverages our History of Success</span><br /><br />
                    2023 Forbes Council Member
                </>
            );
        } else if (scrollPercentage >= (200 / 4) && scrollPercentage < (300 / 4)) {
            return (
                <>
                    <span className="recognitionHeader">It is our Privilege to work with TechBridge</span><br /><br />
                    2023 Techbridge CxO Panel Specialist on IAM <br /><br />
                    2023 & 2024 TechBridge Ambassadors
                </>
            );
        } else if (scrollPercentage >= (300 / 4) && scrollPercentage < 100) {
            return (
                <>
                    <span className="recognitionHeader">Our Employees are Busy but Buzzing</span><br /><br />
                    Rated 5 Star on Glassdoor
                </>
            );
        } else {
            return (
                <>
                    These accolades showcase RAAH Technologies' dedication to <span className="highlightRecognition">innovation</span> in Identity, <span className="highlightRecognition">leadership</span> and <span className="highlightRecognition">philanthropy</span> in the community, and our commitment to always putting <span className="highlightRecognition">people first</span>.
                </>
            );
        }
    };

    return (
        <div className="recognitionContainer" id="RecognitionComponentDiv">
            <div className="leftRecognitionContainer">
                <div className={getSelectedText("first")}>
                    <div className={`hiddenRecognitionTitle ${getTitleStyle("first")}`}>
                        <div className="recognitionImageWrapper">
                            <img src={Okta} alt="Okta" className="recognitionImage" width={400} height={200} />
                            <img src={Arrow} className={getTitleStyle("first") === "selectedRecognition" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
                <div className={getSelectedText("second")}>
                    <div className={`hiddenRecognitionTitle ${getTitleStyle("second")}`}>
                        <div className="recognitionImageWrapper">
                            <img src={Forbes} alt="Forbes" className="recognitionImage" width={400} height={200} />
                            <img src={Arrow} className={getTitleStyle("second") === "selectedRecognition" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
                <div className={getSelectedText("third")}>
                    <div className={`hiddenRecognitionTitle ${getTitleStyle("third")}`}>
                        <div className="recognitionImageWrapper">
                            <img src={Techbridge} alt="Techbridge" className="recognitionImage1" width={400} height={200} />
                            <img src={Arrow} className={getTitleStyle("third") === "selectedRecognition" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
                <div className={getSelectedText("fourth")}>
                    <div className={`hiddenRecognitionTitle ${getTitleStyle("fourth")}`}>
                        <div className="recognitionImageWrapper">
                            <img src={Glassdoor} alt="Glassdoor" className="recognitionImage1" width={400} height={200} />
                            <img src={Arrow} className={getTitleStyle("fourth") === "selectedRecognition" ? "showArrow arrowImage" : "hideArrow arrowImage"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rightRecognitionContainer">
                <h1 className="rightRecogntionText">
                    {getTextContent()}
                </h1>
            </div>
        </div>
    );
};

export default RecognitionComponent;
