import React from 'react';
import {
  FaGamepad,
  FaShieldAlt,
  FaPuzzlePiece,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const GamingContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing the Gaming Industry with Innovative Identity Solutions
    </h3>
    <p>
      The gaming industry has grown into a vast online ecosystem that demands
      secure and seamless access for players, developers, and administrators. At{' '}
      <strong>RAAH Technologies</strong>, we deliver tailored identity solutions
      that protect players, enhance game experiences, and support the gaming
      community's dynamic needs.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in the Gaming Industry
    </h4>
    <p>
      The gaming industry faces unique challenges including fraud prevention,
      account security, and providing a seamless user experience across devices.
      Our identity solutions tackle these challenges head-on:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Player IAM:</strong> Securing player accounts, preventing fraud,
        and ensuring safe access to gaming platforms.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities of developers, moderators, and
        community managers to ensure appropriate access control.
      </li>
      <li className="modal-list-item">
        <strong>CIAM (B2C):</strong> Providing players with seamless access to
        gaming platforms through Single Sign-On (SSO) and Multi-Factor
        Authentication (MFA) to enhance security and user experience.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for partners, content
        creators, and external collaborators, ensuring secure integration and
        collaboration.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that have access to
        sensitive game data, admin tools, and in-game assets.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaPuzzlePiece size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for the Gaming Industry
    </h4>
    <p>Our customized identity solutions for gaming include:</p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect player accounts from unauthorized access,
        enhancing trust in the platform.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling players to use a single
        set of credentials across multiple games and platforms, improving the
        gaming experience.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that
        developers, moderators, and administrators have appropriate access to
        game systems based on their roles.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing
        administrative tools and in-game assets by controlling access to
        privileged accounts.
      </li>
      <li className="modal-list-item">
        <strong>Federated Identity Management:</strong> Supporting collaboration
        with partners and content creators by managing their identities and
        access securely.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Global Gaming Network
    </h4>
    <p>
      <strong>Challenge:</strong> Global Gaming Network needed to secure player
      accounts, prevent fraud, and provide a seamless cross-platform gaming
      experience for players worldwide.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented CIAM solutions
      with Multi-Factor Authentication (MFA) to protect player accounts and
      Single Sign-On (SSO) to streamline access across multiple games.
      Role-Based Access Control (RBAC) was also implemented to manage developer
      and administrator access to critical game systems.
    </p>
    <p>
      <strong>Result:</strong> Global Gaming Network saw a{' '}
      <strong>50% reduction</strong> in account takeover incidents and a
      significant improvement in player satisfaction due to the seamless login
      experience.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Player Security:</strong> Protecting player accounts
        with MFA and CIAM, reducing the risk of fraud and unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Improved User Experience:</strong> Seamless access across
        multiple games and platforms with Single Sign-On (SSO), enhancing player
        satisfaction.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Streamlined access management
        for developers and administrators, reducing the burden on IT teams.
      </li>
      <li className="modal-list-item">
        <strong>Scalable Collaboration:</strong> Federated Identity Management
        enables secure partnerships with content creators and third-party
        developers.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      The future of gaming requires secure, scalable, and user-friendly identity
      solutions that provide both security and a seamless player experience. At{' '}
      <strong>RAAH Technologies</strong>, we are dedicated to delivering
      advanced IAM, CIAM, PAM, and IGA solutions to help gaming companies meet
      evolving challenges and deliver exceptional experiences for their players.
    </p>
  </>
);

export default GamingContent;
