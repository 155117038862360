import React from 'react';
import { FaShieldAlt, FaUserLock, FaMobileAlt } from 'react-icons/fa';

const MFAEnhancedSecurityContent = () => (
  <>
    <h3 className="modal-main-title">
      Multi-Factor Authentication (MFA) for Enhanced Security
    </h3>
    <p>
      Multi-Factor Authentication (MFA) is a security mechanism that requires
      users to provide two or more verification factors to gain access to an
      application, account, or other resource. By combining multiple
      authentication methods, MFA significantly improves security, making it
      much harder for attackers to gain unauthorized access.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Benefits of MFA
    </h4>
    <p>
      Relying solely on passwords for authentication is no longer sufficient
      given today’s evolving cyber threats. MFA offers an additional layer of
      defense by requiring a combination of factors—such as something the user
      knows, something they have, or something they are. This provides
      substantial protection against unauthorized access, even if one factor
      (e.g., a password) is compromised.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Strengthens Security:</strong> By combining multiple factors
        like biometrics, security tokens, or one-time passwords, MFA
        significantly reduces the chances of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Mitigates Account Compromise:</strong> MFA prevents unauthorized
        access even if login credentials are stolen, greatly reducing the risk
        of data breaches and identity theft.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Many data privacy standards and regulations
        (e.g., GDPR, HIPAA) mandate the use of MFA to protect sensitive
        information, making it crucial for regulatory compliance.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserLock size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports MFA Implementation
    </h4>
    <p>
      <strong>RAAH Technologies</strong> helps organizations design and deploy
      Multi-Factor Authentication (MFA) solutions that align with business
      needs. Our MFA solutions offer:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Customizable MFA Methods:</strong> We implement a variety of
        authentication methods including biometrics, SMS-based OTP, hardware
        tokens, push notifications, and email verification.
      </li>
      <li className="modal-list-item">
        <strong>Seamless Integration:</strong> Our solutions integrate with your
        existing IAM and application infrastructure, offering an uninterrupted
        user experience and reducing complexity during implementation.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive MFA:</strong> Deploy adaptive MFA that leverages
        context-based analysis to prompt for additional verification only when
        risk is detected, balancing security with user convenience.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      Use Case: Securing Healthcare Employee Portals
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare organization needed a way to
      protect access to their employee portal, which contained sensitive patient
      data and financial information. They also needed to meet HIPAA compliance.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed an MFA solution
      utilizing a combination of hardware tokens and push notifications to
      verify employee identities, ensuring that only authorized personnel could
      access sensitive systems.
    </p>
    <p>
      <strong>Result:</strong> The organization significantly improved the
      security of its systems, achieved HIPAA compliance, and experienced a
      substantial reduction in unauthorized access attempts.
    </p>
  </>
);

export default MFAEnhancedSecurityContent;
