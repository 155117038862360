// SeamlessCustomerExperienceContent.js

import React from 'react';
import { FaMobileAlt, FaLaptop, FaSyncAlt } from 'react-icons/fa';

const SeamlessCustomerExperienceContent = () => (
  <>
    <h3 className="modal-main-title">
      Seamless Customer Experience Across Channels (Omni-Channel CIAM for B2C
      and B2B)
    </h3>
    <p>
      Seamless Customer Experience across channels is a fundamental aspect of
      Customer Identity and Access Management (CIAM) for both B2C and B2B use
      cases. It enables customers and partners to have a consistent experience
      across multiple platforms and devices, including mobile apps, web
      applications, kiosks, and more. By providing a unified identity
      experience, organizations can enhance engagement, build trust, and improve
      customer satisfaction.
    </p>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      Benefits of Seamless Customer Experience Across Channels
    </h4>
    <p>
      Implementing an omni-channel experience for CIAM helps organizations
      create cohesive and personalized customer journeys while maintaining
      security and consistency across all channels.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Consistent User Experience:</strong> Provide users with a
        seamless and consistent experience across different channels, reducing
        friction and improving engagement.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Personalization:</strong> Leverage unified customer
        profiles to deliver personalized experiences regardless of the channel,
        improving customer satisfaction.
      </li>
      <li className="modal-list-item">
        <strong>Improved Security:</strong> Centralized identity management
        ensures that security policies are applied consistently across all
        channels, reducing the risk of data breaches.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLaptop size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Seamless Customer Experiences
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive CIAM solutions
      that enable seamless customer experiences across multiple channels for
      both B2C and B2B scenarios. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Unified Customer Profiles:</strong> Create and manage a
        centralized customer profile that provides a consistent experience
        across all channels, ensuring that user preferences and data are
        synchronized.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Implement Single Sign-On across
        multiple platforms, enabling users to authenticate once and access all
        services seamlessly, regardless of the channel.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Authentication:</strong> Deploy adaptive authentication
        methods that provide additional security based on context and
        channel-specific risks, maintaining a balance between security and user
        convenience.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaSyncAlt size={30} style={{ marginRight: '10px' }} />
      Use Case: Creating an Omni-Channel Experience for a Retail Brand
    </h4>
    <p>
      <strong>Challenge:</strong> A retail brand needed to provide a seamless
      and consistent experience for customers across their website, mobile app,
      and in-store kiosks while maintaining secure access to customer data.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a CIAM solution
      that included unified customer profiles, Single Sign-On across all
      channels, and adaptive authentication to provide an enhanced user
      experience while maintaining security.
    </p>
    <p>
      <strong>Result:</strong> The retail brand delivered a consistent and
      personalized customer experience across multiple channels, increased user
      engagement, and ensured data security and compliance with regulations like
      GDPR.
    </p>
  </>
);

export default SeamlessCustomerExperienceContent;
