// PartnerFederationContent.js

import React from 'react';
import { FaHandshake, FaNetworkWired, FaUsers } from 'react-icons/fa';

const PartnerFederationContent = () => (
  <>
    <h3 className="modal-main-title">
      Partner Federation (B2B and B2B2C CIAM)
    </h3>
    <p>
      Partner Federation is an essential feature of Customer Identity and Access
      Management (CIAM) for B2B and B2B2C use cases. It enables seamless and
      secure authentication and access for users across multiple organizations,
      ensuring that partners and third-party stakeholders can easily interact
      with shared resources while maintaining security and compliance.
    </p>

    <h4 className="modal-subheading">
      <FaHandshake size={30} style={{ marginRight: '10px' }} />
      Benefits of Partner Federation
    </h4>
    <p>
      Implementing Partner Federation helps organizations improve collaboration,
      streamline access for partner users, and maintain strong security and
      compliance.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Seamless Access:</strong> Allow users from partner organizations
        to access resources without the need for multiple credentials, enhancing
        user experience and reducing friction.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Utilize secure federation protocols
        like SAML and OpenID Connect to ensure that authentication is handled
        securely, reducing the risk of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that data sharing and access comply
        with regulations like GDPR and CCPA, protecting sensitive information
        and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaNetworkWired size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Partner Federation
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      Partner Federation for both B2B and B2B2C use cases. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Federated Identity Management:</strong> Implement federated
        identity solutions using industry-standard protocols like SAML and
        OpenID Connect to enable secure authentication for partner users.
      </li>
      <li className="modal-list-item">
        <strong>Granular Access Controls:</strong> Define and enforce access
        controls to ensure that partner users only have the access they need,
        minimizing the risk of unauthorized actions.
      </li>
      <li className="modal-list-item">
        <strong>Scalable Integration:</strong> Easily integrate with multiple
        partner organizations to facilitate collaboration and streamline
        onboarding for new partners.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Use Case: Enabling Secure Partner Federation for a B2B2C Retail Network
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B2C retail network needed to enable secure
      collaboration between suppliers, partners, and end customers, while
      ensuring that access was only granted to authorized users.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Partner
      Federation solution using SAML and OpenID Connect, allowing partner users
      to authenticate seamlessly and securely. Granular access controls were
      applied to ensure that users had appropriate access to resources.
    </p>
    <p>
      <strong>Result:</strong> The retail network achieved seamless
      collaboration between partners and customers, improved operational
      efficiency, and ensured compliance with data protection regulations,
      resulting in enhanced trust and stronger business relationships.
    </p>
  </>
);

export default PartnerFederationContent;
