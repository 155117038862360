// PrivilegedAccountDiscoveryLifecycleContent.js

import React from 'react';
import { FaProjectDiagram, FaKey, FaSyncAlt } from 'react-icons/fa';

const PrivilegedAccountDiscoveryLifecycleContent = () => (
  <>
    <h3 className="modal-main-title">
      Privileged Account Discovery and Lifecycle Management
    </h3>
    <p>
      Privileged Account Discovery and Lifecycle Management is a fundamental
      component of Privileged Access Management (PAM). It involves identifying
      all privileged accounts across an organization, managing their lifecycle
      from creation to deactivation, and ensuring that they are appropriately
      governed throughout their existence. Effective lifecycle management helps
      reduce security risks, maintain accountability, and ensure compliance.
    </p>

    <h4 className="modal-subheading">
      <FaProjectDiagram size={30} style={{ marginRight: '10px' }} />
      Benefits of Privileged Account Discovery and Lifecycle Management
    </h4>
    <p>
      Implementing Privileged Account Discovery and Lifecycle Management helps
      organizations enhance security, ensure proper use of privileged accounts,
      and maintain compliance with data protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Visibility:</strong> Discover all privileged accounts
        across the organization, providing a clear understanding of the
        privileged access landscape.
      </li>
      <li className="modal-list-item">
        <strong>Risk Reduction:</strong> Manage the lifecycle of privileged
        accounts to ensure that unused or unnecessary accounts are deactivated,
        minimizing the risk of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that privileged accounts are managed
        in accordance with regulations like GDPR and CCPA, protecting sensitive
        information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Privileged Account Discovery and Lifecycle
      Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers Privileged Access Management
      (PAM) solutions that include Privileged Account Discovery and Lifecycle
      Management. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Account Discovery:</strong> Automatically discover all
        privileged accounts across systems, applications, and environments,
        ensuring that no account is overlooked.
      </li>
      <li className="modal-list-item">
        <strong>Lifecycle Management:</strong> Manage the entire lifecycle of
        privileged accounts, from creation and provisioning to deactivation and
        removal, ensuring that privileges are granted and revoked as needed.
      </li>
      <li className="modal-list-item">
        <strong>Access Reviews:</strong> Conduct regular reviews of privileged
        accounts to ensure that access remains appropriate and compliant with
        organizational policies.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaSyncAlt size={30} style={{ marginRight: '10px' }} />
      Use Case: Managing Privileged Accounts for a Technology Company
    </h4>
    <p>
      <strong>Challenge:</strong> A technology company needed to identify and
      manage all privileged accounts across their IT infrastructure to ensure
      that no unauthorized access was possible and that privileges were properly
      managed.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Privileged
      Account Discovery and Lifecycle Management solution that included
      automated account discovery, lifecycle management, and regular access
      reviews to maintain visibility and control.
    </p>
    <p>
      <strong>Result:</strong> The technology company gained enhanced visibility
      into their privileged accounts, reduced the risk of unauthorized access,
      and ensured compliance with data protection regulations, resulting in
      improved security and operational efficiency.
    </p>
  </>
);

export default PrivilegedAccountDiscoveryLifecycleContent;
