import React from 'react';
import {
  FaUniversity,
  FaLock,
  FaHandHoldingUsd,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const FinancialServicesContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing Financial Services with Cutting-Edge Identity Solutions
    </h3>
    <p>
      In the highly regulated world of financial services, secure access to
      sensitive data and systems is crucial. At{' '}
      <strong>RAAH Technologies</strong>, we provide tailored identity solutions
      that ensure compliance, enhance security, and streamline access for
      financial institutions and their customers.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Challenges in Financial Services
    </h4>
    <p>
      The financial sector faces unique challenges including data breaches,
      stringent regulatory requirements, and the need for secure yet convenient
      customer experiences. Our identity solutions address these key challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing employee access to financial
        systems and sensitive data, ensuring compliance with industry standards.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Providing effective Identity Governance and
        Administration to manage user lifecycles, entitlements, and ensure audit
        readiness.
      </li>
      <li className="modal-list-item">
        <strong>CIAM (B2C):</strong> Offering seamless, secure customer access
        to banking platforms with features like Single Sign-On (SSO) and
        Multi-Factor Authentication (MFA).
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for third-party partners
        and vendors, ensuring secure access while minimizing risks.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that have access to
        critical financial systems, mitigating risks of insider threats and
        unauthorized access.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaHandHoldingUsd size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Financial Services
    </h4>
    <p>Our customized identity solutions for financial services include:</p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security for both workforce and customer access, helping
        protect sensitive financial data from unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring employees
        and contractors have appropriate access to systems based on their roles,
        enhancing security and compliance.
      </li>
      <li className="modal-list-item">
        <strong>Customer Identity and Access Management (CIAM):</strong>{' '}
        Providing secure and seamless access to online banking, investment
        platforms, and other services.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing privileged
        accounts to protect high-value assets and reduce the risk of breaches
        from insiders or compromised credentials.
      </li>
      <li className="modal-list-item">
        <strong>Federated Identity Management:</strong> Enabling secure
        collaboration with partners, vendors, and affiliates by managing their
        identities efficiently.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Secure Banking Corporation
    </h4>
    <p>
      <strong>Challenge:</strong> Secure Banking Corporation needed to secure
      employee and customer access to critical banking systems, comply with
      regulatory requirements, and mitigate insider threats.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided an integrated
      identity solution that included Workforce IAM, CIAM, and PAM. We
      implemented Multi-Factor Authentication (MFA) across workforce and
      customer channels, and used Role-Based Access Control (RBAC) to ensure
      that access to systems was strictly managed based on user roles.
    </p>
    <p>
      <strong>Result:</strong> Secure Banking Corporation achieved a{' '}
      <strong>40% reduction</strong> in unauthorized access attempts, improved
      regulatory compliance, and enhanced customer satisfaction with a more
      secure, seamless login experience.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to financial
        industry regulations, reducing the risk of non-compliance and associated
        penalties.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Comprehensive identity solutions,
        including PAM and MFA, protect against cyber threats and unauthorized
        access.
      </li>
      <li className="modal-list-item">
        <strong>Improved Customer Experience:</strong> Secure, seamless access
        to financial services through CIAM, enhancing customer trust and
        satisfaction.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Role-based access and automated
        lifecycle management reduce the burden on IT teams, enabling them to
        focus on higher-value tasks.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As financial services continue to evolve, secure identity solutions are
      paramount to meet increasing customer expectations and regulatory demands.
      At <strong>RAAH Technologies</strong>, we are committed to delivering
      advanced IAM, IGA, CIAM, and PAM solutions that help financial
      institutions stay ahead of the curve, ensuring both security and
      convenience.
    </p>
  </>
);

export default FinancialServicesContent;
