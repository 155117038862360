import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './ThinkingSphereComponent.css';
import ScrollingTitle from './ScrollingTitle.js';

function ThinkingSphereComponent({ scrollPercentage }) {
    const refContainer = useRef(null);

    useEffect(() => {
        const container = refContainer.current;
        const sphereColor = getComputedStyle(document.documentElement).getPropertyValue('--sphere-color').trim();
        const sphereOpacity = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--sphere-opacity'));
        const circleColor = getComputedStyle(document.documentElement).getPropertyValue('--circle-color').trim();
        const circleOpacity = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--circle-opacity'));

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ alpha: true });

        const updateRendererSize = () => {
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
            renderer.setSize(containerWidth, containerHeight);
            camera.aspect = containerWidth / containerHeight;
            camera.updateProjectionMatrix();
        };

        if (container && !container.hasChildNodes()) {
            container.appendChild(renderer.domElement);
        }

        updateRendererSize();

        const geometry = new THREE.SphereGeometry(25, 12, 12);
        const wireframeGeometry = new THREE.WireframeGeometry(geometry);
        const material = new THREE.LineBasicMaterial({
            color: new THREE.Color(sphereColor),
            transparent: true,
            opacity: sphereOpacity,
        });
        const wireframe = new THREE.LineSegments(wireframeGeometry, material);
        scene.add(wireframe);

        const positions = geometry.attributes.position;
        const circleMeshes = [];
        for (let i = 0; i < positions.count; i++) {
            const vertexPosition = new THREE.Vector3().fromBufferAttribute(positions, i);
            const circleGeometry = new THREE.CircleGeometry(THREE.MathUtils.randFloat(0.1, 1.5), 10);
            const circleMaterial = new THREE.MeshBasicMaterial({
                color: new THREE.Color(circleColor),
                transparent: true,
                opacity: circleOpacity,
            });
            const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
            circleMesh.position.copy(vertexPosition);
            scene.add(circleMesh);
            circleMeshes.push({
                mesh: circleMesh,
                originalPosition: vertexPosition.clone(),
            });
        }

        camera.position.z = 50;

        const animate = () => {
            requestAnimationFrame(animate);
            wireframe.rotation.x += 0.004;
            wireframe.rotation.y += 0.004;

            circleMeshes.forEach(({ mesh, originalPosition }) => {
                const rotatedPosition = originalPosition.clone().applyQuaternion(wireframe.quaternion);
                mesh.position.copy(rotatedPosition);
            });

            renderer.render(scene, camera);
        };

        animate();

        const resizeObserver = new ResizeObserver(() => {
            updateRendererSize();
        });

        if (container) {
            resizeObserver.observe(container);
        }

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
                if (container.contains(renderer.domElement)) {
                    container.removeChild(renderer.domElement);
                }
            }
        };
    }, []);

    return (
        <div className="thinkingSphereContainer">
            <div className="title-and-sphere-wrapper">
                <div ref={refContainer} className="refContainer" />
                <div className="title-wrapper">
                    <ScrollingTitle scrollPercentage={scrollPercentage} />
                </div>
            </div>
        </div>
    );
}

export default ThinkingSphereComponent;
