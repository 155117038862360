import React from 'react';
import Bulb from '../icons/BulbDarkTheme.gif';
import './App.css';

const H7 = () => {
    return (
        
        <div className="header-tile bulb-dark-holder">
            <img
                src={Bulb}
                alt="A representation of the Identity world"
                className="bulb-dark-gif"
                key={Date.now()}
                /> 


            <span className="font-link">
                <h1>Want to learn more </h1>
                <h1>about <span className="highlight">Identity?</span></h1>
            </span>
        
        </div>
    )

}

export default H7;