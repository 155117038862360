// JITPrivilegedAccessContent.js

import React from 'react';
import { FaClock, FaKey, FaUserShield } from 'react-icons/fa';

const JITPrivilegedAccessContent = () => (
  <>
    <h3 className="modal-main-title">Just-In-Time (JIT) Privileged Access</h3>
    <p>
      Just-In-Time (JIT) Privileged Access is a crucial component of Privileged
      Access Management (PAM). It involves granting privileged access to users
      only when they need it and only for the duration required to perform
      specific tasks. This approach minimizes the exposure of privileged
      credentials, reducing the risk of misuse and enhancing overall security.
    </p>

    <h4 className="modal-subheading">
      <FaClock size={30} style={{ marginRight: '10px' }} />
      Benefits of Just-In-Time Privileged Access
    </h4>
    <p>
      Implementing JIT Privileged Access helps organizations reduce the risk of
      unauthorized access, enhance security, and ensure compliance with data
      protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Minimized Attack Surface:</strong> Limit the time during which
        privileged credentials are active, reducing the window of opportunity
        for attackers to exploit them.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Grant privileged access only when
        needed, ensuring that sensitive systems are not exposed to unnecessary
        risk.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that privileged access activities
        comply with regulations like GDPR and CCPA, protecting sensitive
        information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Just-In-Time Privileged Access
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers Privileged Access Management
      (PAM) solutions that include Just-In-Time Privileged Access. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>On-Demand Access:</strong> Provide on-demand privileged access
        that is granted only for the time required to complete specific tasks.
      </li>
      <li className="modal-list-item">
        <strong>Approval Workflows:</strong> Implement approval workflows to
        ensure that privileged access is granted only after appropriate
        authorization, enhancing control and accountability.
      </li>
      <li className="modal-list-item">
        <strong>Automated Access Revocation:</strong> Automatically revoke
        privileged access once the task is completed or the allotted time
        expires, ensuring that no unnecessary privileges remain active.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Use Case: Implementing JIT Privileged Access for a Healthcare Organization
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare organization needed to secure
      access to sensitive patient data while ensuring that administrators could
      perform necessary maintenance tasks without exposing systems to
      unnecessary risk.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a JIT Privileged
      Access solution that provided on-demand access to administrators for
      specific tasks. Approval workflows were used to authorize access, and
      privileges were automatically revoked once tasks were completed.
    </p>
    <p>
      <strong>Result:</strong> The healthcare organization improved security,
      minimized the risk of unauthorized access, and ensured compliance with
      data protection regulations, resulting in enhanced trust and confidence in
      their security practices.
    </p>
  </>
);

export default JITPrivilegedAccessContent;
