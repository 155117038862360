import React from 'react';
import {
  FaCloud,
  FaShieldAlt,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const AWSContent = () => (
  <>
    <h3 className="modal-main-title">
      AWS Identity and Access Management: Securing Cloud Infrastructure
    </h3>
    <p>
      Amazon Web Services (AWS) offers powerful identity and access management
      tools to secure cloud infrastructure and manage user permissions
      effectively. At <strong>RAAH Technologies</strong>, we help organizations
      harness AWS IAM, Single Sign-On (SSO), and other AWS identity tools to
      ensure their cloud environments are secure and compliant.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Key Capabilities of AWS Identity Solutions
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>AWS Identity and Access Management (IAM):</strong> Manage access
        to AWS resources by defining granular permissions for users, groups, and
        roles.
      </li>
      <li className="modal-list-item">
        <strong>AWS Single Sign-On (SSO):</strong> Centralize identity
        management and access control for AWS accounts and third-party
        applications.
      </li>
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Add an extra layer
        of security to protect AWS accounts by requiring multiple forms of
        authentication.
      </li>
      <li className="modal-list-item">
        <strong>Federated Access:</strong> Enable secure access to AWS resources
        for users outside of your organization by integrating with identity
        providers.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Securing Multi-Account AWS Environments
    </h4>
    <p>
      <strong>Challenge:</strong> A growing enterprise needed to secure multiple
      AWS accounts while managing user access efficiently across different
      departments.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented AWS SSO to
      centralize identity management across all AWS accounts. We also configured
      IAM roles and policies to enforce least privilege access and enabled MFA
      for all users.
    </p>
    <p>
      <strong>Result:</strong> The enterprise achieved consistent security
      controls across AWS accounts and significantly reduced the administrative
      overhead of managing access.
    </p>

    <h4 className="modal-subheading">
      <FaCloud size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we have extensive experience in
      designing and implementing AWS identity solutions to meet diverse business
      needs. Our services include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Consulting and strategy for AWS cloud security and identity management.
      </li>
      <li className="modal-list-item">
        Integration of AWS IAM with existing identity management systems to
        create a unified security posture.
      </li>
      <li className="modal-list-item">
        Configuration of AWS SSO, IAM roles, and policies to enforce security
        best practices.
      </li>
      <li className="modal-list-item">
        Ongoing support and optimization to ensure your AWS environment remains
        secure and efficient.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://docs.aws.amazon.com/iam/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS IAM Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://aws.amazon.com/iam/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS IAM Overview
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://aws.amazon.com/single-sign-on/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS SSO Overview
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://aws.amazon.com/security/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS Security and Identity Resources
        </a>
      </li>
    </ul>
  </>
);

export default AWSContent;
