// PrivilegedAccessGovernanceContent.js

import React from 'react';
import { FaGavel, FaShieldAlt, FaClipboardCheck } from 'react-icons/fa';

const PrivilegedAccessGovernanceContent = () => (
  <>
    <h3 className="modal-main-title">Privileged Access Governance</h3>
    <p>
      Privileged Access Governance is an essential aspect of Privileged Access
      Management (PAM). It involves establishing policies, procedures, and
      controls to manage and govern the use of privileged accounts, ensuring
      that privileged access is granted and used appropriately. Effective
      governance helps organizations minimize security risks, maintain
      accountability, and comply with data protection regulations.
    </p>

    <h4 className="modal-subheading">
      <FaGavel size={30} style={{ marginRight: '10px' }} />
      Benefits of Privileged Access Governance
    </h4>
    <p>
      Implementing Privileged Access Governance helps organizations enhance
      security, ensure proper use of privileged accounts, and maintain
      compliance with data protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Establish policies and controls to
        ensure that privileged access is granted only to authorized users and
        used appropriately, reducing the risk of unauthorized actions.
      </li>
      <li className="modal-list-item">
        <strong>Accountability:</strong> Maintain detailed records of privileged
        access activities, ensuring that users are held accountable for their
        actions.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that privileged access activities
        comply with regulations like GDPR and CCPA, protecting sensitive
        information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Privileged Access Governance
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers Privileged Access Management
      (PAM) solutions that include Privileged Access Governance. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Policy Enforcement:</strong> Define and enforce policies that
        govern the use of privileged accounts, ensuring that access is granted
        and used in accordance with organizational standards.
      </li>
      <li className="modal-list-item">
        <strong>Access Reviews:</strong> Conduct periodic reviews of privileged
        access to ensure that only authorized users have access and that
        privileges are appropriate for their roles.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Reporting:</strong> Generate detailed reports of
        privileged access activities to ensure compliance with regulatory
        requirements and provide insights for improving governance practices.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardCheck size={30} style={{ marginRight: '10px' }} />
      Use Case: Implementing Privileged Access Governance for a Healthcare
      Organization
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare organization needed to ensure
      that privileged access to sensitive patient data was properly governed to
      comply with data protection regulations and prevent unauthorized access.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Privileged
      Access Governance solution that included policy enforcement, access
      reviews, and audit reporting to ensure that privileged access was properly
      managed and compliant with regulations.
    </p>
    <p>
      <strong>Result:</strong> The healthcare organization improved security,
      ensured compliance with data protection regulations, and maintained
      accountability for all privileged access activities, resulting in enhanced
      trust and confidence in their security practices.
    </p>
  </>
);

export default PrivilegedAccessGovernanceContent;
