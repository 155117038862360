import React, { useState, useRef, useEffect } from 'react';
import SolutionsComponentTitle from './solutionsComponentTitle';
import SolutionsComponent1 from './SolutionsComponent1';
import SolutionsComponent2 from './SolutionsComponent2';
import SolutionsComponent3 from './solutionsComponent3';
import SolutionsComponent4 from './solutionsComponent4';
import './SolutionsPage.css';
import HamburgerMenu from '../overlays/HamburgerMenu';
import ScrollArrow from '../overlays/ScrollArrow';
import Icon from '../overlays/Icon';
import RightSphere from '../overlays/RightSphere';
import LeftSphere from '../overlays/LeftSphere';

function SolutionsPage() {
  const [animationComplete, setAnimationComplete] = useState(false);
  const containerRef = useRef();

  const handleSolutionsAnimationEnd = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    if (animationComplete) {
      // Any additional effect if needed after animation completes.
    }
  }, [animationComplete]);

  return (
    <div>
      {!animationComplete && (
        <div className="solutions-page container" ref={containerRef}>
          <div
            className="solutions-scroll-section"
            style={{ height: '100vh', width: '100%', overflow: 'hidden' }}
          >
            <SolutionsComponentTitle
              onAnimationEnd={handleSolutionsAnimationEnd}
            />
          </div>
        </div>
      )}

      {animationComplete && (
        <div
          className="solutions-page container"
          ref={containerRef}
          style={{ overflowY: 'scroll' }}
        >
          <Icon />
          <HamburgerMenu />
          <LeftSphere />
          <RightSphere />
          <ScrollArrow />
          <div
            id="solutions-component-1"
            className="solutions-scroll-section"
            style={{ height: '100vh' }}
          >
            {/* Pass containerRef to SolutionsComponent1 */}
            <SolutionsComponent1 containerRef={containerRef} />
          </div>
          <div
            id="solutions-component-2"
            className="solutions-scroll-section"
            style={{ height: '100vh' }}
          >
            <SolutionsComponent2 />
          </div>
          <div
            id="solutions-component-3"
            className="solutions-scroll-section"
            style={{ height: '100vh' }}
          >
            <SolutionsComponent3 />
          </div>
          <div
            id="solutions-component-4"
            className="solutions-scroll-section"
            style={{ height: '100vh' }}
          >
            <SolutionsComponent4 />
          </div>
        </div>
      )}
    </div>
  );
}

export default SolutionsPage;
