import React, { useEffect } from 'react';
import './solutionsComponentTitle.css';

const SolutionsComponentTitle = ({ onAnimationEnd }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onAnimationEnd();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onAnimationEnd]);

  return (
    <div className="scTitle">
      <h1>
        <span className="highlightSolutions">RAAH</span> Solutions
      </h1>
    </div>
  );
};

export default SolutionsComponentTitle;
