import React, { useState, useRef, forwardRef } from 'react';
import HamburgerMenu from '../overlays/HamburgerMenu';
import ScrollArrow from '../overlays/ScrollArrow';
import Icon from '../overlays/Icon';
import RightSphere from '../overlays/RightSphere';
import LeftSphere from '../overlays/LeftSphere';
import './ContactForm.css';
 
// Reusable InputField component using forwardRef
const InputField = forwardRef(
  (
    { label, name, value, placeholder, onChange, type = 'text', error },
    ref
  ) => (
    <div>
      <label>
        {label}
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          ref={ref}
          required
        />
      </label>
      {error && <p className="error-message">{error}</p>}
    </div>
  )
);
 
const ContactForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    email: '',
    phone: '',
    industry: '',
    numberOfEmployees: '',
    numberOfUsers: '',
    bestTimeToContact: '',
    additionalDetails: '',
    service: '',
    advisoryType: '',
    advisoryCommunication: '',
    professionalServiceType: '',
    projectStartDate: '',
    managedServiceType: '',
    contractDuration: '',
    staffingRoles: '',
    numberOfPositions: '',
    inquiryDetails: '',
  });
 
  const companyNameRef = useRef(null);
  const emailRef = useRef(null);
  const industryRef = useRef(null);
  const nextSectionRef = useRef(null);
  const formRef = useRef(null); // Create a ref for the form
  const [submitMessage, setSubmitMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const validateForm = () => {
    console.log('Entered form validation.');
    let newErrors = {};
    let firstInvalidField = null;
 
    if (!formData.companyName) {
      newErrors.companyName = 'Company Name is required';
      if (!firstInvalidField) firstInvalidField = companyNameRef;
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
      if (!firstInvalidField) firstInvalidField = emailRef;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      if (!firstInvalidField) firstInvalidField = emailRef;
    }
    if (!formData.industry) {
      newErrors.industry = 'Industry is required';
      if (!firstInvalidField) firstInvalidField = industryRef;
    }
 
    console.log('Validation errors:', newErrors); // Debugging log
 
    setErrors(newErrors);
    if (firstInvalidField) {
      firstInvalidField.current?.scrollIntoView({ behavior: 'smooth' });
    }
 
    return Object.keys(newErrors).length === 0;
  };
 
  const handleChange = (e) => {
    console.log('Change made to form.');
 
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
 
  const handleExternalSubmit = () => {
    // Programmatically submit the form by calling the form's submit method
    console.log('entered external submit code.');
    if (formRef.current) {
      console.log('entered external submit code. IF');
      // formRef.current.requestSubmit(); // Submits the form
      handleSubmit(new Event('submit'));
    }
  };
 
  const handleSubmit = async (e) => {
    console.log('Submitted form.');
 
    e.preventDefault();
 
    // Clear previous submit message
    setSubmitMessage(null);
 
    // Check if form is valid
    if (!validateForm()) {
      console.log('Form validation failed:', errors);
      return;
    }
 
    setIsSubmitting(true); // Disable button and show submitting state
 
    const apiUrl =
      'https://us-central1-raahtech-website-server.cloudfunctions.net/app/api/contact-form';
 
    try {
      console.log('Submitting form data:', formData); // Debugging log
 
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
 
      if (response.ok) {
        setSubmitMessage('Form submitted successfully!');
        setFormData({
          companyName: '',
          email: '',
          phone: '',
          industry: '',
          numberOfEmployees: '',
          numberOfUsers: '',
          bestTimeToContact: '',
          additionalDetails: '',
          service: '',
          advisoryType: '',
          advisoryCommunication: '',
          professionalServiceType: '',
          projectStartDate: '',
          managedServiceType: '',
          contractDuration: '',
          staffingRoles: '',
          numberOfPositions: '',
          inquiryDetails: '',
        });
        setErrors({}); // Reset errors after successful submit
      } else {
        const errorData = await response.json(); // Capture error response
        console.log('Form submission failed:', errorData); // Debugging log
        setSubmitMessage('Failed to submit form.'); // Display error message
      }
    } catch (error) {
      console.error('Error submitting form:', error); // Debugging log
      setSubmitMessage('An error occurred while submitting the form.'); // Display error message
    }
 
    setIsSubmitting(false); // Re-enable submit button
  };
 
  const scrollToNextSection = () => {
    console.log('Scrolled to next section.');
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
 
  const renderServiceQuestions = () => {
    switch (formData.service) {
      case 'Advisory Services':
        return (
          <>
            <InputField
              label="What type of advisory are you seeking?"
              name="advisoryType"
              value={formData.advisoryType || ''}
              onChange={handleChange}
            />
            <label>
              Preferred method of communication
              <select
                name="advisoryCommunication"
                value={formData.advisoryCommunication || ''}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select a method
                </option>
                <option value="Phone">Phone</option>
                <option value="Email">Email</option>
                <option value="In-Person">In-Person</option>
              </select>
            </label>
          </>
        );
      case 'Professional Services':
        return (
          <>
            <InputField
              label="What type of professional service are you interested in?"
              name="professionalServiceType"
              value={formData.professionalServiceType || ''}
              onChange={handleChange}
            />
            <InputField
              label="Project start date:"
              name="projectStartDate"
              value={formData.projectStartDate || ''}
              onChange={handleChange}
              type="date"
            />
          </>
        );
      case 'Managed Services':
        return (
          <>
            <InputField
              label="What type of managed service are you looking for?"
              name="managedServiceType"
              value={formData.managedServiceType || ''}
              onChange={handleChange}
            />
            <InputField
              label="Contract duration (months):"
              name="contractDuration"
              value={formData.contractDuration || ''}
              onChange={handleChange}
              type="number"
            />
          </>
        );
      case 'Staffing Services':
        return (
          <>
            <InputField
              label="What type of roles are you looking to staff?"
              name="staffingRoles"
              value={formData.staffingRoles || ''}
              onChange={handleChange}
            />
            <InputField
              label="Number of positions:"
              name="numberOfPositions"
              value={formData.numberOfPositions || ''}
              onChange={handleChange}
              type="number"
            />
          </>
        );
      case 'General Inquiry':
        return (
          <>
            <label>
              Please provide details of your inquiry
              <textarea
                name="inquiryDetails"
                value={formData.inquiryDetails || ''}
                onChange={handleChange}
                rows="4"
              />
            </label>
          </>
        );
      default:
        return null;
    }
  };
 
  return (
    <div className="contact-page contact-page-container">
      <Icon />
      <HamburgerMenu />
      <LeftSphere />
      <RightSphere />
      <form onSubmit={handleSubmit} ref={formRef}>
        <h2>Contact Us</h2>
 
        <div className="split-sides">
          <div className="left-section">
            <InputField
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              ref={companyNameRef}
              error={errors.companyName}
              placeholder="e.g., Acme Corp"
            />
            <InputField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              ref={emailRef}
              error={errors.email}
              placeholder="e.g., john.doe@example.com"
            />
            <InputField
              label="Phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              error={errors.phone}
              placeholder="e.g., (123) 456-7890"
            />
            <label ref={industryRef}>
              Industry
              <select
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select your industry
                </option>
                <option value="Technology">Technology</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Education">Education</option>
                <option value="Retail">Retail</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Transportation">Transportation</option>
                <option value="Legal">Legal</option>
                <option value="Marketing">Marketing</option>
              </select>
            </label>
            {errors.industry && (
              <p className="error-message">{errors.industry}</p>
            )}
          </div>
 
          <div className="right-section">
            <InputField
              label="Number of Employees"
              name="numberOfEmployees"
              type="number"
              value={formData.numberOfEmployees}
              onChange={handleChange}
              placeholder="e.g., 500"
            />
            <InputField
              label="Number of Users"
              name="numberOfUsers"
              type="number"
              value={formData.numberOfUsers}
              onChange={handleChange}
              placeholder="e.g., 2000"
            />
 
            <label>
              Best Time to Contact
              <select
                name="bestTimeToContact"
                value={formData.bestTimeToContact}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a time
                </option>
                <option value="9am-12pm EST">Morning (9 AM - 12 PM EST)</option>
                <option value="12pm-3pm EST">
                  Afternoon (12 PM - 3 PM EST)
                </option>
                <option value="3pm-5pm EST">Evening (3 PM - 5 PM EST)</option>
              </select>
            </label>
 
            <label>
              Additional Details
              <textarea
                name="additionalDetails"
                value={formData.additionalDetails}
                onChange={handleChange}
                rows="5"
              />
            </label>
          </div>
        </div>
 
        <div className="continue-button">
          <button type="button" onClick={scrollToNextSection}>
            Continue
          </button>
        </div>
      </form>
 
      <div className="next-section-container">
        <div className="next-section" ref={nextSectionRef}>
          <h2>Service Selection</h2>
          <label>
            Service
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a service
              </option>
              <option value="Advisory Services">Advisory Services</option>
              <option value="Professional Services">
                Professional Services
              </option>
              <option value="Managed Services">Managed Services</option>
              <option value="Staffing Services">Staffing Services</option>
              <option value="General Inquiry">General Inquiry</option>
            </select>
          </label>
 
          {renderServiceQuestions()}
 
          <div className="continue-button">
            <button
              type="button"
              disabled={isSubmitting}
              onClick={handleExternalSubmit}
            >
              {isSubmitting ? 'Submitting...' : 'Submit All'}
            </button>
          </div>
 
          {submitMessage && <p aria-live="polite">{submitMessage}</p>}
        </div>
      </div>
    </div>
  );
};
 
export default ContactForm;