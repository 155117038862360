import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LeftSphere.css'

function LeftSphere() {
    const navigate = useNavigate();
    return (
        <div className='left-outer-circle'>
            <div className='left-inner-circle'> </div>
        </div>
    );
}

export default LeftSphere;