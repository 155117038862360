import React, { useRef, useState, useEffect } from "react";
import './ServicesPage.css'
import HamburgerMenu from "../overlays/HamburgerMenu";
import ScrollArrow from "../overlays/ScrollArrow";
import Icon from "../overlays/Icon";
import RightSphere from "../overlays/RightSphere";
import LeftSphere from "../overlays/LeftSphere";
import ServicesComponent1 from "./ServicesComponent1";
import ServicesComponent2 from "./ServicesComponent2";
import ServicesComponent3 from "./ServicesComponent3";
import ServicesComponent4 from "./ServicesComponent4";
import ServicesComponent5 from "./ServicesComponent5";
import ServicesComponentTitle from "./servicesComponentTitle";

function ServicesPage() {
  const [animationComplete, setAnimationComplete] = useState(false);
  const [windowScrollPercent, setWindowScrollPercent] = useState(0);
  const [divScrollPercents, setDivScrollPercents] = useState([0, 0, 0, 0, 0]);
  const containerRef = useRef(); // Reference to the scrollable container

  const handleServicesAnimationEnd = () => {
    setAnimationComplete(true);
  };

  const handleContainerScroll = () => {
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const totalScroll = scrollHeight - clientHeight;
    const scrollPercentage = (scrollTop / totalScroll) * 100;
    setWindowScrollPercent(scrollPercentage);

    // Calculate individual div scroll percentages based on container scroll percentage
    const sections = document.querySelectorAll('.scroll-section');
    let startScrollPercent = 0;

    const newScrollPercents = Array.from(sections).map((section) => {
      const sectionHeight = section.clientHeight;
      const sectionScrollPercent = (sectionHeight / scrollHeight) * 100;

      let divScrollPercent = 0;
      if (scrollPercentage >= startScrollPercent && scrollPercentage < startScrollPercent + sectionScrollPercent) {
        divScrollPercent = ((scrollPercentage - startScrollPercent) / sectionScrollPercent) * 100;
      } else if (scrollPercentage >= startScrollPercent + sectionScrollPercent) {
        divScrollPercent = 100; // Fully scrolled past the section
      }

      startScrollPercent += sectionScrollPercent;
      return divScrollPercent;
    });

    setDivScrollPercents(newScrollPercents);
  };

  // Add event listener for container scroll only after the animation completes
  useEffect(() => {
    if (animationComplete) {
      const container = containerRef.current;
      container.addEventListener('scroll', handleContainerScroll);

      return () => {
        container.removeEventListener('scroll', handleContainerScroll);
      };
    }
  }, [animationComplete]); // Only apply scrolling logic after animation is complete

  return (
    <div>
      {!animationComplete && (
        <div className="Services container" ref={containerRef}>
          <div
            className="scroll-section"
            style={{ height: '100vh', width: '100%', overflow: 'hidden' }}
          >
            <ServicesComponentTitle
              onAnimationEnd={handleServicesAnimationEnd}
            />
          </div>
        </div>
      )}

      {animationComplete && (
        <div className="Services container" ref={containerRef} style={{ overflowY: "scroll" }}>
          <Icon />
          <HamburgerMenu />
          <LeftSphere />
          <RightSphere />
          <ScrollArrow />
          <div id="ServicesComponent1" className='scroll-section' style={{ height: "100vh", position: "relative" }}>
            <ServicesComponent1 scrollPercentage={divScrollPercents[0]} />
          </div>
          <div id="ServicesComponent2" className='scroll-section' style={{ height: "500vh", position: "relative" }}>
            <ServicesComponent2 scrollPercentage={divScrollPercents[1]} />
          </div>
          <div id="ServicesComponent3" className='scroll-section' style={{ height: "400vh", position: "relative" }}>
            <ServicesComponent3 scrollPercentage={divScrollPercents[2]} />
          </div>
          <div id="ServicesComponent4" className='scroll-section' style={{ height: "500vh", position: "relative" }}>
            <ServicesComponent4 scrollPercentage={divScrollPercents[3]} />
          </div>
          <div id="ServicesComponent5" className='scroll-section' style={{ height: "400vh", position: "relative" }}>
            <ServicesComponent5 scrollPercentage={divScrollPercents[4]} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ServicesPage;
