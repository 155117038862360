import React from 'react';
import { FaMobileAlt, FaLock, FaNetworkWired } from 'react-icons/fa';

const DeviceEndpointContent = () => (
  <>
    <h3 className="modal-main-title">
      Device and Endpoint Identity Management
    </h3>
    <p>
      Device and Endpoint Identity Management is crucial for securing identities
      across the numerous devices used in modern workplaces. It involves
      managing the identities of devices and ensuring that only authorized
      devices are allowed to access sensitive systems and data.
    </p>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      Benefits of Device and Endpoint Identity Management
    </h4>
    <p>
      Implementing Device and Endpoint Identity Management helps organizations
      protect against unauthorized device access and maintain visibility over
      the devices accessing their network.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Ensure that only trusted devices can
        access critical systems and sensitive data, reducing the risk of
        breaches.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Meet regulatory requirements by managing
        and tracking device identities effectively, ensuring adherence to
        security standards.
      </li>
      <li className="modal-list-item">
        <strong>Centralized Management:</strong> Streamline device identity
        management by centralizing control, which reduces administrative
        overhead and enhances operational efficiency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaNetworkWired size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Device Identity Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers solutions to manage device
      identities and ensure secure access across your organization. Our
      solutions provide:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Trusted Device Authentication:</strong> We help you implement
        trusted device authentication, allowing only pre-registered and
        authenticated devices to connect to your network.
      </li>
      <li className="modal-list-item">
        <strong>Seamless Integration:</strong> Our device management solutions
        integrate with existing IAM systems, providing comprehensive visibility
        and control without adding complexity.
      </li>
      <li className="modal-list-item">
        <strong>Policy Enforcement:</strong> Define and enforce policies to
        control device access based on risk, compliance needs, and business
        requirements.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Use Case: Securing Remote Work Devices
    </h4>
    <p>
      <strong>Challenge:</strong> A global organization needed to secure remote
      work devices accessing their network, while maintaining a seamless
      experience for employees.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Device and
      Endpoint Identity Management solution that authenticated devices before
      granting access, ensuring that only company-approved devices could
      connect.
    </p>
    <p>
      <strong>Result:</strong> The organization significantly improved its
      security posture, reduced unauthorized access attempts, and provided a
      secure remote work environment for employees.
    </p>
  </>
);

export default DeviceEndpointContent;
