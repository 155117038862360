// SocialLoginContent.js

import React from 'react';
import { FaFacebook, FaGoogle, FaHandshake } from 'react-icons/fa';

const SocialLoginContent = () => (
  <>
    <h3 className="modal-main-title">
      Social and Federated Login for Customers (B2C and B2B CIAM)
    </h3>
    <p>
      Social and Federated Login are key features of Customer Identity and
      Access Management (CIAM) for B2C and B2B use cases. These login methods
      allow customers and partners to use existing social media or federated
      identities to access services, providing a seamless and convenient
      authentication experience while maintaining security and compliance.
    </p>

    <h4 className="modal-subheading">
      <FaFacebook size={30} style={{ marginRight: '10px' }} />
      Benefits of Social and Federated Login
    </h4>
    <p>
      Implementing Social and Federated Login helps organizations improve user
      experience, reduce friction during onboarding, and enhance security by
      leveraging trusted identity providers.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved User Experience:</strong> Allowing users to sign in
        with existing social or corporate identities reduces the need for
        creating and remembering new credentials, providing a more seamless
        onboarding experience.
      </li>
      <li className="modal-list-item">
        <strong>Reduced Onboarding Friction:</strong> Social and Federated Login
        simplify the registration process, making it easier for users to start
        using services without complex sign-up forms.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Leverage trusted identity providers
        such as Google, Facebook, or corporate identity systems, which provide
        additional layers of security and validation.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that user data is handled in
        compliance with regulations like GDPR and CCPA, while providing users
        with transparency and control over their consent.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaHandshake size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Social and Federated Login
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive CIAM solutions
      that support Social and Federated Login for both B2C and B2B use cases.
      Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Integration with Leading Identity Providers:</strong> Enable
        login via popular social platforms like Google, Facebook, LinkedIn, and
        federated identity providers such as SAML or OIDC to streamline
        authentication.
      </li>
      <li className="modal-list-item">
        <strong>Customizable Consent Management:</strong> Allow users to manage
        consent preferences and provide transparency into how their data is
        used, ensuring compliance with GDPR and CCPA.
      </li>
      <li className="modal-list-item">
        <strong>Secure Data Handling:</strong> Ensure that user data is securely
        managed and encrypted, while complying with relevant data protection
        regulations.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaGoogle size={30} style={{ marginRight: '10px' }} />
      Use Case: Enabling Seamless Login for a B2B SaaS Platform
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B SaaS company needed to streamline the
      authentication process for their clients by enabling federated login
      options, reducing the friction for business users accessing their
      services.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Social and
      Federated Login solution that integrated with leading identity providers,
      allowing users to authenticate using their existing corporate credentials
      or social media accounts.
    </p>
    <p>
      <strong>Result:</strong> The SaaS platform improved user onboarding speed,
      reduced password fatigue, and enhanced user satisfaction, while
      maintaining compliance with data protection regulations.
    </p>
  </>
);

export default SocialLoginContent;
