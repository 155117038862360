import React from 'react';
import {
  FaFilm,
  FaShieldAlt,
  FaPlayCircle,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const MediaContent = () => (
  <>
    <h3 className="modal-main-title">
      Empowering Media and Entertainment with Secure Identity Solutions
    </h3>
    <p>
      The media and entertainment sector is undergoing rapid digital
      transformation, with secure access to content and collaboration being key
      priorities. At <strong>RAAH Technologies</strong>, we provide customized
      identity and access management solutions that safeguard content,
      streamline operations, and ensure secure access for all stakeholders.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in Media and Entertainment
    </h4>
    <p>
      Media and entertainment companies face significant challenges, such as
      protecting intellectual property, managing secure access to content, and
      ensuring collaboration with partners and vendors. Our identity solutions
      address these challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing access for content creators,
        editors, and administrative personnel, ensuring compliance with industry
        standards.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities for internal and external
        personnel, ensuring appropriate access rights, and providing audit
        trails for compliance.
      </li>
      <li className="modal-list-item">
        <strong>CIAM (B2C):</strong> Providing seamless access to streaming
        platforms for subscribers, ensuring a secure and smooth user experience
        with features like Single Sign-On (SSO) and Multi-Factor Authentication
        (MFA).
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that access
        critical content and media systems, mitigating risks of insider threats
        and unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for external partners,
        production companies, and other collaborators, ensuring secure
        integration across the media ecosystem.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaPlayCircle size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Media and Entertainment
    </h4>
    <p>
      Our customized identity solutions for the media and entertainment sector
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect access to content management systems,
        safeguarding against unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling content creators and
        editors to use a single set of credentials to access multiple systems,
        improving efficiency and reducing login fatigue.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that media
        personnel have access to the systems and data they need, based on their
        roles and responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user identities
        throughout their employment lifecycle, ensuring compliance with industry
        regulations.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing access to
        critical content systems, including administrative tools and content
        storage, to protect against unauthorized use.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Global Media Company
    </h4>
    <p>
      <strong>Challenge:</strong> A global media company needed to protect its
      intellectual property while enabling content creators and editors to
      access content and collaborate securely across multiple locations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided a comprehensive
      identity solution, including Single Sign-On (SSO) and Multi-Factor
      Authentication (MFA) for secure access. Role-Based Access Control (RBAC)
      was implemented to ensure appropriate access based on job
      responsibilities, and Privileged Access Management (PAM) protected
      administrative tools and content storage systems.
    </p>
    <p>
      <strong>Result:</strong> The media company experienced a{' '}
      <strong>30% reduction</strong> in unauthorized access attempts and
      improved collaboration, resulting in faster content production and
      increased viewer trust.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Content Security:</strong> Protecting intellectual
        property and media content with CIAM and MFA, ensuring privacy and
        security.
      </li>
      <li className="modal-list-item">
        <strong>Improved Operational Efficiency:</strong> Streamlined access for
        media personnel with SSO and RBAC, reducing administrative burden and
        enhancing productivity.
      </li>
      <li className="modal-list-item">
        <strong>Secure Collaboration:</strong> Enabling secure interactions with
        partners, production companies, and vendors through effective identity
        management.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to media and
        entertainment industry standards, reducing the risk of penalties and
        breaches.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As the media and entertainment sector continues to evolve, secure identity
      solutions are crucial to protecting intellectual property and enabling
      efficient content production and distribution. At{' '}
      <strong>RAAH Technologies</strong>, we are dedicated to providing advanced
      IAM, IGA, CIAM, and PAM solutions that meet the complex needs of media
      professionals and their audiences.
    </p>
  </>
);

export default MediaContent;
