import React, { useState, useEffect, useRef } from "react";
import "./teamComponent.css";
import Team1 from "../icons/Naren.png";
import Team2 from "../icons/Ovi.png";
import Team3 from "../icons/Tarun.png";
import Team4 from "../icons/Antonio.png";
import LinkedInIcon from "../icons/LinkedInIcon.gif"

function TeamComponent() {
    const [showTeamHeader, setShowTeamHeader] = useState(false);
    const [showItems, setShowItems] = useState(false);
    const teamSectionRef = useRef(null);

    const images = [
        { src: Team1, header: "Naren Jangid", text: <>Founder & CEO</>, linkedinURL: "https://www.linkedin.com/in/narenjangid/" },
        { src: Team2, header: "Ovi Balaj", text: <>Chief Operating and Strategy Officer</>, linkedinURL: "https://www.linkedin.com/in/ovi-balaj/" },
        { src: Team3, header: "Tarun Guhanand", text: <>Director | Implementation & Delivery</>, linkedinURL: "https://www.linkedin.com/in/tarun-guhanand-atlanta-iam-consultant/" },
        { src: Team4, header: "Antonio Herrera", text: <>Senior Account Executive</>, linkedinURL: "https://www.linkedin.com/in/antonioherrera1/" },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setShowTeamHeader(true);
                    setTimeout(() => {
                        setShowItems(true);
                    }, 1000);
                }
            },
            { threshold: 0.3 }
        );
        if (teamSectionRef.current) {
            observer.observe(teamSectionRef.current);
        }
        return () => {
            if (teamSectionRef.current) {
                observer.unobserve(teamSectionRef.current);
            }
        };
    }, []);

    return (
        <div className="teamComponentContainer" ref={teamSectionRef}>
            <div className={`teamComponentHeader ${showTeamHeader ? "animate" : ""}`}>
                <h1>The <span className="highlightTeam">RAAH</span> Team</h1>
            </div>
            {showItems && (
                <div className="teamItemWrapper">
                    {images.map((image, index) => (
                        <div key={index} className="teamItem">
                            <img
                                src={image.src}
                                alt={`team-image-${index}`}
                                className="teamImage"
                            />
                            <div className="teamContent">
                                <div className="teamContentHeader">
                                    <h2>{image.header}</h2>
                                    <p>{image.text}</p>
                                </div>
                                <a href={image.linkedinURL}><img src={LinkedInIcon} alt={`team-image-${index}`}
                                    className="linkedInImage" /></a>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default TeamComponent;
