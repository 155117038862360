// RealTimeIdentityThreatDetectionContent.js

import React from 'react';
import { FaShieldAlt, FaExclamationTriangle, FaClock } from 'react-icons/fa';

const IdentityThreatDetectionContent = () => (
  <>
    <h3 className="modal-main-title">
      Real-Time Identity Threat Detection (CIAM)
    </h3>
    <p>
      Real-Time Identity Threat Detection is a vital component of Customer
      Identity and Access Management (CIAM), especially for B2C and B2B use
      cases. It involves monitoring user activities and behaviors to detect and
      respond to suspicious activities in real time, helping to prevent identity
      fraud and ensure the security of customer data.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Benefits of Real-Time Identity Threat Detection
    </h4>
    <p>
      Implementing real-time threat detection helps organizations identify and
      mitigate risks before they become serious security incidents, ensuring the
      safety and integrity of customer identities.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Early Threat Detection:</strong> Identify suspicious activities
        and potential threats as they occur, allowing for immediate action to
        mitigate risks.
      </li>
      <li className="modal-list-item">
        <strong>Fraud Prevention:</strong> Prevent identity fraud by monitoring
        unusual login patterns, device changes, and other risk factors that may
        indicate unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure compliance with data protection
        regulations such as GDPR and CCPA by implementing proactive measures to
        protect customer data.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaExclamationTriangle size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Real-Time Identity Threat Detection
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers comprehensive CIAM solutions
      that include real-time identity threat detection for both B2C and B2B
      scenarios. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Behavioral Analytics:</strong> Utilize advanced behavioral
        analytics to detect anomalies in user activities, such as unusual login
        locations or rapid changes in device usage.
      </li>
      <li className="modal-list-item">
        <strong>Risk-Based Authentication:</strong> Implement risk-based
        authentication that prompts for additional verification when suspicious
        behavior is detected, balancing security and user experience.
      </li>
      <li className="modal-list-item">
        <strong>Automated Alerts and Responses:</strong> Set up automated alerts
        for suspicious activities and enable immediate response actions, such as
        account locking or requiring multi-factor authentication (MFA).
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClock size={30} style={{ marginRight: '10px' }} />
      Use Case: Preventing Fraud for an Online Banking Platform
    </h4>
    <p>
      <strong>Challenge:</strong> An online banking platform needed to protect
      customer accounts from identity fraud while ensuring a smooth user
      experience for legitimate users.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a real-time
      identity threat detection solution that leveraged behavioral analytics and
      risk-based authentication to monitor user activities and respond to
      suspicious behavior instantly.
    </p>
    <p>
      <strong>Result:</strong> The banking platform significantly reduced
      instances of fraud, improved customer trust, and ensured compliance with
      data protection regulations, all while maintaining a user-friendly
      experience.
    </p>
  </>
);

export default IdentityThreatDetectionContent;
