import React, { useState, useRef, useEffect } from "react";
import PhilosophyComponent from "./philosophyComponent";
import ValueComponent from "./valueComponent";
import TeamComponent from "./teamComponent";
import AboutComponentTitle from "./aboutComponentTitle";
import WayComponent from "./wayComponent";
import "./About.css";
import ContactAboutComponent from "./ContactAboutComponent";
import HamburgerMenu from "../overlays/HamburgerMenu";
import ScrollArrow from "../overlays/ScrollArrow";
import Icon from "../overlays/Icon";
import RightSphere from "../overlays/RightSphere";
import LeftSphere from "../overlays/LeftSphere";

function About() {
    const [animationComplete, setAnimationComplete] = useState(false);
    const [windowScrollPercent, setWindowScrollPercent] = useState(0);
    const [divScrollPercents, setDivScrollPercents] = useState([0, 0, 0, 0, 0]);
    const containerRef = useRef(); // Reference to the scrollable container

    const handleAboutAnimationEnd = () => {
        setAnimationComplete(true);
    };

    // Function to calculate scroll percentage of the scrollable container
    const handleContainerScroll = () => {
        const container = containerRef.current;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        const totalScroll = scrollHeight - clientHeight;
        const scrollPercentage = (scrollTop / totalScroll) * 100;
        setWindowScrollPercent(scrollPercentage);

        // Calculate individual div scroll percentages based on container scroll percentage
        const sections = document.querySelectorAll('.scroll-section');
        let startScrollPercent = 0;

        const newScrollPercents = Array.from(sections).map((section) => {
            const sectionHeight = section.clientHeight;
            const sectionScrollPercent = (sectionHeight / scrollHeight) * 100;

            let divScrollPercent = 0;
            if (scrollPercentage >= startScrollPercent && scrollPercentage < startScrollPercent + sectionScrollPercent) {
                divScrollPercent = ((scrollPercentage - startScrollPercent) / sectionScrollPercent) * 100;
            } else if (scrollPercentage >= startScrollPercent + sectionScrollPercent) {
                divScrollPercent = 100; // Fully scrolled past the section
            }

            startScrollPercent += sectionScrollPercent;
            return divScrollPercent;
        });

        setDivScrollPercents(newScrollPercents);
    };

    // Add event listener for container scroll only after the animation completes
    useEffect(() => {
        if (animationComplete) {
            const container = containerRef.current;
            container.addEventListener('scroll', handleContainerScroll);

            return () => {
                container.removeEventListener('scroll', handleContainerScroll);
            };
        }
    }, [animationComplete]); // Only apply scrolling logic after animation is complete

    return (
        <div>
            {!animationComplete && (
                <div className="About container" ref={containerRef}>
                    <div
                        className="scroll-section"
                        style={{
                            height: "100vh",
                            width: "100%",
                            overflow: "hidden", // Hide scrollbars during animation
                        }}
                    >
                        <AboutComponentTitle onAnimationEnd={handleAboutAnimationEnd} />
                    </div>
                </div>
            )}

            {animationComplete && (
                <div className="About container" ref={containerRef} style={{ overflowY: "scroll" }}>
                    <Icon />
                    <HamburgerMenu />
                    <LeftSphere />
                    <RightSphere />
                    <ScrollArrow />
                    <div id="PhilosophyComponentDiv" className="scroll-section" style={{ height: "100vh" }}>
                        <PhilosophyComponent scrollPercentage={divScrollPercents[0]} />
                    </div>
                    <div id="ValueComponentDiv" className="scroll-section" style={{ height: "100vh" }}>
                        <ValueComponent scrollPercentage={divScrollPercents[1]} />
                    </div>
                    <div id="TeamComponentDiv" className="scroll-section" style={{ height: "100vh" }}>
                        <TeamComponent scrollPercentage={divScrollPercents[2]} />
                    </div>
                    <div id="WayComponentDiv" className="scroll-section" style={{ height: "400vh", position: "relative" }}>
                        <WayComponent containerHeight={400} scrollPercentage={divScrollPercents[3]} />
                    </div>
                    <div id="ContactComponent" className="scroll-section" style={{ height: "100vh" }}>
                        <ContactAboutComponent scrollPercentage={divScrollPercents[4]} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default About;
