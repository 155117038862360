import React, { useState, useEffect, useRef } from "react";
import "./valueComponent.css";
import Value1 from "../icons/Versatality.gif";
import Value2 from "../icons/People.gif";
import Value3 from "../icons/SecurityinEverything.gif";

function ValueComponent() {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [showValueHeader, setShowValueHeader] = useState(false); // Track when to show the header
    const [showCards, setShowCards] = useState(false); // Track when to show the cards
    const valueSectionRef = useRef(null); // Ref to track the section

    const images = [
        {
            src: Value1, header: "Versatility and Adaptability", text: <>
                At RAAH, we know the digital world never stops evolving, and neither do our clients' needs. Our solutions are flexible, robust, and built to scale with your business. Whether integrating with existing systems or addressing new security threats, we prioritize versatility to keep you ahead.
                <br /><br />We thrive on adapting swiftly, ensuring every solution remains relevant and effective.
            </>
        },
        {
            src: Value2, header: "People-First Culture", text: <>
                Our people drive our success. At RAAH, we foster collaboration, innovation, and personal growth, knowing that putting our workforce first unlocks their full potential. This people-first mindset extends to our clients and partners, building stronger relationships, trust, and better solutions.
                <br /><br />By prioritizing people, we create a culture of inclusion, respect, and continuous development.
            </>
        },
        {
            src: Value3, header: "Security in Everything", text: <>
                Security is at the core of everything we do at RAAH. In today’s digital world, it’s essential to embed security into every part of an organization. From identity management to data protection, we take a holistic approach to safeguarding your most valuable assets. Our solutions are built with top-tier security standards to mitigate both current and future threats.
                <br /><br />Beyond technology, our commitment to security is a culture of vigilance and responsibility, ensuring every layer of our service is fortified.
            </>
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setShowValueHeader(true); // Show header when in view
                    setTimeout(() => {
                        setShowCards(true); // Show cards after the header animation is done
                    }, 1000); // Adjust timing to match the duration of the header animation
                }
            },
            { threshold: 0.3 } // Trigger when 30% of the section is visible
        );
        if (valueSectionRef.current) {
            observer.observe(valueSectionRef.current);
        }
        return () => {
            if (valueSectionRef.current) {
                observer.unobserve(valueSectionRef.current);
            }
        };
    }, []);

    const handleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); // Toggle expand/collapse
    };

    return (
        <div className="valueComponentContainer" ref={valueSectionRef}>
            <div className={`valueComponentHeader ${showValueHeader ? "animate" : ""}`}>
                <h1>The <span className="highlightValue">RAAH</span> Values</h1>
            </div>
            {showCards && (
                <div className="valueCardWrapper">
                    {images.map((image, index) => {
                        const isExpanded = expandedIndex === index;

                        if (expandedIndex !== null && expandedIndex !== index) {
                            return null;
                        }

                        return (
                            <div
                                key={index}
                                className={`valueItem ${isExpanded ? "expandedCard" : ""} ${isExpanded ? "expanded" : "collapsed"}`}
                            >
                                <div className="valueCard">
                                    <div className={`valueCardFront ${isExpanded ? "expandedCard" : ""}`}>
                                        <img
                                            src={image.src}
                                            alt={`tech-image-${index}`}
                                            className="sliderValueImage"
                                        />
                                        <div className="cardContent">
                                            <h2>{image.header}</h2>
                                            {isExpanded && (
                                                <div className="descriptionContainer">
                                                    <p>{image.text}</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="expandButton" onClick={() => handleExpand(index)}>
                                            {isExpanded ? "←" : "→"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );

}

export default ValueComponent;
