// CrossOrgCollaborationContent.js

import React from 'react';
import { FaProjectDiagram, FaHandshake, FaUsers } from 'react-icons/fa';

const CrossOrgCollaborationContent = () => (
  <>
    <h3 className="modal-main-title">
      Cross-Organization Collaboration (B2B and B2B2C CIAM)
    </h3>
    <p>
      Cross-Organization Collaboration is an important feature of Customer
      Identity and Access Management (CIAM) for B2B and B2B2C contexts. It
      enables secure interactions and data sharing between different
      organizations, partners, and end customers, fostering a collaborative
      ecosystem while ensuring security, compliance, and effective access
      control.
    </p>

    <h4 className="modal-subheading">
      <FaProjectDiagram size={30} style={{ marginRight: '10px' }} />
      Benefits of Cross-Organization Collaboration
    </h4>
    <p>
      Implementing Cross-Organization Collaboration helps organizations build
      partnerships, improve operational efficiency, and provide seamless access
      to shared resources while maintaining security.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Seamless Partner Integration:</strong> Enable partners and
        third-party organizations to access necessary resources easily and
        securely, improving collaboration.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Maintain strict access controls and
        audit trails to ensure that only authorized users have access to shared
        resources, reducing the risk of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that data sharing between
        organizations complies with regulations like GDPR and CCPA, protecting
        sensitive information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaHandshake size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Cross-Organization Collaboration
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that facilitate
      Cross-Organization Collaboration for B2B and B2B2C use cases. Our
      solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Federated Identity Management:</strong> Implement federated
        identity solutions to enable seamless authentication and access for
        users from partner organizations, ensuring secure collaboration.
      </li>
      <li className="modal-list-item">
        <strong>Granular Access Controls:</strong> Define and enforce access
        controls to ensure that partners and third-party users have only the
        access they need to perform their roles.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Compliance Monitoring:</strong> Track and monitor all
        cross-organization interactions to ensure compliance with data
        protection regulations and maintain security.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Use Case: Enabling Collaboration for a B2B2C Supply Chain Network
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B2C supply chain network needed to enable
      secure collaboration between suppliers, partners, and end customers, while
      ensuring that sensitive information was only accessible to authorized
      users.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a
      Cross-Organization Collaboration solution that utilized federated identity
      management, granular access controls, and compliance monitoring to
      facilitate secure interactions between all stakeholders.
    </p>
    <p>
      <strong>Result:</strong> The supply chain network achieved seamless
      collaboration between partners and customers, improved operational
      efficiency, and ensured compliance with data protection regulations,
      resulting in enhanced trust and stronger partnerships.
    </p>
  </>
);

export default CrossOrgCollaborationContent;
