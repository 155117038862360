// APISecurityContent.js

import React from 'react';
import { FaShieldAlt, FaKey, FaCloud } from 'react-icons/fa';

const APISecurityContent = () => (
  <>
    <h3 className="modal-main-title">API Security and Access Control</h3>
    <p>
      API Security and Access Control are critical components of Customer
      Identity and Access Management (CIAM), especially for B2C and B2B
      scenarios. Securing APIs ensures that sensitive customer and partner data
      is protected while enabling seamless integration between systems and
      services.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Benefits of API Security and Access Control
    </h4>
    <p>
      Implementing API security and access control helps organizations protect
      sensitive data, ensure compliance with regulations, and maintain the
      integrity of their digital services.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Data Protection:</strong> Secure APIs help protect sensitive
        customer and partner data from unauthorized access and breaches,
        ensuring data integrity.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that API access and data sharing
        comply with regulations like GDPR and CCPA, including proper
        authentication and authorization mechanisms.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Integration:</strong> Secure APIs enable seamless and
        secure integration between different systems, ensuring that data is
        shared safely across platforms.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports API Security and Access Control
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers comprehensive CIAM solutions
      that include API security and access control for both B2C and B2B use
      cases. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>OAuth and OpenID Connect:</strong> Implement industry-standard
        protocols like OAuth 2.0 and OpenID Connect to secure API access and
        ensure that only authorized users can access sensitive data.
      </li>
      <li className="modal-list-item">
        <strong>API Gateway Integration:</strong> Integrate with API gateways to
        enforce security policies, monitor API usage, and manage access control
        effectively.
      </li>
      <li className="modal-list-item">
        <strong>Access Control Policies:</strong> Define and enforce access
        control policies to ensure that APIs are only accessible by authorized
        users and applications.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCloud size={30} style={{ marginRight: '10px' }} />
      Use Case: Securing API Access for a Financial Services Platform
    </h4>
    <p>
      <strong>Challenge:</strong> A financial services company needed to secure
      its APIs to protect sensitive customer data while enabling integration
      with partner systems and ensuring compliance with GDPR.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an API security
      solution that utilized OAuth 2.0 for secure authentication, integrated
      with an API gateway for access control, and enforced detailed access
      control policies.
    </p>
    <p>
      <strong>Result:</strong> The financial platform improved its security
      posture, ensured compliance with GDPR, and enabled secure integration with
      partner systems, leading to better service delivery and customer trust.
    </p>
  </>
);

export default APISecurityContent;
