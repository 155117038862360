import React, { useEffect, useRef, useState } from 'react';
import './InfiniteScrollDiv.css';

const InfiniteScrollDiv = () => {
    const scrollContainerRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollSpeed = 7; // Speed of scrolling (adjust as needed)
  
    // Function to handle automatic scrolling
    const autoScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.scrollTop += 1;
  
        // Reset the scroll position if it reaches the bottom
        if (scrollContainer.scrollTop >= scrollContainer.scrollHeight - scrollContainer.clientHeight) {
          // scrollContainer.scrollTop = 0;
        }
      }
    };

    useEffect(() => {
      // Ensure autoScroll and scrollSpeed are defined
      if (!autoScroll || !scrollSpeed) return;
  
      // Set a timeout for 1 second before starting the interval
      const startDelay = setTimeout(() => {
        // Set an interval to automatically scroll
        const scrollInterval = setInterval(autoScroll, scrollSpeed);
  
        // Cleanup the interval on component unmount
        return () => clearInterval(scrollInterval);
      }, 1000); // 1 second delay
  
      // Cleanup the timeout if the component unmounts before the delay
      return () => clearTimeout(startDelay);
    }, [autoScroll, scrollSpeed]); // Add autoScroll and scrollSpeed as dependencies
  
    useEffect(() => {
      const paragraphs = document.querySelectorAll('.scroll-item');
      const observerOptions = {
        root: scrollContainerRef.current,
        threshold: 0.6, // Trigger when 60% of the paragraph is visible
      };
  
      // Observer callback to track the visibility of paragraphs
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveIndex(parseInt(entry.target.dataset.index));
          }
        });
      }, observerOptions);
  
      // Attach observer to each paragraph
      paragraphs.forEach((paragraph) => observer.observe(paragraph));
  
      // Cleanup on component unmount
      return () => {
        paragraphs.forEach((paragraph) => observer.unobserve(paragraph));
      };
    }, []);

  return (
    <div
      ref={scrollContainerRef}
      className='TitleWordListComponent'
      style={{
        overflowY: 'scroll',
        height: '70px',
        textAlign: 'right'
        // width: '500px',
        // border: '1px solid black',
      }}
    >
      <div className='TitleWordList'>
        <p data-index="0" className={`scroll-item ${activeIndex === 0 ? 'active' : ''}`}>Governance</p>
        <p data-index="1" className={`scroll-item ${activeIndex === 1 ? 'active' : ''}`}>Compliance</p>
        <p data-index="2" className={`scroll-item ${activeIndex === 2 ? 'active' : ''}`}>RBAC/ABAC</p>
        <p data-index="3" className={`scroll-item ${activeIndex === 3 ? 'active' : ''}`}>Provisioning</p>
        <p data-index="4" className={`scroll-item ${activeIndex === 4 ? 'active' : ''}`}>Delegation</p>
        <p data-index="5" className={`scroll-item ${activeIndex === 5 ? 'active' : ''}`}>AuthN/AuthZ</p>
        <p data-index="6" className={`scroll-item ${activeIndex === 6 ? 'active' : ''}`}>Identity</p>
      </div>
    </div>
  );
};

export default InfiniteScrollDiv;
