import React, { useState, useEffect } from "react";
import "./ServicesComponent4.css"; // Update the CSS import
import Services1 from "../icons/Total.gif";
import Services2 from "../icons/Total.gif";
import Services3 from "../icons/Total.gif";
import Services4 from "../icons/Total.gif";
import Services5 from "../icons/Total.gif";

function ServicesComponent4({ scrollPercentage }) {
  const [active, setActive] = useState(0);

  const phases = [
    {
      src: Services1, header: "Workforce Identity Managed Services", text: <>
        <span className="highlightServices4">Efficient workforce access, managed securely.</span>
        <br /><br />Manage your workforce identity infrastructure with ease, ensuring secure access to necessary tools.
      </>
    },
    {
      src: Services2, header: "Customer Identity and Access Managed Services (CIAM)", text: <>
        <span className="highlightServices4">Secure, optimized customer experiences.</span>
        <br /><br />Keep customer identity management secure and optimized, focusing on delivering a great experience.
      </>
    },
    {
      src: Services3, header: "Partner Identity Managed Services", text: <>
        <span className="highlightServices4">Smooth, secure partner identity management.</span>
        <br /><br />Maintain secure partner identity solutions, ensuring compliance and seamless integration.
      </>
    },
    {
      src: Services4, header: "Privileged Access Managed Services (PAM)", text: <>
        <span className="highlightServices4">Secure privileged access, mitigate risk.</span>
        <br /><br />Protect critical systems with controlled, monitored, and audited privileged access.
      </>
    },
    {
      src: Services5, header: "Total Managed Identity Services Package", text: <>
        <span className="highlightServices4">Complete identity management solution.</span>
        <br /><br />Manage all your identity needs in one comprehensive package, covering workforce, customer, partner, and privileged access.
      </>
    },
  ];

  useEffect(() => {
    const numberOfPhases = phases.length;
    const phaseThreshold = 100 / numberOfPhases;

    phases.forEach((_, index) => {
      if (scrollPercentage >= index * phaseThreshold && scrollPercentage < (index + 1) * phaseThreshold) {
        setActive(index);
      }
    });
  }, [scrollPercentage, phases]);

  return (
    <div className='ServicesComponent4Container'>
      <div className='ServicesComponent4Text'>
        <h1>Managed Services</h1>
      </div>
      <div className="services4ContentWrapper">
        <div className="services4ImageWrapper">
          <img
            src={phases[active].src}
            alt={phases[active].header}
            className="services4Image"
          />
          <h2 className="services4Header">{phases[active].header}</h2>
        </div>
        <div className="services4TextWrapper">
          <p>{phases[active].text}</p>
        </div>
      </div>
    </div>
  )
}

export default ServicesComponent4;
