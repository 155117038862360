import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RightSphere.css'

function RightSphere() {
    const navigate = useNavigate();
    return (
        <div className='right-outer-circle'>
            <div className='right-inner-circle'> </div>
        </div>
    );
}

export default RightSphere;