import React from 'react';
import {
  FaUsers,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const WSO2Content = () => (
  <>
    <h3 className="modal-main-title">
      Driving Digital Transformation with WSO2 Identity Server
    </h3>
    <p>
      WSO2 Identity Server is a powerful, open-source Identity and Access
      Management (IAM) solution designed to provide Single Sign-On (SSO),
      identity federation, and API security. At{' '}
      <strong>RAAH Technologies</strong>, we leverage WSO2 Identity Server to
      help organizations securely manage identities, enable seamless user
      experiences, and accelerate their digital transformation journeys.
    </p>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      WSO2 Identity Server: Key Capabilities
    </h4>
    <p>
      WSO2 Identity Server offers a wide range of capabilities that help
      organizations manage identities efficiently, secure applications, and
      ensure compliance. Key features include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enable users to access multiple
        applications with a single login, reducing password fatigue and
        improving the overall user experience.
      </li>
      <li className="modal-list-item">
        <strong>Identity Federation:</strong> Federate identities across
        different systems and domains, providing a unified experience for users
        across multiple platforms.
      </li>
      <li className="modal-list-item">
        <strong>OAuth2 and OpenID Connect Support:</strong> Securely manage
        access to APIs and services using industry-standard protocols like
        OAuth2 and OpenID Connect.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Authentication:</strong> Implement context-aware
        authentication policies to balance security and user experience.
      </li>
      <li className="modal-list-item">
        <strong>Access Management:</strong> Control access to resources using
        fine-grained policies, ensuring that only authorized users can access
        sensitive information.
      </li>
    </ul>
    <p>
      With WSO2 Identity Server, organizations can streamline identity
      management, secure access to applications, and provide seamless user
      experiences. RAAH's expertise in deploying WSO2 solutions ensures that
      businesses can fully leverage the platform to meet their unique identity
      and access needs.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Enhancing Security for a Telecommunications Provider
    </h4>
    <p>
      <strong>Challenge:</strong> A telecommunications provider needed to enable
      secure access to multiple customer-facing and internal applications, while
      providing a seamless experience for both employees and customers.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented WSO2 Identity
      Server to provide Single Sign-On (SSO) across all applications, along with
      identity federation to unify user experiences. Adaptive authentication was
      used to add an extra layer of security based on user behavior and risk
      factors.
    </p>
    <p>
      <strong>Result:</strong> The provider experienced a{' '}
      <strong>20% reduction</strong> in password-related support requests, while
      improving the overall security posture and providing a seamless user
      experience across applications.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced User Experience:</strong> Provide seamless access to
        applications with Single Sign-On (SSO) and federated identity
        capabilities.
      </li>
      <li className="modal-list-item">
        <strong>Increased Security:</strong> Leverage adaptive authentication
        and fine-grained access controls to protect sensitive information.
      </li>
      <li className="modal-list-item">
        <strong>Scalability:</strong> WSO2's open-source platform is highly
        scalable, making it suitable for organizations of all sizes.
      </li>
      <li className="modal-list-item">
        <strong>Open Standards:</strong> Utilize industry-standard protocols
        like OAuth2, SAML, and OpenID Connect for secure identity and access
        management.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we combine our deep expertise in
      identity and access management with our knowledge of WSO2 Identity Server
      to deliver tailored solutions for your organization. Our services include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Implementing WSO2 Identity Server to address your specific identity
        management and access control needs.
      </li>
      <li className="modal-list-item">
        Integrating WSO2 with other systems, including CRM, ERP, and custom
        applications, to create a seamless identity management environment.
      </li>
      <li className="modal-list-item">
        Providing ongoing support and optimization to ensure your identity
        solution evolves with your business needs.
      </li>
      <li className="modal-list-item">
        Delivering training and best practices to empower your team to manage
        identities effectively.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://is.docs.wso2.com/en/latest/"
          target="_blank"
          rel="noopener noreferrer"
        >
          WSO2 Identity Server Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://wso2.com/library/"
          target="_blank"
          rel="noopener noreferrer"
        >
          WSO2 Library
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/wso2"
          target="_blank"
          rel="noopener noreferrer"
        >
          WSO2 GitHub Repository
        </a>
      </li>
    </ul>
  </>
);

export default WSO2Content;
