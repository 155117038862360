// PasswordlessAuthenticationContent.js

import React from 'react';
import { FaFingerprint, FaKey, FaMobileAlt } from 'react-icons/fa';

const PasswordlessAuthenticationContent = () => (
  <>
    <h3 className="modal-main-title">
      Passwordless Authentication for Workforce
    </h3>
    <p>
      Passwordless Authentication is a modern security solution that eliminates
      the need for traditional passwords, offering a more secure and
      user-friendly authentication experience. By using biometrics, security
      tokens, or other secure methods, Passwordless Authentication reduces the
      risk of credential theft and phishing attacks.
    </p>

    <h4 className="modal-subheading">
      <FaFingerprint size={30} style={{ marginRight: '10px' }} />
      Benefits of Passwordless Authentication
    </h4>
    <p>
      Implementing Passwordless Authentication helps organizations enhance
      security while improving the user experience by removing the need for
      complex passwords.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved Security:</strong> By eliminating passwords, the risk
        of phishing, credential stuffing, and brute force attacks is
        significantly reduced.
      </li>
      <li className="modal-list-item">
        <strong>Better User Experience:</strong> Users no longer need to
        remember or manage complex passwords, resulting in a seamless login
        experience.
      </li>
      <li className="modal-list-item">
        <strong>Reduced IT Overhead:</strong> Password-related support requests,
        such as password resets, are minimized, reducing the burden on IT
        support teams.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Passwordless Authentication
    </h4>
    <p>
      <strong>RAAH Technologies</strong> helps organizations implement
      Passwordless Authentication solutions that fit their specific needs. Our
      solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Biometric Authentication:</strong> We enable biometric methods
        such as fingerprint and facial recognition to provide secure,
        password-free access.
      </li>
      <li className="modal-list-item">
        <strong>Hardware Tokens and Mobile Devices:</strong> Implement hardware
        security keys and mobile push notifications as secure alternatives to
        passwords.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Authentication:</strong> Use context-aware, risk-based
        analysis to prompt for additional verification only when necessary,
        balancing security and convenience.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaMobileAlt size={30} style={{ marginRight: '10px' }} />
      Use Case: Enhancing Security for Remote Employees
    </h4>
    <p>
      <strong>Challenge:</strong> A tech company needed to enhance security for
      its remote employees while reducing the friction caused by frequent
      password resets and complex password policies.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Passwordless
      Authentication solution using biometric verification and hardware tokens,
      providing secure and seamless access to corporate resources.
    </p>
    <p>
      <strong>Result:</strong> The company experienced a significant reduction
      in password- related IT support tickets, improved employee satisfaction,
      and enhanced overall security for remote access.
    </p>
  </>
);

export default PasswordlessAuthenticationContent;
