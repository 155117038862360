import React from 'react';
import './KeysComponent.css'; // Assuming you are using an external CSS file
import Arrow from './icons/arrow.svg';
import Eye from './icons/Eye.gif';
import Process from './icons/Process.gif';
import Trust from './icons/Trust.gif';

const KeysComponent = ({ scrollPercentage }) => {
  // Determine the right side text based on scroll percentage
  const getHeaderContent = () => {
    if (scrollPercentage >= 0 && scrollPercentage < 100 / 3) {
      return (
        <p className="keys-right-header">
          <span className="highlightThinking">Identity</span> in EVERYTHING
        </p>
      );
    } else if (scrollPercentage >= 100 / 3 && scrollPercentage < 200 / 3) {
      return <p className="keys-right-header">Trust but VERIFY</p>;
    } else if (scrollPercentage >= 200 / 3 && scrollPercentage < 100) {
      return <p className="keys-right-header">Process, Process, Process</p>;
    } else {
      return <p className="keys-right-header">Process, Process, Process</p>;
    }
  };

  // Determine the right side text based on scroll percentage
  const getTextContent = () => {
    if (scrollPercentage >= 0 && scrollPercentage < 100 / 3) {
      return (
        <p className="keys-text">
          Customers, employees and partners rely on secure Identity and Access
          Management to run their businesses. Without it, operations, processes
          and information access grind to a halt.{' '}
          <span className="highlightText-keys">RAAH</span> provides Identity
          Solutions that are central to business operations.
        </p>
      );
    } else if (scrollPercentage >= 100 / 3 && scrollPercentage < 200 / 3) {
      return (
        <p className="keys-text">
          <span className="highlightText-keys">RAAH</span> orchestrates Identity
          in client environments that accounts for 360 Degree visibilty and
          control that puts the power in your users' hands and keeps that power
          in check. We build clear, auditable, and secure processes to support
          Enterprise Users and their Identities.
        </p>
      );
    } else if (scrollPercentage >= 200 / 3 && scrollPercentage < 100) {
      return (
        <p className="keys-text">
          Every Client is unique. We emphasize the importance of aligning IT,
          Support, and the business from top-down, and architect{' '}
          <span className="highlightText-keys">identity</span> solutions that
          eliminate process gaps that cause slowdowns in workforce productivity
          and inefficiencies in CX and UX.
        </p>
      );
    } else {
      return (
        <p className="keys-text">
          Every Client is unique. We emphasize the importance of aligning IT,
          Support, and the business from top-down, and architect{' '}
          <span className="highlightThinking">identity</span> solutions that
          eliminate process gaps that cause slowdowns in workforce productivity
          and inefficiencies in CX and UX.
        </p>
      );
    }
  };

  // Determine the right side text based on scroll percentage
  const getArrowContent = () => {
    if (scrollPercentage >= 0 && scrollPercentage < 100 / 3) {
      return (
        <div className="keys-arrow">
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={50}
            height={50}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ opacity: 0 }}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ margin: 0, opacity: 0 }}
          />
        </div>
      );
    } else if (scrollPercentage >= 100 / 3 && scrollPercentage < 200 / 3) {
      return (
        <div className="keys-arrow">
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ opacity: 0 }}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={50}
            height={50}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ margin: 0, opacity: 0 }}
          />
        </div>
      );
    } else if (scrollPercentage >= 200 / 3 && scrollPercentage < 100) {
      return (
        <div className="keys-arrow">
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ opacity: 0 }}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ opacity: 0 }}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={50}
            height={50}
            style={{ margin: 0 }}
          />
        </div>
      );
    } else {
      return (
        <div className="keys-arrow">
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ opacity: 0 }}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ opacity: 0 }}
          />
          <img
            className="arrow"
            alt="arrow"
            src={Arrow}
            width={70}
            height={70}
            style={{ margin: 0 }}
          />
        </div>
      );
    }
  };

  return (
    <div className="keys-container" id="KeysComponentDiv">
      <div className="keys-left">
        <h1 className="keys-header">
          3 Keys to Building Strong <span className="highlightText-keys">IAM</span> Programs
        </h1>
        <div className="keys-icons">
          <img
            className="eyeball"
            alt="eyeball"
            src={Eye}
            width={70}
            height={70}
          />
          <img
            className="verified"
            alt="verified"
            src={Trust}
            width={70}
            height={70}
          />
          <img
            className="process"
            alt="process"
            src={Process}
            width={70}
            height={70}
          />
        </div>
        {getArrowContent()}
      </div>
      <div className="keys-right">
        <div className="keys-right-content-holder">
          <h1>{getHeaderContent()}</h1>
          <p className="keys-text">{getTextContent()}</p>
        </div>
      </div>
    </div>
  );
};

export default KeysComponent;
