import React, { useEffect } from 'react';
import './aboutComponentTitle.css';

const AboutComponentTitle = ({ onAnimationEnd }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onAnimationEnd();
        }, 2000); // Match the animation duration

        return () => clearTimeout(timer);
    }, [onAnimationEnd]);

    return (
        <div className="aboutComponentTitle">
            <h1>About <span className="highlightAbout">RAAH</span></h1>
        </div>
    );
};

export default AboutComponentTitle;
