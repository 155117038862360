import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import EnterScreenComponent from './EnterScreenComponent';
import IntroScreenComponent from './IntroScreenComponent';
import AboutComponent from '../aboutComponents/About';
import App from '../../App'; // This is your main app component
import ContactPage from '../contactComponents/ContactPage';
import SolutionsPage from '../solutionsComponents/SolutionsPage';
import ServicesPage from '../servicesComponents/ServicesPage';
import ContactForm from '../contactComponents/ContactForm';
import NewsComponent from '../newsComponents/News';

function Navigation() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EnterScreenComponent />} />
        <Route path="/intro" element={<IntroScreenComponent />} />
        <Route path="/home" element={<App />} />
        <Route path="/about" element={<AboutComponent />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/solutions" element={<SolutionsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path='/news' element={<NewsComponent />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default Navigation;
