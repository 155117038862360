import React from 'react';
import Question from '../icons/QuestionMarkBubbleDarkTheme.gif';
import './App.css';

const H1 = () => {
  return (
    <div className="header-tile question-mark-dark-holder">
      <img
        src={Question}
        alt="A representation of the Identity world"
        className="question-mark-dark-gif"
        key={Date.now()}
      />
 
      <span className="font-link">
        <h1>
          How does <span className="highlight">RAAH</span>
        </h1>
        <h1> address Identity?</h1>
      </span>
    </div>
  );
};

export default H1;
