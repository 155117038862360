// DelegatedAdministrationContent.js

import React from 'react';
import { FaUserShield, FaUsersCog, FaKey } from 'react-icons/fa';

const DelegatedAdministrationContent = () => (
  <>
    <h3 className="modal-main-title">
      Delegated Administration (B2B and B2B2C CIAM)
    </h3>
    <p>
      Delegated Administration is a critical feature of Customer Identity and
      Access Management (CIAM) for B2B and B2B2C use cases. It allows
      organizations to delegate administrative tasks to specific users or roles,
      ensuring that partners and third-party stakeholders can manage their own
      resources without compromising overall system security. This approach
      improves efficiency and provides flexibility while maintaining control.
    </p>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      Benefits of Delegated Administration
    </h4>
    <p>
      Implementing Delegated Administration helps organizations improve
      collaboration, reduce administrative overhead, and maintain security and
      compliance.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved Efficiency:</strong> Delegate administrative
        responsibilities to partners or designated users, allowing them to
        manage access, roles, and other resources, reducing the burden on
        central IT teams.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Control:</strong> Maintain overall control by defining
        the scope of delegated tasks, ensuring that delegated administrators can
        only manage specific resources.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that delegated administrative
        actions comply with regulations like GDPR and CCPA, protecting sensitive
        information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUsersCog size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Delegated Administration
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers CIAM solutions that include
      Delegated Administration for both B2B and B2B2C use cases. Our solutions
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Role-Based Delegation:</strong> Define roles that can be
        assigned delegated administrative rights, allowing partners or
        designated users to manage their own resources while maintaining
        security.
      </li>
      <li className="modal-list-item">
        <strong>Granular Access Controls:</strong> Implement granular access
        controls to define the scope of delegated tasks, ensuring that delegated
        administrators have the appropriate level of access.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Monitoring:</strong> Track and monitor all delegated
        administrative activities to ensure compliance with data protection
        regulations and maintain accountability.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      Use Case: Enabling Delegated Administration for a B2B2C Service Platform
    </h4>
    <p>
      <strong>Challenge:</strong> A B2B2C service platform needed to delegate
      administrative responsibilities to partners to allow them to manage their
      own users and resources, while maintaining control and security.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Delegated
      Administration solution that allowed partners to manage their own
      resources through role-based delegation and granular access controls,
      ensuring that delegated tasks were performed securely.
    </p>
    <p>
      <strong>Result:</strong> The service platform improved operational
      efficiency, reduced administrative overhead, and ensured compliance with
      data protection regulations, resulting in better partner relationships and
      enhanced trust.
    </p>
  </>
);

export default DelegatedAdministrationContent;
