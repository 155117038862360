import React from 'react';
import {
  FaIndustry,
  FaShieldAlt,
  FaTruck,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const ManufacturingContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing Manufacturing and Supply Chain with Robust Identity Solutions
    </h3>
    <p>
      The manufacturing and supply chain sectors are critical to the global
      economy, and ensuring secure and efficient operations is essential. At{' '}
      <strong>RAAH Technologies</strong>, we provide tailored identity and
      access management solutions that protect intellectual property, streamline
      operations, and enhance supply chain security.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in Manufacturing and Supply Chain
    </h4>
    <p>
      The manufacturing and supply chain industries face several challenges,
      such as safeguarding intellectual property, managing secure access to
      production systems, and ensuring the integrity of the supply chain. Our
      identity solutions address these challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing access for employees and
        contractors, ensuring compliance with industry standards.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities for workers across different
        facilities, ensuring appropriate access rights and providing audit
        trails.
      </li>
      <li className="modal-list-item">
        <strong>CIAM (B2B):</strong> Providing partners and suppliers with
        secure access to supply chain systems, ensuring collaboration and
        efficiency.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that access
        critical production and supply chain systems, mitigating risks of
        insider threats and unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for external partners and
        suppliers, ensuring secure integration across the supply chain.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTruck size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Manufacturing and Supply Chain
    </h4>
    <p>
      Our customized identity solutions for the manufacturing and supply chain
      sector include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect access to production systems and supply
        chain data, safeguarding against unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling workers to use a single
        set of credentials to access multiple systems, improving efficiency and
        reducing login fatigue.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that workers
        have access to the systems and data they need based on their roles and
        responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user identities
        throughout their employment lifecycle, ensuring compliance with
        regulations and industry standards.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing access to
        critical production systems, ensuring that only authorized personnel
        have control over sensitive operations.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Global Manufacturing Company
    </h4>
    <p>
      <strong>Challenge:</strong> A global manufacturing company needed to
      protect its intellectual property while ensuring secure access to
      production systems and enhancing collaboration with suppliers.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided a comprehensive
      identity solution, including Single Sign-On (SSO) and Multi-Factor
      Authentication (MFA) for secure access. Role-Based Access Control (RBAC)
      was implemented to ensure appropriate access based on job
      responsibilities, and Privileged Access Management (PAM) protected
      critical production systems.
    </p>
    <p>
      <strong>Result:</strong> The manufacturing company experienced a{' '}
      <strong>25% reduction</strong> in unauthorized access attempts, improved
      supply chain collaboration, and enhanced the security of its intellectual
      property.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Protecting intellectual property and
        production systems with MFA and PAM, ensuring privacy and security.
      </li>
      <li className="modal-list-item">
        <strong>Improved Operational Efficiency:</strong> Streamlined access for
        workers with SSO and RBAC, reducing administrative burden and enhancing
        productivity.
      </li>
      <li className="modal-list-item">
        <strong>Supply Chain Security:</strong> Secure interactions with
        partners and suppliers through effective identity management, ensuring
        the integrity of the supply chain.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to industry
        standards, reducing the risk of penalties and breaches.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As the manufacturing and supply chain sectors continue to evolve, secure
      identity solutions are crucial to protecting intellectual property,
      enhancing operational efficiency, and ensuring supply chain integrity. At{' '}
      <strong>RAAH Technologies</strong>, we are committed to providing advanced
      IAM, IGA, CIAM, and PAM solutions that meet the complex needs of
      manufacturers and their supply chain partners.
    </p>
  </>
);

export default ManufacturingContent;
