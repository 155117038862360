import React from 'react';
import {
  FaNetworkWired,
  FaShieldAlt,
  FaClipboardList,
  FaLightbulb,
  FaTools,
  FaCode,
} from 'react-icons/fa';

const PingContent = () => (
  <>
    <h3 className="modal-main-title">
      Unlocking Identity Potential with Ping Identity & ForgeRock Solutions
    </h3>
    <p>
      Ping Identity and ForgeRock offer powerful identity and access management
      solutions for enterprises seeking secure, scalable, and seamless identity
      experiences. At <strong>RAAH Technologies</strong>, we bring the expertise
      required to implement and optimize these platforms to solve identity
      challenges for workforce, customer, and partner ecosystems.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Ping Identity: Delivering Seamless Workforce and Customer Identity
    </h4>
    <p>
      Ping Identity provides a suite of tools that allow organizations to manage
      and secure digital identities, supporting both workforce and customer use
      cases. Key features include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Unified access to enterprise
        applications for employees, partners, and customers with a frictionless
        experience.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Multi-Factor Authentication (MFA):</strong> Leverages
        machine learning to determine when additional authentication factors are
        required, enhancing security while preserving user convenience.
      </li>
      <li className="modal-list-item">
        <strong>Federation Services:</strong> Seamlessly integrates with other
        identity providers, allowing secure and smooth access across
        organizational boundaries.
      </li>
      <li className="modal-list-item">
        <strong>API Security:</strong> Protects APIs using OAuth, OpenID
        Connect, and other security protocols, ensuring that data exchanges are
        secure.
      </li>
      <li className="modal-list-item">
        <strong>Identity Governance:</strong> Provides features for
        provisioning, access management, and role enforcement, allowing
        organizations to enforce secure access policies.
      </li>
    </ul>
    <p>
      Our deep experience with Ping Identity helps organizations create
      efficient identity environments that cater to both workforce productivity
      and a superior customer experience. We implement Ping solutions that are
      scalable, flexible, and perfectly aligned to meet business needs.
    </p>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      ForgeRock: Empowering Digital Transformation with Identity
    </h4>
    <p>
      ForgeRock is a full-stack identity and access management platform designed
      to support enterprises through complex digital transformations. With
      ForgeRock, we enable companies to secure access for employees, customers,
      and things across digital touchpoints. Features include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Automates provisioning,
        deprovisioning, and identity lifecycle events, ensuring users always
        have the right access.
      </li>
      <li className="modal-list-item">
        <strong>Advanced Identity Federation:</strong> Connects with other
        identity systems to enable single identity access across multiple
        applications and organizations.
      </li>
      <li className="modal-list-item">
        <strong>Customer Identity & Access Management (CIAM):</strong> Provides
        customizable registration, authentication, and self-service features to
        create a secure, seamless customer experience.
      </li>
      <li className="modal-list-item">
        <strong>Intelligent Access Trees:</strong> Configures complex
        authentication workflows visually, allowing businesses to customize
        access paths for customers and employees.
      </li>
      <li className="modal-list-item">
        <strong>Internet of Things (IoT) Integration:</strong> Manages
        identities for devices, allowing companies to secure interactions with
        IoT solutions.
      </li>
    </ul>
    <p>
      With <strong>RAAH Technologies</strong>, ForgeRock solutions are deployed
      with precision, tailored to fit the needs of enterprises and public sector
      organizations looking to manage complex identity challenges. We help our
      clients make the most out of ForgeRock's identity orchestration and
      advanced capabilities.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Transforming a Telecom Company's Identity Landscape
    </h4>
    <p>
      <strong>Challenge:</strong> A leading telecom company needed an identity
      solution to unify workforce access and enhance customer engagement while
      protecting customer data.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Ping Identity for
      workforce identity, using SSO and Adaptive MFA to streamline employee
      access while increasing security. ForgeRock was used for customer identity
      management, with CIAM capabilities enabling personalized onboarding and
      Intelligent Access Trees to reduce friction during authentication.
      Integration with the company’s existing systems ensured seamless
      operation.
    </p>
    <p>
      <strong>Result:</strong> The telecom company reported a{' '}
      <strong>50% reduction</strong> in onboarding time for customers, improved
      customer satisfaction scores, and increased security across their
      workforce.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Comprehensive Identity Security:</strong> Secure both workforce
        and customer identities using best-in-class tools that leverage SSO,
        MFA, and API security protocols.
      </li>
      <li className="modal-list-item">
        <strong>Seamless Customer Journeys:</strong> CIAM solutions from
        ForgeRock provide a frictionless experience for customers, enhancing
        engagement and loyalty.
      </li>
      <li className="modal-list-item">
        <strong>Scalability for Growth:</strong> Both Ping Identity and
        ForgeRock are built for enterprises with scalability in mind, enabling
        organizations to grow without limitations on identity.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive and Intelligent Access:</strong> Use AI-powered
        decisions for dynamic authentication based on risk, device, and user
        behavior.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaNetworkWired size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we bring a holistic approach to
      deploying Ping Identity and ForgeRock solutions. Our expertise extends
      from technical implementation to understanding business goals and ensuring
      identity solutions align with those goals.
    </p>
    <p>We focus on delivering tailored solutions by:</p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Providing comprehensive assessments to understand organizational
        identity needs and challenges.
      </li>
      <li className="modal-list-item">
        Offering strategic guidance on identity architecture to ensure
        scalability and business alignment.
      </li>
      <li className="modal-list-item">
        Seamlessly integrating Ping and ForgeRock platforms with other systems,
        maximizing interoperability and enhancing the overall ecosystem.
      </li>
      <li className="modal-list-item">
        Supporting businesses beyond the initial implementation with training,
        ongoing maintenance, and optimization to adapt to evolving requirements.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://docs.pingidentity.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ping Identity Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://www.pingidentity.com/en/resources.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ping Identity Resources
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://backstage.forgerock.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ForgeRock Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/PingIdentity"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ping Identity GitHub Repository
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/ForgeRock"
          target="_blank"
          rel="noopener noreferrer"
        >
          ForgeRock GitHub Repository
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://support.pingidentity.com/s/community-home"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ping Community
        </a>
      </li>
    </ul>
  </>
);

export default PingContent;
