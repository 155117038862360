import React from 'react';
import {
  FaUsers,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const ServiceNowContent = () => (
  <>
    <h3 className="modal-main-title">
      ServiceNow: Streamlining Identity Workflows and IT Service Management
    </h3>
    <p>
      ServiceNow is a leading IT Service Management (ITSM) platform that
      integrates seamlessly with identity management solutions to provide
      automated workflows and improve overall IT efficiency. At{' '}
      <strong>RAAH Technologies</strong>, we utilize our expertise in ServiceNow
      to deliver solutions that simplify identity lifecycle management, enable
      self-service, and ensure a secure IT environment.
    </p>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Key Capabilities of ServiceNow
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Automate user
        provisioning, deprovisioning, and access requests using ServiceNow
        workflows integrated with IAM systems.
      </li>
      <li className="modal-list-item">
        <strong>Access Certification:</strong> Ensure compliance by automating
        periodic access reviews and certification campaigns.
      </li>
      <li className="modal-list-item">
        <strong>Incident Management:</strong> Efficiently handle
        identity-related incidents, from password resets to access denial
        troubleshooting.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Automating Identity Operations
    </h4>
    <p>
      <strong>Challenge:</strong> A large enterprise needed to automate identity
      operations to reduce manual efforts and errors in access provisioning.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented ServiceNow to
      handle identity lifecycle events, such as new hire onboarding, using
      ServiceNow’s workflow automation capabilities integrated with their IAM
      system.
    </p>
    <p>
      <strong>Result:</strong> The enterprise saw a{' '}
      <strong>40% reduction</strong> in manual identity-related tasks and faster
      onboarding for new employees.
    </p>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we bring extensive experience in
      integrating ServiceNow into identity and access management ecosystems. Our
      services include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Consulting and strategy for ITSM and identity management using
        ServiceNow.
      </li>
      <li className="modal-list-item">
        Integration of ServiceNow into broader identity management solutions to
        create a cohesive IT environment.
      </li>
      <li className="modal-list-item">
        Custom workflow development and automation to streamline identity
        processes.
      </li>
      <li className="modal-list-item">
        Ongoing support and optimization to ensure identity solutions grow with
        business needs.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://docs.servicenow.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ServiceNow Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://community.servicenow.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ServiceNow Community
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://developer.servicenow.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ServiceNow Developer Portal
        </a>
      </li>
    </ul>
  </>
);

export default ServiceNowContent;
