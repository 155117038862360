import React from 'react';
import Medal from '../icons/RecognitionMedalDarkTheme.gif';
import './App.css';

const H5 = () => {
    return (
        
        <div className="header-tile medal-dark-holder">
            <img
                src={Medal}
                alt="A representation of the Identity world"
                className="medal-dark-image"
                /> 


            <span className="font-link">
                <h1>Is <span className="highlight">RAAH</span> recognized?</h1>
            </span>
        
        </div>
    )

}

export default H5;