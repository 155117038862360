import React from 'react';
import {
  FaUsers,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const MicrosoftEntraContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing Your Enterprise with Microsoft Entra ID
    </h3>
    <p>
      Microsoft Entra ID (formerly known as Azure Active Directory) is a
      comprehensive identity and access management solution that provides
      seamless access, enhanced security, and improved governance for both
      on-premises and cloud-based resources. At{' '}
      <strong>RAAH Technologies</strong>, we bring our deep understanding of
      Microsoft Entra ID to help businesses leverage this powerful tool for
      identity management, enabling secure and productive experiences for both
      employees and partners.
    </p>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Microsoft Entra ID: Comprehensive Access Management
    </h4>
    <p>
      Microsoft Entra ID offers a range of features designed to secure
      identities and facilitate efficient access to critical resources. Key
      features include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Conditional Access Policies:</strong> Enforce access
        requirements based on risk conditions, user context, or device
        compliance to ensure security without impacting productivity.
      </li>
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Protect access to
        sensitive data by requiring additional verification steps during the
        authentication process.
      </li>
      <li className="modal-list-item">
        <strong>Seamless Single Sign-On (SSO):</strong> Enable users to access
        applications and resources with a single login, reducing password
        fatigue and improving the overall experience.
      </li>
      <li className="modal-list-item">
        <strong>Identity Governance:</strong> Automate identity lifecycle
        management and streamline processes for onboarding, offboarding, and
        access certification.
      </li>
      <li className="modal-list-item">
        <strong>Integration with Microsoft 365 and Azure:</strong> Leverage
        built-in integration with Microsoft's suite of services for better
        productivity and ease of use.
      </li>
    </ul>
    <p>
      Microsoft Entra ID provides organizations with robust identity management
      capabilities, securing access to both cloud and on-premises applications.
      With RAAH's expertise, companies can leverage these capabilities to
      achieve optimal security and productivity.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Streamlining Access for a Financial Services Company
    </h4>
    <p>
      <strong>Challenge:</strong> A financial services company needed to provide
      secure and seamless access to both cloud and on-premises applications for
      employees and partners, while ensuring compliance with industry
      regulations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Microsoft Entra
      ID with Conditional Access and Multi-Factor Authentication (MFA) to ensure
      secure access. Single Sign-On (SSO) was deployed across the organization
      to streamline user access, while identity governance features helped
      automate user onboarding and offboarding.
    </p>
    <p>
      <strong>Result:</strong> The company experienced a{' '}
      <strong>30% reduction</strong> in helpdesk calls related to access issues
      and improved compliance, reducing the risk of regulatory penalties.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Protect sensitive data with
        Multi-Factor Authentication (MFA) and Conditional Access.
      </li>
      <li className="modal-list-item">
        <strong>Improved Productivity:</strong> Single Sign-On (SSO) simplifies
        access, reducing password fatigue and helpdesk support.
      </li>
      <li className="modal-list-item">
        <strong>Streamlined Identity Management:</strong> Automate onboarding,
        offboarding, and access certification with identity governance features.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Meet industry-specific regulatory
        requirements through robust access controls and audit trails.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we combine our expertise in
      identity management with our deep knowledge of Microsoft Entra ID to
      provide tailored identity solutions that address the specific needs of
      your organization. Our services include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Consulting and strategy for implementing identity and access management
        using Microsoft Entra ID.
      </li>
      <li className="modal-list-item">
        Integration with Microsoft 365, Azure, and other third-party systems to
        create a unified identity management environment.
      </li>
      <li className="modal-list-item">
        Identity lifecycle management, including automated onboarding and
        offboarding processes.
      </li>
      <li className="modal-list-item">
        Ongoing support and optimization to adapt your identity solution as your
        organization's needs evolve.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://learn.microsoft.com/en-us/azure/active-directory/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Entra ID Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://techcommunity.microsoft.com/t5/azure-active-directory/ct-p/AzureActiveDirectory"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Entra Community
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/Azure/azure-sdk-for-js"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Azure GitHub Repository
        </a>
      </li>
    </ul>
  </>
);

export default MicrosoftEntraContent;
