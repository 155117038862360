import React from 'react';
import {
  FaDatabase,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const RadiantLogicContent = () => (
  <>
    <h3 className="modal-main-title">
      Streamlining Identity Data with Radiant Logic
    </h3>
    <p>
      Radiant Logic is a leader in identity data integration, providing a
      unified view of distributed identities through its Virtual Directory
      Service (VDS). At <strong>RAAH Technologies</strong>, we leverage Radiant
      Logic to create a streamlined identity architecture, helping organizations
      gain better control over identity data and optimize their IAM and IGA
      solutions.
    </p>

    <h4 className="modal-subheading">
      <FaDatabase size={30} style={{ marginRight: '10px' }} />
      Radiant Logic: Simplified Identity Integration
    </h4>
    <p>
      Radiant Logic's platform is designed to simplify identity data
      integration, making it easier for organizations to unify identities from
      multiple sources and create a single source of truth. Key features
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Federated Identity Directory:</strong> Aggregates identity data
        from multiple directories, databases, and applications to create a
        unified identity view.
      </li>
      <li className="modal-list-item">
        <strong>Identity Correlation:</strong> Matches and correlates identities
        across different data stores, ensuring consistency and eliminating
        duplicates.
      </li>
      <li className="modal-list-item">
        <strong>Virtual Directory Service (VDS):</strong> Provides a real-time,
        unified view of identity data without the need to move or replicate
        data, reducing complexity.
      </li>
      <li className="modal-list-item">
        <strong>Data Transformation:</strong> Supports powerful data
        transformation capabilities, enabling identity data to be presented in
        the format required by consuming applications.
      </li>
      <li className="modal-list-item">
        <strong>Scalable Architecture:</strong> Built to handle large volumes of
        identity data, making it suitable for enterprises with complex identity
        environments.
      </li>
    </ul>
    <p>
      Radiant Logic enables organizations to create a unified identity
      infrastructure that simplifies authentication, authorization, and
      governance. At RAAH, we bring deep expertise in implementing Radiant Logic
      to optimize identity architectures and enable seamless integration across
      systems.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Unifying Identities for a Global Enterprise
    </h4>
    <p>
      <strong>Challenge:</strong> A global enterprise faced challenges in
      managing identities spread across multiple Active Directory forests, cloud
      applications, and legacy databases. The lack of a unified view made it
      difficult to manage user access and enforce consistent security policies.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Radiant Logic's
      Virtual Directory Service to aggregate identity data from all sources,
      creating a single, unified view of all identities. Identity correlation
      was used to match identities across different systems, and data
      transformation ensured that each consuming application received identity
      data in the required format.
    </p>
    <p>
      <strong>Result:</strong> The enterprise gained a{' '}
      <strong>360-degree view</strong> of all identities, reducing
      administrative overhead by <strong>40%</strong> and enabling consistent
      security policy enforcement across all systems.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Unified Identity View:</strong> Aggregates identity data from
        multiple sources to create a single source of truth.
      </li>
      <li className="modal-list-item">
        <strong>Improved Access Management:</strong> Provides a consistent view
        of identities, enabling more effective access management and governance.
      </li>
      <li className="modal-list-item">
        <strong>Reduced Complexity:</strong> Eliminates the need for data
        replication and reduces the complexity of managing multiple identity
        data stores.
      </li>
      <li className="modal-list-item">
        <strong>Scalability:</strong> Handles large volumes of identity data,
        making it ideal for large enterprises with complex identity
        environments.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we understand the complexities of
      managing identity data across diverse environments. Our expertise in
      deploying Radiant Logic enables organizations to:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Design and implement a unified identity infrastructure that aggregates
        identity data from multiple sources.
      </li>
      <li className="modal-list-item">
        Seamlessly integrate Radiant Logic with existing IAM, IGA, and
        application environments to ensure consistent access management.
      </li>
      <li className="modal-list-item">
        Customize data transformation rules to meet the specific needs of
        consuming applications and services.
      </li>
      <li className="modal-list-item">
        Provide ongoing support and optimization to adapt the solution to
        evolving business and regulatory needs.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://docs.radiantlogic.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Radiant Logic Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://www.radiantlogic.com/resources"
          target="_blank"
          rel="noopener noreferrer"
        >
          Radiant Logic Resources
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://support.radiantlogic.com/hc/en-us/sections/6399537997716-FAQs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Radiant Logic FAQs
        </a>
      </li>
    </ul>
  </>
);

export default RadiantLogicContent;
