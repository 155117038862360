import React from 'react';
import {
  FaUsers,
  FaShieldAlt,
  FaCode,
  FaClipboardList,
  FaLightbulb,
  FaNetworkWired,
} from 'react-icons/fa';

const OktaContent = () => (
  <>
    <h3 className="modal-main-title">
      Empowering Organizations with Okta Identity Solutions
    </h3>
    <p>
      Okta provides a comprehensive suite of identity management solutions that
      help secure and streamline digital experiences for both workforce and
      customer-facing applications. At <strong>RAAH Technologies</strong>, we
      leverage Okta to deliver tailored solutions, ensuring that businesses
      maximize their return on identity investments.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Okta Workforce Identity Cloud
    </h4>
    <p>
      Okta Workforce Identity Cloud is designed to ensure that employees,
      contractors, and partners have secure and frictionless access to the tools
      they need. It offers a variety of features such as:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Provides one-click access to all
        applications, reducing password fatigue and enhancing productivity.
      </li>
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adds an extra layer
        of security, making sure that the right people access the right
        resources.
      </li>
      <li className="modal-list-item">
        <strong>Lifecycle Management:</strong> Automates user onboarding,
        offboarding, and permission adjustments, ensuring efficient access
        control and reducing manual errors.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Access:</strong> Implements intelligent access policies
        that adapt to the context of access attempts, providing the right
        balance of security and convenience.
      </li>
    </ul>
    <p>
      Our expertise with Okta Workforce Identity Cloud ensures that these
      features are not only deployed correctly but are optimized for the unique
      needs of your organization. We provide complete lifecycle management
      solutions, from integration to ongoing support, aimed at boosting employee
      productivity and minimizing security risks.
    </p>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Okta Customer Identity Cloud (CIC)
    </h4>
    <p>
      Formerly known as Auth0, Okta Customer Identity Cloud (CIC) offers
      customer-focused identity management capabilities. CIC is built to help
      companies create secure, personalized customer experiences across digital
      platforms. It includes features like:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Social and Enterprise Login:</strong> Offers easy integration
        with popular social networks and enterprise systems for streamlined
        customer onboarding.
      </li>
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Improves customer
        security by adding an additional layer of identity verification.
      </li>
      <li className="modal-list-item">
        <strong>Customizable Login and Signup:</strong> Provides a seamless,
        branded customer experience tailored to your business needs.
      </li>
      <li className="modal-list-item">
        <strong>Identity Federation and SSO:</strong> Ensures a consistent and
        secure login experience across multiple applications, reducing user
        friction.
      </li>
      <li className="modal-list-item">
        <strong>Data Privacy and Consent Management:</strong> Helps ensure that
        customer data is managed in compliance with regulations such as GDPR,
        enhancing trust.
      </li>
    </ul>
    <p>
      At <strong>RAAH Technologies</strong>, we help organizations take full
      advantage of Okta CIC by tailoring its capabilities to meet specific
      business objectives. We understand that every customer journey is unique,
      and our team ensures that Okta CIC's features are configured to provide
      seamless, secure, and delightful customer experiences.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Enhancing Security for a Financial Institution
    </h4>
    <p>
      <strong>Challenge:</strong> A major financial institution required a
      robust, secure solution to manage both employee access and customer
      identities, while maintaining compliance with regulatory requirements.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Okta Workforce
      Identity Cloud to manage internal employee access, utilizing features like
      SSO, MFA, and lifecycle management to automate access provisioning.
      Additionally, Okta CIC was deployed to enhance the customer experience
      through Social Login, MFA, and personalized customer journeys. Integration
      with the institution's existing tech stack ensured seamless user
      experiences across all touchpoints.
    </p>
    <p>
      <strong>Result:</strong> The institution experienced a{' '}
      <strong>40% reduction</strong> in IT helpdesk calls related to password
      resets, increased customer satisfaction due to simplified onboarding
      processes, and met all compliance requirements with ease.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Comprehensive identity and access
        management for both workforce and customer use cases, leveraging MFA and
        adaptive access controls.
      </li>
      <li className="modal-list-item">
        <strong>Streamlined User Experience:</strong> Provides seamless login
        experiences across platforms for both employees and customers, reducing
        friction and enhancing satisfaction.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensures compliance with industry
        standards and data protection regulations, helping organizations avoid
        penalties.
      </li>
      <li className="modal-list-item">
        <strong>Scalable Customer Experiences:</strong> Flexible identity
        management tools to create personalized, secure, and user-friendly
        customer journeys.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaNetworkWired size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we go beyond simply implementing
      Okta. We understand the nuances of identity challenges and how Okta's
      features can solve them. Our approach is consultative—we help clients
      identify gaps in their current identity management, choose the right Okta
      components, and integrate them seamlessly into their environment. We
      specialize in both the technical setup and in driving business value,
      ensuring security, compliance, and a superior user experience.
    </p>
    <p>
      Whether you are adopting Okta for the first time, optimizing your current
      setup, or seeking advanced configurations for complex environments, we
      bring the expertise to guide you through every step.
    </p>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://auth0.com/docs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Auth0 Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://support.okta.com/help/s/community?language=en_US"
          target="_blank"
          rel="noopener noreferrer"
        >
          Okta Support Community
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://community.auth0.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Auth0 Community Forum
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/okta"
          target="_blank"
          rel="noopener noreferrer"
        >
          Okta GitHub Repository
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/auth0"
          target="_blank"
          rel="noopener noreferrer"
        >
          Auth0 GitHub Repository
        </a>
      </li>
    </ul>
  </>
);

export default OktaContent;
