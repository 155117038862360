import React from 'react';
import {
  FaUsers,
  FaTools,
  FaClipboardList,
  FaLightbulb,
  FaCode,
} from 'react-icons/fa';

const OmadaContent = () => (
  <>
    <h3 className="modal-main-title">
      Streamlined Identity Governance with Omada
    </h3>
    <p>
      Omada is a leader in Identity Governance and Administration (IGA),
      providing solutions that help organizations manage and secure identities,
      ensure compliance, and improve operational efficiency. At{' '}
      <strong>RAAH Technologies</strong>, we leverage Omada's capabilities to
      deliver scalable and robust identity governance solutions that enhance
      security and streamline processes.
    </p>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      Omada: Comprehensive Identity Governance
    </h4>
    <p>
      Omada provides a comprehensive platform for identity lifecycle management,
      ensuring that users have the right access to the right resources at the
      right time. Key features of Omada include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Access Certification:</strong> Automates access reviews to
        ensure compliance with regulations and internal policies.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Manages the entire
        lifecycle of user identities, from onboarding to offboarding, ensuring
        that access is properly provisioned and revoked.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Simplifies access
        management by assigning users to roles based on their responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Access Request and Approval:</strong> Provides an intuitive
        self-service portal for users to request access, with configurable
        approval workflows.
      </li>
      <li className="modal-list-item">
        <strong>Compliance and Audit:</strong> Ensures that access policies are
        enforced and provides audit trails for compliance purposes.
      </li>
    </ul>
    <p>
      Omada's IGA platform helps organizations manage user access efficiently
      and securely, reducing the risk of unauthorized access and ensuring
      compliance. At RAAH, we bring our expertise in implementing Omada to help
      organizations achieve their identity governance goals.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Improving Access Control for a Healthcare Provider
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare provider needed to ensure that
      access to patient data and internal systems was tightly controlled, while
      also ensuring compliance with healthcare regulations such as HIPAA.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Omada's IGA
      solution to manage the entire lifecycle of user identities, including
      onboarding, role assignments, and access certifications. Role-Based Access
      Control (RBAC) was used to ensure that only authorized personnel could
      access sensitive patient information, and automated access reviews helped
      maintain compliance with HIPAA requirements.
    </p>
    <p>
      <strong>Result:</strong> The healthcare provider experienced a{' '}
      <strong>40% reduction</strong> in unauthorized access incidents and
      improved their compliance posture, reducing the risk of regulatory
      penalties.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Ensures that users only have access
        to the resources they need, reducing the risk of data breaches.
      </li>
      <li className="modal-list-item">
        <strong>Improved Compliance:</strong> Automates access reviews and
        provides audit trails to ensure compliance with regulations.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Streamlines identity management
        processes, reducing the workload for IT and security teams.
      </li>
      <li className="modal-list-item">
        <strong>Better User Experience:</strong> Provides an intuitive
        self-service portal for access requests, reducing delays and improving
        user satisfaction.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we leverage our deep expertise in
      identity governance to help organizations get the most out of Omada's
      platform. Our services include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Designing and implementing identity governance solutions tailored to the
        unique needs of your organization.
      </li>
      <li className="modal-list-item">
        Integrating Omada with existing IT systems, such as HR and ERP
        platforms, to create a unified identity management environment.
      </li>
      <li className="modal-list-item">
        Providing ongoing support and optimization to ensure that your identity
        governance solution continues to meet your evolving needs.
      </li>
      <li className="modal-list-item">
        Delivering training and best practices to help your team effectively
        manage identities and maintain compliance.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://omadaidentity.com/services/omada-academy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Omada Academy
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://documentation.omadaidentity.com/docs/welcome-to-omada/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Omada Documentation (Login Required)
        </a>
      </li>
      <li className="modal-list-item">
        Looking for more information? Work with RAAH and we will liaise with
        Omada to get you access to their documentation and other resources
        without engaging in a formal sales process.
      </li>
    </ul>
  </>
);

export default OmadaContent;
