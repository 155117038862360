import React from 'react';
import {
  FaExclamationTriangle,
  FaToolbox,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const EnergyAndUtilitiesContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing the Energy and Utilities Sector with Tailored Identity Solutions
    </h3>
    <p className="modal-paragraph">
      The energy and utilities sector is critical to national infrastructure and
      must be safeguarded against both physical and cyber threats. At{' '}
      <strong>RAAH Technologies</strong>, we offer customized identity and
      access management solutions that provide secure, compliant, and efficient
      operations for energy companies and utilities.
    </p>

    <h4 className="modal-subheading">
      <FaExclamationTriangle size={30} style={{ marginRight: '10px' }} />
      Challenges in the Energy and Utilities Sector
    </h4>
    <p className="modal-paragraph">
      The energy sector faces unique challenges, including managing secure
      access to critical infrastructure, meeting compliance requirements, and
      protecting against potential cyber threats. Our tailored solutions address
      these key challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing workforce access to critical
        systems and infrastructure, ensuring employees and contractors have the
        appropriate level of access at all times.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Providing identity governance for both internal
        and external personnel, managing user lifecycles, and ensuring
        compliance with industry regulations.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Implementing Privileged Access Management to
        secure access to control systems, grid infrastructure, and other
        high-value assets.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for third-party vendors
        and partners that need access to the energy network, ensuring secure
        collaboration while limiting risks.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaToolbox size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Energy and Utilities
    </h4>
    <p className="modal-paragraph">
      RAAH Technologies provides customized identity solutions for the energy
      and utilities sector, including:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Implementing MFA to
        protect access to critical systems and infrastructure, adding an extra
        layer of security against unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Enabling role-based
        access for field workers, administrative personnel, and contractors to
        ensure secure access to systems based on user roles and
        responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Federated Identity Management:</strong> Facilitating
        collaboration with third-party contractors and vendors by securely
        managing their identities and ensuring compliance with industry
        standards.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Secure Power Company
    </h4>
    <p className="modal-paragraph">
      <strong>Challenge:</strong> Secure Power Company needed to ensure that
      their workforce and contractors had secure access to critical
      infrastructure while maintaining compliance with industry standards.
    </p>
    <p className="modal-paragraph">
      <strong>Solution:</strong> RAAH provided an integrated identity and access
      management solution, implementing Workforce IAM and MFA to secure access
      to control systems and sensitive grid infrastructure. Additionally,
      Role-Based Access Control (RBAC) was employed to ensure that field
      workers, contractors, and administrative staff had appropriate access
      based on their roles.
    </p>
    <p className="modal-paragraph">
      <strong>Result:</strong> Secure Power Company reported a{' '}
      <strong>30% reduction</strong> in unauthorized access attempts and a
      significant improvement in their compliance posture, which helped avoid
      costly fines.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Comprehensive IAM and PAM solutions
        that protect critical infrastructure from unauthorized access and cyber
        threats.
      </li>
      <li className="modal-list-item">
        <strong>Operational Efficiency:</strong> Simplified identity governance
        reduces manual processes for managing access and enhances productivity.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to industry
        regulations, reducing the risk of penalties for non-compliance.
      </li>
      <li className="modal-list-item">
        <strong>Streamlined Collaboration:</strong> Federated identity solutions
        enable secure collaboration with third-party vendors and contractors.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p className="modal-paragraph">
      The future of the energy and utilities sector demands secure, resilient,
      and scalable identity solutions that can adapt to evolving threats and
      regulatory changes. At <strong>RAAH Technologies</strong>, we are
      committed to providing innovative IAM, IGA, PAM, and federated identity
      solutions to safeguard energy infrastructure and ensure reliable services
      for the community.
    </p>
  </>
);

export default EnergyAndUtilitiesContent;
