import React, { useState, useEffect } from "react";
import "./philosophyComponent.css";
import { useNavigate } from 'react-router-dom';

function PhilosophyComponent() {
    const [showPhilosophyTitle, setShowPhilosophyTitle] = useState(false);
    const [showPhilosophyParagraph, setShowPhilosophyParagraph] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Start the title animation after a short delay
        const titleTimer = setTimeout(() => {
            setShowPhilosophyTitle(true);
            // Start the paragraph animation after the title animation
            const paragraphTimer = setTimeout(() => {
                setShowPhilosophyParagraph(true);
            }, 200); // Adjust delay as needed

            return () => clearTimeout(paragraphTimer);
        }, 100); // Adjust delay as needed

        return () => clearTimeout(titleTimer);
    }, []);

    return (
        <div className="philosophyContainer">
            <div className={`philosophyTitle ${showPhilosophyTitle ? 'animate' : ''}`}>
                <h1>The <span className="highlightPhilosophy">RAAH</span> Philosophy</h1>
            </div>
            <div className={`philosphyParagraph ${showPhilosophyParagraph ? 'show' : 'hide'}`}>
                <p>
                    In Hindi, <span className="highlightPhilosophy">RAAH (राह)</span> means <span className="highlightPhilosophy">"path"</span>. We guide you through the chaos of the digital world, protecting and future-proofing your data, people, and systems. With cutting-edge identity and access management, we tackle today’s challenges and ensure you're ready to dominate tomorrow. <br /><br />We're not just here to solve problems; we’re here to keep you ahead, secure, and unstoppable.
                </p>
                <button
                    className="newsButton"
                    onClick={() => navigate("/news")}
                >
                    Read our latest News & PR!
                </button>
            </div>
        </div>
    );
}

export default PhilosophyComponent;
