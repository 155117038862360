import React from 'react';
import {
  FaHospital,
  FaShieldAlt,
  FaUserMd,
  FaClipboardList,
  FaHeartbeat,
  FaLightbulb,
} from 'react-icons/fa';

const HealthcareContent = () => (
  <>
    <h3 className="modal-main-title">
      Transforming Healthcare and Insurance with Secure Identity Solutions
    </h3>
    <p>
      The healthcare and insurance sectors handle sensitive personal and medical
      data, requiring robust identity and access management to safeguard patient
      information and ensure regulatory compliance. At{' '}
      <strong>RAAH Technologies</strong>, we provide advanced identity solutions
      designed to meet the unique needs of healthcare providers, insurers, and
      patients.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in Healthcare and Insurance
    </h4>
    <p>
      Healthcare and insurance organizations face numerous challenges, including
      securing patient data, ensuring compliance with privacy regulations, and
      managing identities across complex ecosystems. Our identity solutions
      address these challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Patient IAM (CIAM):</strong> Providing patients with secure
        access to healthcare portals, ensuring privacy and ease of use.
      </li>
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing access for healthcare
        professionals and administrative staff, ensuring compliance with
        healthcare standards.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities for healthcare workers,
        ensuring the right people have appropriate access to systems and data.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged access to critical
        healthcare systems, including medical devices and administrative tools.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for partners, insurers,
        and other stakeholders, ensuring secure collaboration across the
        healthcare ecosystem.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserMd size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Healthcare and Insurance
    </h4>
    <p>
      Our customized identity solutions for healthcare and insurance include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect patient and provider accounts, safeguarding
        sensitive medical information.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling healthcare professionals
        to use a single set of credentials to access multiple systems, improving
        efficiency and reducing login fatigue.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that
        healthcare workers have access to the systems and data they need, based
        on their roles and responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user identities
        throughout their employment lifecycle, ensuring compliance with
        regulations like HIPAA.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing access to
        critical systems, including administrative tools and medical devices, to
        protect against unauthorized use.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Secure Health Network
    </h4>
    <p>
      <strong>Challenge:</strong> Secure Health Network needed to protect
      patient data while ensuring healthcare professionals could easily access
      critical systems and information.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a comprehensive
      identity solution, including Single Sign-On (SSO) and Multi-Factor
      Authentication (MFA) for healthcare professionals. Role-Based Access
      Control (RBAC) was used to manage access based on job responsibilities,
      and Privileged Access Management (PAM) protected administrative tools.
    </p>
    <p>
      <strong>Result:</strong> Secure Health Network saw a{' '}
      <strong>40% reduction</strong> in unauthorized access attempts and
      improved compliance with healthcare data regulations, resulting in greater
      trust from patients and healthcare providers.
    </p>

    <h4 className="modal-subheading">
      <FaHeartbeat size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Patient Security:</strong> Protecting sensitive health
        information with CIAM and MFA, ensuring privacy and security.
      </li>
      <li className="modal-list-item">
        <strong>Improved Provider Efficiency:</strong> Streamlined access for
        healthcare professionals with SSO and RBAC, reducing administrative
        burden.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to healthcare
        regulations such as HIPAA, reducing the risk of penalties and breaches.
      </li>
      <li className="modal-list-item">
        <strong>Secure Collaboration:</strong> Enabling secure interactions with
        partners, insurers, and other stakeholders through effective identity
        management.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As the healthcare and insurance sectors continue to evolve, secure
      identity solutions are crucial to protecting sensitive data and enhancing
      patient care. At <strong>RAAH Technologies</strong>, we are dedicated to
      providing advanced IAM, IGA, CIAM, and PAM solutions that meet the complex
      needs of healthcare providers, insurers, and patients.
    </p>
  </>
);

export default HealthcareContent;
