// UserEntitlementContent.js

import React from 'react';
import { FaUserTag, FaTasks, FaKey } from 'react-icons/fa';

const UserEntitlementContent = () => (
  <>
    <h3 className="modal-main-title">User Entitlement Management</h3>
    <p>
      User Entitlement Management involves defining, granting, and managing the
      access rights and permissions that users have within an organization's
      systems. Proper entitlement management ensures that users have the
      appropriate level of access based on their roles and responsibilities,
      helping to enforce the principle of least privilege.
    </p>

    <h4 className="modal-subheading">
      <FaUserTag size={30} style={{ marginRight: '10px' }} />
      Benefits of User Entitlement Management
    </h4>
    <p>
      Implementing User Entitlement Management helps organizations improve
      security, ensure compliance, and reduce the risk of unauthorized access.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Access Control:</strong> Provides a structured approach to
        granting and managing user permissions, ensuring users have only the
        access they need.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Helps meet regulatory requirements by
        ensuring that user access is managed in line with business policies and
        compliance standards.
      </li>
      <li className="modal-list-item">
        <strong>Reduced Risk:</strong> Minimizes the risk of excessive
        permissions and potential security breaches by enforcing the principle
        of least privilege.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTasks size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports User Entitlement Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive User Entitlement
      Management solutions to help organizations effectively manage user access
      rights. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Automated Entitlement Assignment:</strong> Automate the
        assignment of entitlements based on user roles, reducing manual errors
        and ensuring consistency.
      </li>
      <li className="modal-list-item">
        <strong>Real-Time Access Reviews:</strong> Conduct real-time reviews of
        user entitlements to ensure that access remains appropriate as roles and
        responsibilities change.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Reporting:</strong> Track and report on user
        entitlements to provide visibility and support compliance efforts.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      Use Case: Managing Entitlements in a Financial Institution
    </h4>
    <p>
      <strong>Challenge:</strong> A financial institution needed to ensure that
      user entitlements were properly managed to prevent excessive permissions
      and maintain compliance with financial regulations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an automated User
      Entitlement Management solution that assigned entitlements based on user
      roles and conducted regular access reviews.
    </p>
    <p>
      <strong>Result:</strong> The institution reduced the risk of unauthorized
      access, ensured compliance with regulatory requirements, and improved
      overall access management efficiency.
    </p>
  </>
);

export default UserEntitlementContent;
