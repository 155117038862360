// AuditComplianceWorkforceContent.js

import React from 'react';
import { FaClipboardCheck, FaLock, FaUserShield } from 'react-icons/fa';

const AuditComplianceWorkforceContent = () => (
  <>
    <h3 className="modal-main-title">Audit and Compliance for Workforce</h3>
    <p>
      Audit and Compliance are essential for ensuring that organizational
      policies and regulatory requirements are being followed across all
      workforce-related identity and access management activities. Regular
      audits help identify potential gaps in security and ensure that user
      access aligns with business needs and compliance standards.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardCheck size={30} style={{ marginRight: '10px' }} />
      Benefits of Audit and Compliance
    </h4>
    <p>
      Implementing comprehensive audit and compliance processes helps
      organizations reduce risks, ensure adherence to regulations, and maintain
      visibility into workforce access activities.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensure that user access and
        identity management practices align with regulatory standards such as
        GDPR, HIPAA, and SOX.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Regular audits help identify and
        address security gaps, ensuring that workforce access is appropriately
        managed.
      </li>
      <li className="modal-list-item">
        <strong>Accountability and Transparency:</strong> Maintain detailed
        records of access and identity changes, providing visibility and
        accountability for all actions.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Audit and Compliance
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive audit and
      compliance solutions tailored to workforce identity management. Our
      solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Automated Audit Reports:</strong> Generate automated reports to
        track workforce access activities, providing insights into potential
        compliance issues.
      </li>
      <li className="modal-list-item">
        <strong>Compliance Monitoring:</strong> Monitor user access in real-time
        to ensure that compliance requirements in accordance with SOX, HIPAA,
        and more are met with regularity
      </li>
      <li className="modal-list-item">
        <strong>Access Certification Campaigns:</strong> Conduct periodic access
        reviews and certifications to validate that workforce members have
        appropriate access rights.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Use Case: Ensuring Compliance for a Healthcare Provider
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare provider needed to ensure that
      workforce access to sensitive patient data complied with HIPAA regulations
      while maintaining operational efficiency.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an audit and
      compliance solution that included automated audit reporting, real-time
      monitoring, and periodic access certification campaigns.
    </p>
    <p>
      <strong>Result:</strong> The healthcare provider achieved compliance with
      HIPAA, improved visibility into workforce access, and maintained a secure
      environment for patient data.
    </p>
  </>
);

export default AuditComplianceWorkforceContent;
