import React, { useState, useEffect } from "react";
import "./news1Component.css";
import article1 from '../icons/OktaAwardPR.png';

function News1Component() {
    const [showNews1Title, setShowNews1Title] = useState(false);
    const [showNews1Paragraph, setShowNews1Paragraph] = useState(false);

    useEffect(() => {
        // Start the title animation after a short delay
        const titleTimer = setTimeout(() => {
            setShowNews1Title(true);
            // Start the paragraph animation after the title animation
            const paragraphTimer = setTimeout(() => {
                setShowNews1Paragraph(true);
            }, 200); // Adjust delay as needed

            return () => clearTimeout(paragraphTimer);
        }, 100); // Adjust delay as needed

        return () => clearTimeout(titleTimer);
    }, []);

    return (
        <div className="news1Container">
            {/* <div className={`News1Title ${showNews1Title ? 'animate' : ''}`}>
                <h1><span className="highlightNews1">RAAH</span> News & PR</h1>
            </div> */}
            <div className={`news1Paragraph ${showNews1Paragraph ? 'show' : 'hide'}`}>
                <img className="newsArticle1" src={article1}/>
            </div>
        </div>
    );
}

export default News1Component;
