import React from 'react';
import { FaSearch, FaUserLock, FaClipboardList } from 'react-icons/fa';

const PrivilegedAccountMonitoringContent = () => (
  <>
    <h3 className="modal-main-title">
      Privileged Account Session Monitoring and Auditing
    </h3>
    <p>
      Privileged Account Session Monitoring and Auditing is a critical aspect of
      Privileged Access Management (PAM). It involves tracking and auditing the
      activities performed during privileged sessions to ensure that actions
      taken by privileged users are appropriate and compliant with security
      policies. This approach helps prevent misuse of privileged access, ensures
      accountability, and provides insights for improving security.
    </p>

    <h4 className="modal-subheading">
      <FaSearch size={30} style={{ marginRight: '10px' }} />
      Benefits of Privileged Account Session Monitoring and Auditing
    </h4>
    <p>
      Implementing Privileged Account Session Monitoring and Auditing helps
      organizations enhance security, ensure accountability, and maintain
      compliance with data protection regulations.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Monitor privileged user sessions in
        real-time to detect and respond to suspicious activities, reducing the
        risk of unauthorized actions.
      </li>
      <li className="modal-list-item">
        <strong>Accountability:</strong> Maintain detailed records of all
        actions taken during privileged sessions, ensuring that users are held
        accountable for their activities.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that privileged session activities
        comply with regulations like GDPR and CCPA, protecting sensitive
        information and maintaining transparency.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserLock size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Privileged Account Session Monitoring and
      Auditing
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers Privileged Access Management
      (PAM) solutions that include Privileged Account Session Monitoring and
      Auditing. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Session Recording:</strong> Record all privileged user sessions
        to capture detailed information about actions taken, providing a
        comprehensive audit trail for compliance and security analysis.
      </li>
      <li className="modal-list-item">
        <strong>Real-Time Monitoring:</strong> Monitor privileged sessions in
        real-time to detect and respond to any suspicious activities, minimizing
        potential risks.
      </li>
      <li className="modal-list-item">
        <strong>Audit and Reporting:</strong> Generate detailed audit reports of
        privileged sessions to ensure compliance with regulatory requirements
        and provide insights for improving security practices.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Implementing Privileged Account Session Monitoring for a
      Financial Institution
    </h4>
    <p>
      <strong>Challenge:</strong> A financial institution needed to ensure that
      all actions taken by privileged users were properly monitored and audited
      to protect sensitive financial data and meet regulatory requirements.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a Privileged
      Account Session Monitoring and Auditing solution that recorded all
      privileged sessions, provided real-time monitoring, and generated detailed
      audit reports to ensure compliance and security.
    </p>
    <p>
      <strong>Result:</strong> The financial institution improved security,
      ensured compliance with data protection regulations, and maintained
      accountability for all privileged activities, resulting in enhanced trust
      and confidence in their security practices.
    </p>
  </>
);

export default PrivilegedAccountMonitoringContent;
