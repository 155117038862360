// AccessCertificationContent.js

import React from 'react';
import { FaClipboardCheck, FaLock, FaUserShield } from 'react-icons/fa';

const AccessCertificationContent = () => (
  <>
    <h3 className="modal-main-title">Access Certification and Attestation</h3>
    <p>
      Access Certification and Attestation are critical components of identity
      governance that involve periodically reviewing user access to ensure it is
      appropriate and aligns with organizational policies. This process helps
      organizations verify that users have the correct level of access based on
      their roles and responsibilities.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardCheck size={30} style={{ marginRight: '10px' }} />
      Benefits of Access Certification and Attestation
    </h4>
    <p>
      Implementing access certification and attestation processes helps
      organizations maintain compliance, enhance security, and reduce the risk
      of excessive access.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Compliance:</strong> Access certification helps meet regulatory
        requirements by ensuring that users have the appropriate access based on
        their job functions.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Regularly reviewing access rights
        reduces the risk of excessive permissions, minimizing potential attack
        vectors.
      </li>
      <li className="modal-list-item">
        <strong>Accountability:</strong> Attestation ensures that managers and
        application owners are accountable for the access provided to their team
        members.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserShield size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Access Certification
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers comprehensive access
      certification solutions to help organizations maintain control over user
      access. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Automated Campaigns:</strong> We implement automated access
        review campaigns to streamline the certification process, ensuring
        timely completion and reducing manual effort.
      </li>
      <li className="modal-list-item">
        <strong>Customizable Review Policies:</strong> Define and customize
        review policies to align with organizational requirements and compliance
        standards.
      </li>
      <li className="modal-list-item">
        <strong>Manager and Owner Attestation:</strong> Provide tools for
        managers and application owners to easily attest to user access,
        ensuring accountability.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Use Case: Ensuring Compliance in the Healthcare Sector
    </h4>
    <p>
      <strong>Challenge:</strong> A healthcare organization needed to ensure
      compliance with regulations such as HIPAA by periodically reviewing and
      certifying user access to sensitive patient data.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed an automated access
      certification solution that enabled managers to easily review and certify
      access, ensuring that only authorized personnel had access to patient
      information.
    </p>
    <p>
      <strong>Result:</strong> The organization achieved improved compliance,
      reduced the risk of unauthorized access, and maintained a secure
      environment for patient data.
    </p>
  </>
);

export default AccessCertificationContent;
