// GranularAccessControlsContent.js

import React from 'react';
import { FaLock, FaKey, FaShieldAlt } from 'react-icons/fa';

const GranularAccessControlsContent = () => (
  <>
    <h3 className="modal-main-title">Granular Access Controls</h3>
    <p>
      Granular Access Controls allow organizations to define fine-grained
      permissions for users, ensuring that they have access only to the specific
      resources required for their roles. By setting precise access rules,
      organizations can enforce the principle of least privilege, reducing the
      risk of unauthorized access.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Benefits of Granular Access Controls
    </h4>
    <p>
      Implementing Granular Access Controls helps organizations enhance
      security, maintain compliance, and reduce the risk of data breaches by
      tightly controlling user access.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> By defining precise permissions,
        organizations can ensure that users have access only to what they need,
        minimizing potential attack vectors.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Granular controls help meet regulatory
        requirements by ensuring that sensitive data is only accessible to
        authorized users.
      </li>
      <li className="modal-list-item">
        <strong>Risk Reduction:</strong> Enforcing the principle of least
        privilege reduces the risk of internal threats and accidental data
        exposure.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Granular Access Controls
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides comprehensive Granular Access
      Control solutions that help organizations define and manage detailed
      permissions. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Custom Access Policies:</strong> Define custom access policies
        based on user roles, departments, and specific business needs to ensure
        proper access control.
      </li>
      <li className="modal-list-item">
        <strong>Real-Time Enforcement:</strong> Enforce access policies in
        real-time to prevent unauthorized access and respond quickly to changes
        in user roles or permissions.
      </li>
      <li className="modal-list-item">
        <strong>Detailed Audit Trails:</strong> Track and monitor all access
        activities to provide a complete audit trail for compliance and security
        purposes.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      Use Case: Securing Access to Sensitive Customer Data
    </h4>
    <p>
      <strong>Challenge:</strong> A customer service organization needed to
      ensure that only authorized personnel could access sensitive customer
      data, while maintaining operational efficiency.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented Granular Access
      Controls that defined specific permissions for different roles within the
      organization, ensuring that only those with a legitimate need could access
      sensitive data.
    </p>
    <p>
      <strong>Result:</strong> The organization achieved enhanced data security,
      minimized the risk of unauthorized access, and maintained compliance with
      data protection regulations.
    </p>
  </>
);

export default GranularAccessControlsContent;
