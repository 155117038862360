// AccessRequestContent.js

import React from 'react';
import { FaTasks, FaClipboardList, FaCheckCircle } from 'react-icons/fa';

const AccessRequestContent = () => (
  <>
    <h3 className="modal-main-title">Access Request and Workflow Automation</h3>
    <p>
      Access Request and Workflow Automation is a critical part of identity
      management, allowing users to request access to applications or systems in
      an organized way. Automated workflows streamline the process of granting,
      modifying, or revoking access based on business rules, ensuring efficiency
      and compliance.
    </p>

    <h4 className="modal-subheading">
      <FaTasks size={30} style={{ marginRight: '10px' }} />
      Benefits of Access Request and Workflow Automation
    </h4>
    <p>
      Implementing automated workflows for access requests helps organizations
      maintain control over user access while minimizing administrative
      overhead.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Improved Efficiency:</strong> Automating the access request
        process reduces manual tasks, allowing IT teams to focus on more
        strategic activities.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Automated workflows ensure that
        access is granted only after appropriate approvals, reducing the risk of
        unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Workflow automation provides an audit trail
        of access requests and approvals, helping organizations meet regulatory
        requirements.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Access Request Automation
    </h4>
    <p>
      <strong>RAAH Technologies</strong> helps organizations implement robust
      access request and workflow automation solutions. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Customizable Workflows:</strong> We design customizable
        workflows that align with your organization's policies and business
        rules, ensuring that all access requests are properly managed.
      </li>
      <li className="modal-list-item">
        <strong>Approval Management:</strong> Automated approval chains ensure
        that the right stakeholders are involved in the decision-making process
        for granting access.
      </li>
      <li className="modal-list-item">
        <strong>Self-Service Portal:</strong> Provide employees with a
        self-service portal to request access, reducing the dependency on IT
        teams and improving productivity.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCheckCircle size={30} style={{ marginRight: '10px' }} />
      Use Case: Streamlining Access Requests in Manufacturing
    </h4>
    <p>
      <strong>Challenge:</strong> A manufacturing company needed to streamline
      access requests for various applications to ensure that employees had
      timely access to necessary resources.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed an access request
      and workflow automation solution, allowing employees to request access
      through a self-service portal while ensuring that all approvals were
      handled efficiently.
    </p>
    <p>
      <strong>Result:</strong> The company experienced a reduction in the time
      required to grant access, improved operational efficiency, and ensured
      that all access changes were properly documented for compliance purposes.
    </p>
  </>
);

export default AccessRequestContent;
