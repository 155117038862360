import React from 'react';
import './FooterComponent.css';
import SocialIcons from './overlays/SocialIcons';

function FooterComponent({scrollPercentage}) {
  return (
    <div className='footerContainer'>
      <SocialIcons />
      <div className='footerSub'>
        <p>
          Stay updated with the <span className='footerHighlight'>identity</span> and cybersecurity world today.
          <br />
          Subscribe now!
        </p>
      </div>
      <div className='footerEmail'>
        <div className='emailInputContainer'>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Enter your email.'
            className='emailInput'
          />
          <button type='submit' className='submitArrowButton'>
            ➔
          </button>
        </div>
      </div>
      <div className='footerServices'>
        <ul className='firstList'>
          <li><a href='/home'>Home</a></li>
          <li><a href='/about'>About</a></li>
          <li><a href='/services'>Services</a></li>
          <li><a href='/solutions'>Solutions</a></li>
          <li><a href='/contact'>Contact</a></li>
          {/* <li><a href='#'>Case Studies</a></li>
          <li><a href='#'>Free Resources</a></li> */}
        </ul>
        {/* <ul className='secondList'>
          <li><a href='#'>FAQ</a></li>
          <li><a href='#'>Blog</a></li>
          <li><a href='#'>Career</a></li> 
          <li><a href='/contact'>Contact</a></li>
          <li><a href='#'>Privacy Policy</a></li>
          <li><a href='#'>Terms and Conditions</a></li>
        </ul> */}
      </div>
      <div className='footerCopyright'>
        <p>© RAAH Technologies 2024. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default FooterComponent;
