import React from 'react';
import {
  FaBalanceScale,
  FaShieldAlt,
  FaGavel,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const LegalContent = () => (
  <>
    <h3 className="modal-main-title">
      Strengthening Legal and Professional Services with Secure Identity
      Solutions
    </h3>
    <p>
      Legal and professional services require secure access to sensitive client
      information and the ability to manage identities efficiently. At{' '}
      <strong>RAAH Technologies</strong>, we deliver tailored identity and
      access management solutions to protect confidential data, ensure
      compliance, and enable seamless collaboration.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in Legal and Professional Services
    </h4>
    <p>
      Legal and professional services face significant challenges, such as
      safeguarding sensitive client data, managing user access across different
      teams, and ensuring compliance with regulations. Our identity solutions
      address these challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Securing access for legal professionals
        and support staff, ensuring compliance with industry standards.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities for employees, ensuring
        appropriate access rights, and providing audit trails for compliance.
      </li>
      <li className="modal-list-item">
        <strong>Client IAM (CIAM):</strong> Providing clients with secure access
        to legal documents and case information, ensuring privacy and ease of
        use.
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that access
        critical legal systems, mitigating the risk of insider threats and
        unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for external partners and
        collaborators, ensuring secure integration across professional networks.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaGavel size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Legal and Professional Services
    </h4>
    <p>
      Our customized identity solutions for legal and professional services
      include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect client data and legal documents,
        safeguarding against unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Enabling legal professionals to
        use a single set of credentials to access multiple systems, improving
        efficiency and reducing login fatigue.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that legal
        professionals have access to the systems and data they need, based on
        their roles and responsibilities.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user identities
        throughout their employment lifecycle, ensuring compliance with
        regulations such as GDPR.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing access to
        critical legal systems, including administrative tools and case
        management systems, to protect against unauthorized use.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Global Law Firm
    </h4>
    <p>
      <strong>Challenge:</strong> A global law firm needed to protect sensitive
      client information while enabling legal professionals to access case files
      and collaborate securely across multiple offices.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided a comprehensive
      identity solution, including Single Sign-On (SSO) and Multi-Factor
      Authentication (MFA) for secure access. Role-Based Access Control (RBAC)
      was implemented to ensure appropriate access based on job
      responsibilities, and Privileged Access Management (PAM) protected
      administrative tools and case management systems.
    </p>
    <p>
      <strong>Result:</strong> The global law firm experienced a{' '}
      <strong>30% reduction</strong> in unauthorized access attempts and
      improved compliance with data protection regulations, resulting in greater
      trust from clients and more efficient operations.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Client Security:</strong> Protecting sensitive client
        information with CIAM and MFA, ensuring privacy and security.
      </li>
      <li className="modal-list-item">
        <strong>Improved Professional Efficiency:</strong> Streamlined access
        for legal professionals with SSO and RBAC, reducing administrative
        burden.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to legal and
        professional standards, reducing the risk of penalties and breaches.
      </li>
      <li className="modal-list-item">
        <strong>Secure Collaboration:</strong> Enabling secure interactions with
        partners and clients through effective identity management.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As the legal and professional services sectors continue to evolve, secure
      identity solutions are crucial to protecting sensitive data and enhancing
      client service. At <strong>RAAH Technologies</strong>, we are dedicated to
      providing advanced IAM, IGA, CIAM, and PAM solutions that meet the complex
      needs of legal professionals and their clients.
    </p>
  </>
);

export default LegalContent;
