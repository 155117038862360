import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import World from "./icons/world.png";
import './ContactComponent.css';
import { US } from 'country-flag-icons/react/3x2';
import { RO } from 'country-flag-icons/react/3x2';
import { IN } from 'country-flag-icons/react/3x2';

function ContactComponent({scrollPercentage}) {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(null); // Track which country box is visible
  const containerRef = useRef(null);

  const toggleVisibility = (country) => {
    setVisible((prev) => (prev === country ? null : country)); // Toggle visibility
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setVisible(null); // Hide all text boxes
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='contactContainer' ref={containerRef}>
      <div className='worldContainer'>
        <img className="worldImage" alt='world' src={World} width={900} height={500} />

        <div className='plus1' onClick={() => toggleVisibility('usa')}>+</div>
        <div className={`plus1Text ${visible === 'usa' ? 'active' : ''}`}>
          <h1>USA <US title="United States" width={15}/></h1>
          <p>3280 Peachtree Rd NE, 7th <br /> Floor, Atlanta, GA 30305</p>
        </div>

        <div className='plus2' onClick={() => toggleVisibility('romania')}>+</div>
        <div className={`plus2Text ${visible === 'romania' ? 'active' : ''}`}>
          <h1>ROMANIA <RO title="Romania" width={15}/></h1>
          <p>Strada Constantin Noica 57,<br /> Sibiu 550169, Romania</p>
        </div>

        <div className='plus3' onClick={() => toggleVisibility('india')}>+</div>
        <div className={`plus3Text ${visible === 'india' ? 'active' : ''}`}>
          <h1>INDIA <IN title="India" width={15}/></h1>
          <p>A-40, Rock Enclave Hindustan Naka,<br /> Kandivali (West), Mumbai 400067 Maharashtra-India</p>
        </div>
      </div>

      <div className='contactButton'>
        <button className="button" onClick={() => navigate("/contact")}>Contact Us!</button>
      </div>
    </div>
  );
} 

export default ContactComponent;
