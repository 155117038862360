import React from 'react';
import { FaLock, FaKey, FaUsers } from 'react-icons/fa';

const SSOEmployeeContent = () => (
  <>
    <h3 className="modal-main-title">Single Sign-On (SSO) for Employees</h3>
    <p>
      Single Sign-On (SSO) enables employees to use a single set of credentials
      to access multiple applications, improving user experience and reducing IT
      overhead. It minimizes the need for remembering multiple passwords,
      leading to fewer password-related issues and increasing productivity.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Benefits of SSO for Employee Access
    </h4>
    <p>
      Implementing SSO not only enhances the user experience but also
      strengthens security. By centralizing authentication, organizations can
      reduce the risk of compromised credentials, manage access policies more
      efficiently, and ensure better adherence to security protocols.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Streamlined Access:</strong> Employees can access all necessary
        tools and applications with a single login, improving efficiency.
      </li>
      <li className="modal-list-item">
        <strong>Reduced IT Burden:</strong> Fewer password resets mean less
        strain on IT support teams, reducing operational costs.
      </li>
      <li className="modal-list-item">
        <strong>Centralized Security:</strong> Administrators can manage user
        access centrally, making it easier to enforce security policies.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUsers size={30} style={{ marginRight: '10px' }} />
      RAAH Technologies' Expertise in SSO
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we specialize in integrating Single
      Sign-On (SSO) with existing identity infrastructure. We design custom SSO
      solutions that meet the specific needs of your organization, including:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Custom Integrations:</strong> Our solutions integrate seamlessly
        with third-party applications, legacy systems, and custom applications.
      </li>
      <li className="modal-list-item">
        <strong>Secure Authentication:</strong> By centralizing authentication,
        we help your organization enforce stricter access policies.
      </li>
      <li className="modal-list-item">
        <strong>Improved Compliance:</strong> Ensure that access is granted
        based on organizational roles and compliance standards.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaKey size={30} style={{ marginRight: '10px' }} />
      Use Case: Manufacturing Company Enhances Employee Productivity
    </h4>
    <p>
      <strong>Challenge:</strong> A manufacturing company needed to simplify
      employee access to multiple internal systems, each with its own login
      requirements.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented an SSO solution
      that integrated all of the company's internal applications, allowing
      employees to log in once to access all systems securely.
    </p>
    <p>
      <strong>Result:</strong> The company reported a{' '}
      <strong>40% reduction</strong> in IT helpdesk tickets related to login
      issues and an improvement in employee productivity.
    </p>
  </>
);

export default SSOEmployeeContent;
