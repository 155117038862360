import React, { useState, useEffect, useRef } from 'react';
import './solutionsComponent3.css';
import FullScreenModal from '../fullscreenModalComponents/fullScreenModal';

// Import platform images (SVG and PNG)
import OktaPng from '../icons/okta.png';
import PingIdentityPng from '../icons/pingidentity.png';
import CyberArkPng from '../icons/cyberark.png';
import DelineaPng from '../icons/delinea.png';
import RadiantLogicPng from '../icons/radiantlogic.png';
import SAPGigyaPng from '../icons/sapgigya.png';
import OmadaPng from '../icons/omada.png';
import MicrosoftEntraPng from '../icons/microsoftentra.png';
import WSO2Png from '../icons/wso2.png';
import ServiceNowPng from '../icons/servicenow.png';
import AwsPng from '../icons/awsiam.png';
import SaviyntPng from '../icons/saviynt.png';

// Import detailed body content for each platform
import OktaContent from '../fullscreenModalComponents/OktaContent';
import PingContent from '../fullscreenModalComponents/PingContent';
import CyberArkContent from '../fullscreenModalComponents/CyberArkContent';
import DelineaContent from '../fullscreenModalComponents/DelineaContent';
import RadiantLogicContent from '../fullscreenModalComponents/RadiantLogicContent';
import GigyaContent from '../fullscreenModalComponents/GigyaContent';
import OmadaContent from '../fullscreenModalComponents/OmadaContent';
import MicrosoftEntraContent from '../fullscreenModalComponents/MicrosoftEntraContent';
import WSO2Content from '../fullscreenModalComponents/WSO2Content';
import ServiceNowContent from '../fullscreenModalComponents/ServiceNowContent';
import AWSContent from '../fullscreenModalComponents/AWSContent';
import SaviyntContent from '../fullscreenModalComponents/SaviyntContent';

// Platform data array with detailed body content as JSX components
const platformData = [
  {
    title: 'Okta',
    svgIcon: OktaPng,
    body: <OktaContent />,
  },
  {
    title: 'Ping Identity',
    svgIcon: PingIdentityPng,
    body: <PingContent />,
  },
  {
    title: 'CyberArk',
    svgIcon: CyberArkPng,
    body: <CyberArkContent />,
  },
  {
    title: 'Delinea',
    svgIcon: DelineaPng,
    body: <DelineaContent />,
  },
  {
    title: 'Radiant Logic',
    svgIcon: RadiantLogicPng,
    body: <RadiantLogicContent />,
  },
  {
    title: 'Gigya',
    svgIcon: SAPGigyaPng,
    body: <GigyaContent />,
  },
  {
    title: 'Omada',
    svgIcon: OmadaPng,
    body: <OmadaContent />,
  },
  {
    title: 'Microsoft Entra',
    svgIcon: MicrosoftEntraPng,
    body: <MicrosoftEntraContent />,
  },
  {
    title: 'WSO2',
    svgIcon: WSO2Png,
    body: <WSO2Content />,
  },
  {
    title: 'ServiceNow',
    svgIcon: ServiceNowPng,
    body: <ServiceNowContent />,
  },
  {
    title: 'AWS',
    svgIcon: AwsPng,
    body: <AWSContent />,
  },
  {
    title: 'Saviynt',
    svgIcon: SaviyntPng,
    body: <SaviyntContent />,
  },
];

function SolutionsComponent3() {
  const [showInitialTitle, setShowInitialTitle] = useState(false);
  const [isTitleShrunk, setIsTitleShrunk] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    icon: '',
    body: null,
  });
  const componentRef = useRef(null);
  const animationRunOnce = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationRunOnce.current) {
            animationRunOnce.current = true;
            setShowInitialTitle(true);
            setTimeout(() => {
              setIsTitleShrunk(true);
              setTimeout(() => {
                setShowContent(true);
                setTimeout(() => {
                  setShowGrid(true);
                }, 300); // Adding delay to show grid after content
              }, 150);
            }, 500);
          }
        });
      },
      { threshold: 0.4 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleLearnMoreClick = (platform) => {
    setModalContent({
      title: platform.title,
      icon: platform.svgIcon,
      body: platform.body,
    });
    setIsModalOpen(true);
  };

  return (
    <div className="sc3_container" ref={componentRef}>
      <div
        className={`sc3_title ${showInitialTitle ? 'sc3_title--show' : ''} ${
          isTitleShrunk ? 'sc3_title--shrink' : ''
        }`}
      >
        <h1>
          By <span className="sc3_highlight">Platform</span>
        </h1>
      </div>
      <div
        className={`sc3_paragraph ${showContent ? 'sc3_paragraph--show' : ''}`}
      >
        <p>
          <span className="sc3_highlight">RAAH</span> delivers platform-specific
          identity solutions, addressing a wide range of challenges with
          tailored expertise.
          <br />
          <br />
          We <span className="sc3_highlight">future-proof</span> your Identity
          Infrastructure.
        </p>
      </div>
      <div className={`sc3_grid ${showGrid ? 'sc3_grid--show' : ''}`}>
        {platformData.map((platform, index) => (
          <div
            key={index}
            className="sc3_item"
            onClick={() => handleLearnMoreClick(platform)}
          >
            <img
              src={platform.svgIcon}
              alt={platform.title}
              className="sc3_item-img"
            />
          </div>
        ))}
      </div>
      <FullScreenModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        // title={modalContent.title}
        icon={modalContent.icon}
        body={modalContent.body}
      />
    </div>
  );
}

export default SolutionsComponent3;
