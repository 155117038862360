import React from 'react';
import Lock from '../icons/LockDarkTheme.gif';
import './App.css';

const H6 = () => {
    return (
        
        <div className="header-tile lock-dark-holder">
            <img
                src={Lock}
                alt="A representation of the Identity world"
                className="lock-dark-gif"
                key={Date.now()}
                /> 


            <span className="font-link">
            <h1>Interested in securing </h1>
            <h1><span className="highlight">Identity?</span></h1>
            </span>
        
        </div>
    )

}

export default H6;