import React from 'react';
import Net from '../icons/HubNetWorkDarkTheme.gif';
import './App.css';

const H3 = () => {
    return (
        
        <div className="header-tile cyber-net-dark-holder">
            <img
                src={Net}
                alt="A representation of the Identity world"
                className="cyber-net-dark-gif"
                key={Date.now()}
                /> 


            <span className="font-link">
                <h1>Why RAAH for </h1>
                <h1><span className="highlight">Identity</span>?</h1>
            </span>
        
        </div>
    )

}

export default H3;