// ZeroTrustSecurityContent.js

import React from 'react';
import { FaShieldAlt, FaLock, FaUserSecret } from 'react-icons/fa';

const ZeroTrustSecurityContent = () => (
  <>
    <h3 className="modal-main-title">
      Zero Trust Security and Adaptive Access Controls
    </h3>
    <p>
      Zero Trust Security is a framework for enhancing security by assuming that
      no user or device can be trusted by default, regardless of whether they
      are inside or outside the organization's network. It requires strict
      verification for every user or device attempting to access resources.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Benefits of Zero Trust Security
    </h4>
    <p>
      Implementing Zero Trust Security helps organizations enhance their
      security posture by continuously verifying every access request and
      reducing the attack surface.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Assumes that every request is
        potentially malicious, ensuring that only authorized users and devices
        can access resources.
      </li>
      <li className="modal-list-item">
        <strong>Minimized Attack Surface:</strong> By segmenting the network and
        applying least privilege principles, Zero Trust significantly reduces
        the number of entry points for attackers.
      </li>
      <li className="modal-list-item">
        <strong>Continuous Verification:</strong> Every access request is
        authenticated and authorized, ensuring ongoing protection against
        compromised credentials or devices.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Zero Trust Implementation
    </h4>
    <p>
      <strong>RAAH Technologies</strong> provides Zero Trust Security solutions
      that align with modern security needs. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Network Segmentation:</strong> We help implement
        micro-segmentation to isolate sensitive resources and minimize lateral
        movement by attackers.
      </li>
      <li className="modal-list-item">
        <strong>Adaptive Authentication:</strong> Enforce adaptive,
        context-aware authentication to verify user identities based on risk and
        behavior.
      </li>
      <li className="modal-list-item">
        <strong>Least Privilege Access:</strong> Implement least privilege
        principles, ensuring users have only the access necessary for their
        roles.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaUserSecret size={30} style={{ marginRight: '10px' }} />
      Use Case: Protecting Critical Financial Data
    </h4>
    <p>
      <strong>Challenge:</strong> A financial institution needed to protect
      critical customer financial data while minimizing the risk of data
      breaches and internal threats.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed a Zero Trust
      Security framework, using network segmentation, adaptive authentication,
      and continuous verification to secure all access points.
    </p>
    <p>
      <strong>Result:</strong> The institution achieved enhanced data
      protection, reduced the risk of insider threats, and ensured that only
      authorized personnel had access to sensitive information.
    </p>
  </>
);

export default ZeroTrustSecurityContent;
