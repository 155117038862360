import React from 'react';
import {
  FaMobileAlt,
  FaShieldAlt,
  FaTools,
  FaClipboardList,
  FaLightbulb,
} from 'react-icons/fa';

const TechContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing Technology and Communications with Innovative Identity Solutions
    </h3>
    <p>
      The technology and communications sectors are critical to modern society,
      requiring robust security and identity solutions to protect sensitive data
      and maintain seamless operations. At <strong>RAAH Technologies</strong>,
      we offer cutting-edge identity and access management solutions that meet
      the unique demands of these industries.
    </p>

    <h4 className="modal-subheading">
      <FaShieldAlt size={30} style={{ marginRight: '10px' }} />
      Challenges in Technology and Communications
    </h4>
    <p>
      Technology and communications companies face numerous challenges, such as
      securing sensitive customer information, managing access across diverse
      systems, and ensuring compliance with stringent regulatory standards. Our
      tailored solutions address these challenges:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Workforce IAM:</strong> Ensuring secure access for employees and
        contractors, providing the right level of access at all times.
      </li>
      <li className="modal-list-item">
        <strong>IGA:</strong> Managing identities for internal and external
        users, automating user lifecycle management, and providing audit trails
        for compliance.
      </li>
      <li className="modal-list-item">
        <strong>CIAM (B2C):</strong> Enabling seamless and secure access for
        customers, ensuring smooth experiences with features like Single Sign-On
        (SSO) and Multi-Factor Authentication (MFA).
      </li>
      <li className="modal-list-item">
        <strong>PAM:</strong> Protecting privileged accounts that access
        critical systems, mitigating risks of unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>B2B IAM:</strong> Managing identities for business partners,
        ensuring secure integration and collaboration across the technology
        ecosystem.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      Tailored Solutions for Technology and Communications
    </h4>
    <p>
      Our customized identity solutions for the technology and communications
      sectors include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Multi-Factor Authentication (MFA):</strong> Adding an extra
        layer of security to protect critical systems and data from unauthorized
        access.
      </li>
      <li className="modal-list-item">
        <strong>Single Sign-On (SSO):</strong> Simplifying access for employees,
        customers, and partners, improving efficiency and reducing login
        fatigue.
      </li>
      <li className="modal-list-item">
        <strong>Role-Based Access Control (RBAC):</strong> Ensuring that users
        have appropriate access based on their roles, protecting sensitive
        systems and data.
      </li>
      <li className="modal-list-item">
        <strong>Identity Lifecycle Management:</strong> Managing user identities
        from onboarding to offboarding, ensuring compliance with regulations.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Access Management (PAM):</strong> Securing access to
        critical infrastructure, including network systems and administrative
        tools.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Global Tech Firm
    </h4>
    <p>
      <strong>Challenge:</strong> A global technology firm needed to secure its
      vast network infrastructure and provide seamless access to employees,
      contractors, and business partners across multiple locations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies provided an integrated
      identity solution, including Workforce IAM and Multi-Factor Authentication
      (MFA) for secure access. Role-Based Access Control (RBAC) was implemented
      to ensure appropriate access for users, and Privileged Access Management
      (PAM) protected critical network systems.
    </p>
    <p>
      <strong>Result:</strong> The tech firm experienced a{' '}
      <strong>40% reduction</strong> in unauthorized access attempts and
      improved operational efficiency, resulting in enhanced security and
      compliance.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Enhanced Security:</strong> Protecting critical systems and data
        with CIAM, IAM, and PAM, reducing unauthorized access.
      </li>
      <li className="modal-list-item">
        <strong>Improved Operational Efficiency:</strong> Streamlined access
        with SSO and RBAC, reducing administrative overhead and improving user
        experience.
      </li>
      <li className="modal-list-item">
        <strong>Secure Collaboration:</strong> Enabling secure interactions with
        partners and contractors through effective identity management.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Ensuring adherence to industry
        standards and reducing risks of non-compliance.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Looking Ahead
    </h4>
    <p>
      As technology and communications continue to advance, secure identity
      solutions are crucial to protecting sensitive data and enabling
      innovation. At <strong>RAAH Technologies</strong>, we are committed to
      providing advanced IAM, IGA, CIAM, and PAM solutions that address the
      evolving needs of the technology and communications sectors.
    </p>
  </>
);

export default TechContent;
