import React from "react";
import '../App.css';
import './AccomplishmentsComponents.css';
import Chicksaw from './icons/chicksaw.svg';
import Sita from './icons/sita.svg';
import Spectra from './icons/spectra.svg';
import Target from './icons/target.svg';
import Travelport from './icons/travelport.svg';
import Ups from './icons/ups.svg';
import Vanguard from './icons/vanguard.svg';
import Verizon from './icons/verizon.svg';
import Wells from './icons/wells.svg';
import Zoetis from './icons/zoetis.svg';
import Zynga from './icons/zynga.svg';
import Block from './icons/block.svg';
import Ets from './icons/ets.svg';
import Exelon from './icons/exelon.svg';
import Florida from './icons/florida.svg';
import Globalp from './icons/globalp.svg';
import Horizon from './icons/horizon.svg';
import Ihg from './icons/ihg.svg';
import Independence from './icons/independence.svg';
import Irs from './icons/irs.svg';
import Labcorp from './icons/labcorp.svg';
import Laguardia from './icons/laguardia.svg';
import Lowes from './icons/lowes.svg';
import Mastercard from './icons/mastercard.svg';
import Matansuka from './icons/matansuka.svg';
import Mckesson from './icons/mckesson.svg';
import Nordstrom from './icons/nordstrom.svg';
import Occ from './icons/occ.svg';
import Pensacola from './icons/pensacola.svg';
import Stlouis from './icons/stlouis.svg';
import Anthem from './icons/anthem.svg';
import Atos from './icons/atos.svg';
import Bankofamerica from './icons/bankofamerica.svg';
import Bluecross from './icons/bluecross.svg';
import Boston from './icons/boston.svg';
import Cocacola from './icons/cocacola.svg';
import Cox from './icons/cox.svg';

const logos = [
    Chicksaw, Sita, Spectra, Target, Travelport, Ups, Vanguard, Verizon, Wells,
    Zoetis, Zynga, Block, Ets, Exelon, Florida, Globalp, Horizon, Ihg, Independence,
    Irs, Labcorp, Laguardia, Lowes, Mastercard, Matansuka, Mckesson,
    Nordstrom, Occ, Pensacola, Stlouis, Anthem, Atos, Bankofamerica, Bluecross,
    Boston, Cocacola, Cox
];

const AccomplishmentsComponent = ({scrollPercentage}) => {
    const getSelectedText = (textVal) => {
        let baseClass = "shape transition";

        switch (textVal) {
            case "first":
                if (scrollPercentage >= 1 && scrollPercentage < (100 / 3)) {
                    return `${baseClass} selectedText`;
                } else {
                    return `${baseClass} rectangle`;
                }
            case "second":
                if (scrollPercentage >= (100 / 3) && scrollPercentage < (200 / 3)) {
                    return `${baseClass} selectedText`;
                } else {
                    return `${baseClass} rectangle`;
                }
            case "third":
                if (scrollPercentage >= (200 / 3) && scrollPercentage < 100) {
                    return `${baseClass} selectedText`;
                } else {
                    return `${baseClass} rectangle`;
                }
            default:
                return baseClass;
        }
    };

    const getTitleStyle = (textVal) => {
        switch (textVal) {
            case "first":
                if (scrollPercentage >= 1 && scrollPercentage < (100 / 3)) {
                    return "selectedText";
                } else {
                    return "hiddenTitle";
                }
            case "second":
                if (scrollPercentage >= (100 / 3) && scrollPercentage < (200 / 3)) {
                    return "selectedText";
                } else {
                    return "hiddenTitle";
                }
            case "third":
                if (scrollPercentage >= (200 / 3) && scrollPercentage < 100) {
                    return "selectedText";
                } else {
                    return "hiddenTitle";
                }
            default:
                return "hiddenTitle";
        }
    };

    const getTextContent = () => {
        if (scrollPercentage >= 0 && scrollPercentage < (100 / 3)) {
            return (
                <>
                    We drive <span className="highlightTextAccomplishment">cost savings</span> through flexible engagements and pricing, enhanced operations, reduced security risks, and minimized legal exposure.
                </>
            );
        } else if (scrollPercentage >= (100 / 3) && scrollPercentage < (200 / 3)) {
            return (
                <>
                    Our solutions <span className="highlightTextAccomplishment">scale across diverse verticals</span>, built with future proofed design principles in mind for a variety of deployment complexities.
                </>
            );
        } else if (scrollPercentage >= (200 / 3) && scrollPercentage < 100) {
            return (
                <>
                    <span className="highlightTextAccomplishment">Identity Expertise in every capacity</span>, RAAH adds value to scoping, management, implementation, delivery, and ongoing support.
                </>
            );
        } else {
            return (
                " "
            );
        }
    };

    return (
        <div className="accomplishmentsContainer" id="AccomplishmentsComponentDiv">
            <div className="leftTextContainer">
                <div className={getSelectedText("first")}>
                    <div className={`title ${getTitleStyle("first")}`}>
                        $8 million
                        <span className={getTitleStyle("first") === "selectedText" ? "highlightAccomplishment" : ""}>+</span>
                        <br />
                        <span className="smallTextAccomplishment">Annual Costs Saved</span>
                    </div>
                </div>
                <div className={getSelectedText("second")}>
                    <div className={`title ${getTitleStyle("second")}`}>
                        625 million
                        <span className={getTitleStyle("second") === "selectedText" ? "highlightAccomplishment" : ""}>+</span>
                        <br />
                        <span className="smallTextAccomplishment">Identities Managed</span>
                    </div>
                </div>
                <div className={getSelectedText("third")}>
                    <div className={`title ${getTitleStyle("third")}`}>
                        250
                        <span className={getTitleStyle("third") === "selectedText" ? "highlightAccomplishment" : ""}>+</span>
                        <br />
                        <span className="smallTextAccomplishment">Successful IAM projects</span>
                    </div>
                </div>
            </div>
            <div className="rightTextContainer">
                <p className="rightText">
                    {getTextContent()}
                </p>
            </div>
            <div className="logoCarousel">
                {logos.map((logo, index) => (
                    <div className={`logo logo${index + 1}`} key={index}>
                        <img src={logo} alt={`logo${index + 1}`} width={200} height={130} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AccomplishmentsComponent;