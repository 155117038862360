import React from 'react';
import {
  FaShieldAlt,
  FaClipboardList,
  FaLightbulb,
  FaLock,
  FaTools,
  FaCode,
} from 'react-icons/fa';

const CyberArkContent = () => (
  <>
    <h3 className="modal-main-title">
      Securing Privileged Access with CyberArk Solutions
    </h3>
    <p>
      CyberArk is the market leader in privileged access management (PAM),
      providing a comprehensive solution for securing privileged accounts,
      credentials, and secrets that represent a significant security risk. At{' '}
      <strong>RAAH Technologies</strong>, we help organizations leverage
      CyberArk to manage and protect their privileged access, fortify defenses,
      and reduce the attack surface.
    </p>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      CyberArk: Unmatched Privileged Access Security
    </h4>
    <p>
      CyberArk’s suite of PAM solutions is designed to secure privileged
      credentials and secrets across a range of environments—from on-premises to
      hybrid and cloud-based architectures. Key features include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Credential Vaulting:</strong> Securely stores privileged
        credentials in a digital vault, ensuring that sensitive credentials are
        encrypted, stored, and rotated automatically.
      </li>
      <li className="modal-list-item">
        <strong>Privileged Session Management:</strong> Records and monitors
        privileged sessions to ensure accountability and to detect potential
        threats, providing a full audit trail for compliance.
      </li>
      <li className="modal-list-item">
        <strong>Application Access Manager:</strong> Provides applications and
        services with secure access to credentials without embedding them in
        code, protecting secrets and API keys.
      </li>
      <li className="modal-list-item">
        <strong>Endpoint Privilege Manager:</strong> Reduces risk by enforcing
        least privilege on workstations, ensuring that users only have the
        access necessary for their roles, thereby minimizing the attack surface.
      </li>
      <li className="modal-list-item">
        <strong>Threat Detection and Response:</strong> Uses AI and behavioral
        analytics to detect anomalies and malicious behavior associated with
        privileged accounts, enabling rapid response.
      </li>
    </ul>
    <p>
      Our experience with CyberArk allows us to implement a secure framework
      that aligns privileged access with your organization's overall security
      strategy, reducing both external and internal risks.
    </p>

    <h4 className="modal-subheading">
      <FaClipboardList size={30} style={{ marginRight: '10px' }} />
      Use Case: Securing Financial Institution Privileged Access
    </h4>
    <p>
      <strong>Challenge:</strong> A financial institution required a solution to
      secure administrative access to critical infrastructure, ensure compliance
      with industry regulations, and reduce the risk of insider threats.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies deployed CyberArk’s
      Privileged Access Manager, implementing credential vaulting and privileged
      session management to protect and monitor privileged accounts. Endpoint
      Privilege Manager was also employed to enforce least privilege across
      administrative and employee workstations.
    </p>
    <p>
      <strong>Result:</strong> The financial institution achieved a{' '}
      <strong>70% reduction</strong> in potential attack vectors, improved their
      compliance posture, and gained complete visibility and control over
      privileged activity.
    </p>

    <h4 className="modal-subheading">
      <FaLightbulb size={30} style={{ marginRight: '10px' }} />
      Benefits at a Glance
    </h4>
    <ul className="modal-list benefits-list">
      <li className="modal-list-item">
        <strong>Reduced Attack Surface:</strong> Eliminates hardcoded
        credentials and enforces least privilege to significantly minimize the
        risk of breaches.
      </li>
      <li className="modal-list-item">
        <strong>Comprehensive Privileged Account Management:</strong> Ensures
        privileged accounts and credentials are continuously protected, vaulted,
        and rotated.
      </li>
      <li className="modal-list-item">
        <strong>Regulatory Compliance:</strong> Helps organizations meet
        compliance requirements, including PCI-DSS, SOX, HIPAA, and GDPR, by
        providing auditing and accountability.
      </li>
      <li className="modal-list-item">
        <strong>Improved Security Posture:</strong> Detects and mitigates
        threats associated with privileged access through behavioral analytics
        and anomaly detection.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaTools size={30} style={{ marginRight: '10px' }} />
      RAAH's Expertise
    </h4>
    <p>
      At <strong>RAAH Technologies</strong>, we deliver holistic PAM solutions
      with CyberArk by understanding the unique security needs of each client
      and integrating those requirements into the deployment process. Our
      approach includes:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        Conducting risk assessments to understand privileged access risks and
        determine how best to mitigate them with CyberArk solutions.
      </li>
      <li className="modal-list-item">
        Creating deployment plans that align CyberArk capabilities with existing
        security controls, ensuring seamless integration into the organization's
        infrastructure.
      </li>
      <li className="modal-list-item">
        Training teams to understand and manage privileged access, enabling them
        to efficiently use CyberArk to protect critical assets.
      </li>
      <li className="modal-list-item">
        Providing ongoing monitoring, auditing, and maintenance to adapt to
        evolving risks and ensure that privileged accounts remain secure over
        time.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaCode size={30} style={{ marginRight: '10px' }} />
      Helpful Resources
    </h4>
    <ul className="modal-list">
      <li className="modal-list-item">
        <a
          href="https://docs.cyberark.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CyberArk Documentation
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://www.cyberark.com/resources/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CyberArk Resources
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://github.com/cyberark"
          target="_blank"
          rel="noopener noreferrer"
        >
          CyberArk GitHub Repository
        </a>
      </li>
      <li className="modal-list-item">
        <a
          href="https://community.cyberark.com/s/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CyberArk Community
        </a>
      </li>
    </ul>
  </>
);

export default CyberArkContent;
