import React, { useState, useRef, useEffect } from "react";
import News1Component from "./news1Component";
import NewsComponentTitle from "./newsComponentTitle";
import "./News.css";
import HamburgerMenu from "../overlays/HamburgerMenu";
import ScrollArrow from "../overlays/ScrollArrow";
import Icon from "../overlays/Icon";
import RightSphere from "../overlays/RightSphere";
import LeftSphere from "../overlays/LeftSphere";

function News() {
    const [animationComplete, setAnimationComplete] = useState(false);
    const [windowScrollPercent, setWindowScrollPercent] = useState(0);
    const [divScrollPercents, setDivScrollPercents] = useState([0, 0, 0, 0, 0]);
    const containerRef = useRef(); // Reference to the scrollable container

    const handleAboutAnimationEnd = () => {
        setAnimationComplete(true);
    };

    // Function to calculate scroll percentage of the scrollable container
    const handleContainerScroll = () => {
        const container = containerRef.current;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        const totalScroll = scrollHeight - clientHeight;
        const scrollPercentage = (scrollTop / totalScroll) * 100;
        setWindowScrollPercent(scrollPercentage);

        // Calculate individual div scroll percentages based on container scroll percentage
        const sections = document.querySelectorAll('.scroll-section');
        let startScrollPercent = 0;

        const newScrollPercents = Array.from(sections).map((section) => {
            const sectionHeight = section.clientHeight;
            const sectionScrollPercent = (sectionHeight / scrollHeight) * 100;

            let divScrollPercent = 0;
            if (scrollPercentage >= startScrollPercent && scrollPercentage < startScrollPercent + sectionScrollPercent) {
                divScrollPercent = ((scrollPercentage - startScrollPercent) / sectionScrollPercent) * 100;
            } else if (scrollPercentage >= startScrollPercent + sectionScrollPercent) {
                divScrollPercent = 100; // Fully scrolled past the section
            }

            startScrollPercent += sectionScrollPercent;
            return divScrollPercent;
        });

        setDivScrollPercents(newScrollPercents);
    };

    // Add event listener for container scroll only after the animation completes
    useEffect(() => {
        if (animationComplete) {
            const container = containerRef.current;
            container.addEventListener('scroll', handleContainerScroll);

            return () => {
                container.removeEventListener('scroll', handleContainerScroll);
            };
        }
    }, [animationComplete]); // Only apply scrolling logic after animation is complete

    return (
        <div>
            {!animationComplete && (
                <div className="News container" ref={containerRef}>
                    <div
                        className="scroll-section"
                        style={{
                            height: "100vh",
                            width: "100%",
                            overflow: "hidden", // Hide scrollbars during animation
                        }}
                    >
                        <NewsComponentTitle onAnimationEnd={handleAboutAnimationEnd} />
                    </div>
                </div>
            )}

            {animationComplete && (
                <div className="News container" ref={containerRef} style={{ overflowY: "scroll" }}>
                    <Icon />
                    <HamburgerMenu />
                    <LeftSphere />
                    <RightSphere />
                    {/* <ScrollArrow /> */}
                    <div id="NewsComponentDiv" className="scroll-section" style={{ height: "100vh" }}>
                        <News1Component scrollPercentage={divScrollPercents[0]} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default News;
