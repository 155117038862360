// CustomerProfileContent.js

import React from 'react';
import { FaUser, FaClipboardCheck, FaLock } from 'react-icons/fa';

const CustomerProfileContent = () => (
  <>
    <h3 className="modal-main-title">
      Customer Profile and Consent Management (B2C and B2B CIAM)
    </h3>
    <p>
      Customer Profile and Consent Management are integral components of
      Customer Identity and Access Management (CIAM) for B2C and B2B use cases.
      They allow organizations to collect, manage, and secure customer data
      while providing transparency and giving users control over how their
      information is used.
    </p>

    <h4 className="modal-subheading">
      <FaUser size={30} style={{ marginRight: '10px' }} />
      Benefits of Customer Profile and Consent Management
    </h4>
    <p>
      Implementing customer profile and consent management helps organizations
      build trust, maintain compliance with data protection regulations, and
      improve customer engagement by providing personalized experiences.
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Data Transparency:</strong> Provide customers with visibility
        into the data collected about them and how it is used, fostering trust
        and transparency.
      </li>
      <li className="modal-list-item">
        <strong>Enhanced Personalization:</strong> Utilize customer profile data
        to create personalized experiences, improving customer satisfaction and
        engagement.
      </li>
      <li className="modal-list-item">
        <strong>Compliance:</strong> Ensure that customer data is handled in
        compliance with regulations like GDPR and CCPA, including managing
        consent and providing customers with control over their data.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaClipboardCheck size={30} style={{ marginRight: '10px' }} />
      How RAAH Technologies Supports Customer Profile and Consent Management
    </h4>
    <p>
      <strong>RAAH Technologies</strong> offers comprehensive CIAM solutions
      that support customer profile and consent management for both B2C and B2B
      use cases. Our solutions include:
    </p>
    <ul className="modal-list">
      <li className="modal-list-item">
        <strong>Centralized Customer Data Management:</strong> Collect and
        manage customer data in a secure, centralized location, providing
        transparency and compliance with data protection standards.
      </li>
      <li className="modal-list-item">
        <strong>Customizable Consent Management:</strong> Allow customers to
        manage their consent preferences easily, ensuring that their data is
        only used for approved purposes.
      </li>
      <li className="modal-list-item">
        <strong>Secure Data Handling:</strong> Ensure that all customer data is
        encrypted and managed in compliance with relevant data protection
        regulations, such as GDPR and CCPA.
      </li>
    </ul>

    <h4 className="modal-subheading">
      <FaLock size={30} style={{ marginRight: '10px' }} />
      Use Case: Managing Consent for a Global Retail Platform
    </h4>
    <p>
      <strong>Challenge:</strong> A global retail company needed to manage
      customer profiles and consent across multiple regions while ensuring
      compliance with GDPR and other data protection regulations.
    </p>
    <p>
      <strong>Solution:</strong> RAAH Technologies implemented a customer
      profile and consent management solution that centralized customer data,
      provided customizable consent management options, and ensured secure data
      handling in compliance with relevant regulations.
    </p>
    <p>
      <strong>Result:</strong> The retail platform improved data transparency,
      enhanced customer trust, and ensured compliance with GDPR and CCPA,
      resulting in better customer relationships and increased satisfaction.
    </p>
  </>
);

export default CustomerProfileContent;
